<template>
  <div class="payment-details-container py-4">
    <div class="payment-details__illustration">
      <svg id="payment-illustration" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="353.572" height="368.506" viewBox="0 0 353.572 368.506">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_551" data-name="Rectangle 551" width="340.097" height="314.527" fill="currentcolor"/>
          </clipPath>
          <clipPath id="clip-path-3">
            <rect id="Rectangle_552" data-name="Rectangle 552" width="237.567" height="234.99" fill="none"/>
          </clipPath>
          <clipPath id="clip-path-4">
            <rect id="Rectangle_553" data-name="Rectangle 553" width="117.076" height="108.645" fill="none"/>
          </clipPath>
          <clipPath id="clip-path-5">
            <rect id="Rectangle_554" data-name="Rectangle 554" width="87.018" height="12.457" fill="none"/>
          </clipPath>
          <clipPath id="clip-path-6">
            <rect id="Rectangle_555" data-name="Rectangle 555" width="188.922" height="309.534" fill="none"/>
          </clipPath>
          <clipPath id="clip-path-7">
            <rect id="Rectangle_556" data-name="Rectangle 556" width="72.397" height="37.538" fill="none"/>
          </clipPath>
          <clipPath id="clip-path-8">
            <rect id="Rectangle_557" data-name="Rectangle 557" width="128.531" height="91.151" fill="none"/>
          </clipPath>
        </defs>
        <g id="Group_2193" data-name="Group 2193">
          <g id="Group_2192" data-name="Group 2192">
            <g id="Group_2191" data-name="Group 2191" clip-path="url(#clip-path)">
              <path id="Path_4481" data-name="Path 4481" d="M240.819.011c-31.267.539-55.382,26.11-82.146,42.283C118.247,66.723,66.334,71.681,32.2,104.325,8.821,126.675-2.738,160.356.55,192.528s20.933,62.333,46.486,82.154c16.293,12.639,35.418,21.158,54.933,27.821,19.777,6.753,40.367,11.749,61.264,12.013,28.964.365,57.415-8.393,83.615-20.748,18.22-8.592,35.784-19.092,50.114-33.25a140.232,140.232,0,0,0,31.03-47.456c17.577-43.638,14.779-93.643-.456-138.152C314.932,38.1,284.116-.736,240.819.011" transform="translate(0 0)" fill="currentcolor"/>
              <g id="Group_2190" data-name="Group 2190" opacity="0.7">
                <g id="Group_2189" data-name="Group 2189">
                  <g id="Group_2188" data-name="Group 2188" clip-path="url(#clip-path)">
                    <path id="Path_4482" data-name="Path 4482" d="M240.819.011c-31.267.539-55.382,26.11-82.146,42.283C118.247,66.723,66.334,71.681,32.2,104.325,8.821,126.675-2.738,160.356.55,192.528s20.933,62.333,46.486,82.154c16.293,12.639,35.418,21.158,54.933,27.821,19.777,6.753,40.367,11.749,61.264,12.013,28.964.365,57.415-8.393,83.615-20.748,18.22-8.592,35.784-19.092,50.114-33.25a140.232,140.232,0,0,0,31.03-47.456c17.577-43.638,14.779-93.643-.456-138.152C314.932,38.1,284.116-.736,240.819.011" transform="translate(0 0)" fill="#fff6eb"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Group_2196" data-name="Group 2196" transform="translate(23.169 111.657)" opacity="0.1">
          <g id="Group_2195" data-name="Group 2195">
            <g id="Group_2194" data-name="Group 2194" clip-path="url(#clip-path-3)">
              <path id="Path_4483" data-name="Path 4483" d="M264.314,199.86c-.224-6.4-.64-13.223-1.329-20.285-.925-14.095-2.695-27.267-5.9-35.545v0h0v0h-.014c-17.614-3.608-109.239,3.077-109.3,3.082l-.575-2.546c-.075-.338-.153-.675-.229-1.013l-.4-1.774-.116-.4c-.593-2.395-1.262-4.771-2.048-7.121h0v0s-69.458-6.2-92.5-.187v0h0c0,.2.005.4.008.6l.068,5.688c.068,11-.187,21.317.383,32.657v.079h-.009a2.477,2.477,0,0,0-.337.037A4.864,4.864,0,0,0,47.8,178.4l.331,3.534c.005.058.007.111.013.169.85,9.022,1.682,17.959,1.682,17.959h.01c.029.014.435.042,1.16.081l3.814.265c-8.119.754-16.968,11.472-16.6,20.688s-8.494,26.579-6.277,36.55c1.73,7.784,10.437,20.3,14.7,29.8q-.154.875-.336,1.887l-19.02,38.185c1.271-1.87,12.72,48,66.008,37.437l37.9-61.742h0a73.378,73.378,0,0,0,11.54-10.848,18.843,18.843,0,0,0,3.573-4.943l0-.005a26.458,26.458,0,0,0,3.07-6.966c.168-.638.346-1.284.529-1.932,3.092-7.873,7.224-18.624,10.249-26.069,39.9-1.182,81.848-3.621,102.9-8.577l.046-.007c2.034-13.841,2.078-29.381,1.223-44.005M59.257,234.747a60.119,60.119,0,0,1-.59-6.942q.7,3.667,1.508,7.331c-.3-.138-.607-.274-.918-.389" transform="translate(-27.272 -131.433)"/>
            </g>
          </g>
        </g>
        <path id="Path_4484" data-name="Path 4484" d="M22.164,335.033s17.059,50.429,70.348,39.87c0,0,11.822-55.51,21.732-63.683,6.391-5.271,20.5-14.719,23.516-21.554,3.073-6.97,15.131-39.318,17.477-43.229s-25.419-48.872-25.419-48.872L71.338,227.74,38.8,285.544s-6.241,37.319-16.632,49.49" transform="translate(-3.335 -29.727)" fill="#fff"/>
        <path id="Path_4485" data-name="Path 4485" d="M37.892,306.355s-4.175,31.186-17.961,47.48" transform="translate(-2.999 -46.096)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4486" data-name="Path 4486" d="M104.972,384.92s11.761-53.712,21.732-63.683c0,0,22.526-13.106,26.589-28.526,2.813-10.679,7.926-22.988,10.37-28.576" transform="translate(-15.795 -39.743)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4487" data-name="Path 4487" d="M110.55,266.131c-.872-.541,13.631,11.08,14.194,26.878" transform="translate(-16.628 -40.041)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4488" data-name="Path 4488" d="M257.632,234.792c-46.962,11.078-198.213,9.591-198.213,9.591-1.274-2.685-2.812-9.447-4.3-17.28-2.973-15.725-5.751-35.765-5.751-35.765l-5-.348s-.833-8.937-1.682-17.959,4.214-9.014,4.214-9.014l-.365-31.229-.093-7.8c23.039-6.015,92.5.187,92.5.187L141.1,132.7l1.206,5.335,109.318-3.084c10.041,25.919,6.006,99.837,6.006,99.837" transform="translate(-6.408 -18.412)" fill="currentcolor"/>
        <path id="Path_4489" data-name="Path 4489" d="M261.175,191.172l-71.615,3.437a5.346,5.346,0,0,1-5.6-5.084l-.7-14.547a5.347,5.347,0,0,1,5.084-5.6l71.615-3.438a5.347,5.347,0,0,1,5.6,5.084l.7,14.547a5.347,5.347,0,0,1-5.084,5.6" transform="translate(-27.574 -24.968)" fill="#263238"/>
        <path id="Path_4490" data-name="Path 4490" d="M266.752,206.466l.223,2.42-82.555,3.2-.069-2.174Z" transform="translate(-27.739 -31.066)" fill="#263238"/>
        <path id="Path_4491" data-name="Path 4491" d="M267.208,216.377l.069,2.174-82.55,3.052-.069-2.173Z" transform="translate(-27.785 -32.557)" fill="#263238"/>
        <g id="Group_2199" data-name="Group 2199" transform="translate(135.898 115.475)" opacity="0.3">
          <g id="Group_2198" data-name="Group 2198">
            <g id="Group_2197" data-name="Group 2197" clip-path="url(#clip-path-4)">
              <path id="Path_4492" data-name="Path 4492" d="M269.289,137s12.186,57.77,6.006,99.838c0,0-77.034,9.033-103.411,7.576L170.8,230.355l2.207-.343s-10.657-80.244-13.041-89.934c0,0,91.737-6.7,109.321-3.079" transform="translate(-159.968 -135.927)"/>
            </g>
          </g>
        </g>
        <g id="Group_2202" data-name="Group 2202" transform="translate(58.098 209.693)" opacity="0.8">
          <g id="Group_2201" data-name="Group 2201">
            <g id="Group_2200" data-name="Group 2200" clip-path="url(#clip-path-5)">
              <path id="Path_4493" data-name="Path 4493" d="M154.483,246.833l.923,10.628s-77.653,3.4-84.478.931.362-6.419,18.421-7.836,65.134-3.724,65.134-3.724" transform="translate(-68.388 -246.833)" fill="#fff"/>
            </g>
          </g>
        </g>
        <path id="Path_4494" data-name="Path 4494" d="M121,185.081c0,3.546-3.177,6.42-7.1,6.42s-7.1-2.874-7.1-6.42,3.177-6.42,7.1-6.42,7.1,2.874,7.1,6.42" transform="translate(-16.071 -26.882)" fill="#263238"/>
        <path id="Path_4495" data-name="Path 4495" d="M50.569,193.436a275.185,275.185,0,0,0,10.048,53.052c32.936-.281,65.865-.652,98.787-1.705s66.769-3.272,99.43-7.885c0,0,5.849-51.617-6.006-99.838-13.1-2.724-108.957,3.069-108.957,3.069" transform="translate(-7.609 -20.513)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4496" data-name="Path 4496" d="M151.283,228.164l.923,10.629-73.879,1.7c-3.052.26-7.8.66-10.6-.773-1.28-.654-2.2-2.255-1.6-3.56.543-1.175,1.978-1.588,3.246-1.849a215.061,215.061,0,0,1,27.47-3.323q29.55-2.183,59.181-2.961c-.409.011-5.232-39.749-5.766-43.415-2.091-14.361-4.08-28.775-6.66-43.059a116.154,116.154,0,0,0-3.985-16.318c-30.446-2.176-62.241-4.192-92.5-.185.276,13.484-.221,25.517.463,39.023" transform="translate(-7.09 -18.471)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4497" data-name="Path 4497" d="M61.01,260.988a6.43,6.43,0,0,1-.326-1.661" transform="translate(-9.131 -39.02)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4498" data-name="Path 4498" d="M61.261,253.175c1.132-2.828,4.524-6.018,13.736-7.043,16.812-1.869,53.467-5.23,74.964-6.051" transform="translate(-9.218 -36.124)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" stroke-dasharray="3.911 3.911"/>
        <path id="Path_4499" data-name="Path 4499" d="M167.631,239.7q.868-.029,1.7-.051l-.232-1.687" transform="translate(-25.223 -35.806)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4500" data-name="Path 4500" d="M166.51,219.408c-3.867-27.812-8.148-55.413-13.052-83.065" transform="translate(-23.09 -20.515)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" stroke-dasharray="3.882 3.882"/>
        <path id="Path_4501" data-name="Path 4501" d="M152.766,134.113q-.149-.838-.3-1.676s-.593-.045-1.691-.124" transform="translate(-22.687 -19.909)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4502" data-name="Path 4502" d="M132.359,131.654c-14.126-.945-54.3-3.292-81.107-1.459" transform="translate(-7.712 -19.488)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" stroke-dasharray="4.066 4.066"/>
        <path id="Path_4503" data-name="Path 4503" d="M48.916,130.464q-.856.066-1.691.138" transform="translate(-7.106 -19.63)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4504" data-name="Path 4504" d="M44.321,198.06,42.291,176.4a4.863,4.863,0,0,1,4.559-5.311c12.486-.722,44.787-2.418,57.186-1.426,5.954.477,12.409,2.384,15.31,8.077,2.39,4.69,2.752,11.726-1.273,15.582-6.117,5.861-17.715,5.411-25.6,5.688C77.776,199.528,44.333,198.186,44.321,198.06Z" transform="translate(-6.36 -25.483)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4505" data-name="Path 4505" d="M122.37,187.554c.025-.6,3.088,12.443-8.212,16.748s-63.589,2.953-63.589,2.953v-6.114s37,.776,43.15.6,27.985,1.883,28.651-14.191" transform="translate(-7.609 -28.217)" fill="#263238"/>
        <path id="Path_4506" data-name="Path 4506" d="M122.293,273.358s9.24,5.413,12.7,15.576" transform="translate(-18.401 -41.131)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4507" data-name="Path 4507" d="M42.591,174.909s45.334-2.132,56.867-1.747,17.186,2.919,18.339,10.149-5.845,11.766-11.419,12.534-62.008,1.1-62.008,1.1" transform="translate(-6.408 -26.048)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" stroke-dasharray="4 4"/>
        <path id="Path_4508" data-name="Path 4508" d="M70.824,265.025s-7.016-24.373-19.942-29.174c0,0-2.584-16.634,2.955-17.55s21.05-11.162,16.987-23.949-16.249,6.417-24.373,7.155S29.463,212.975,29.833,222.2s-8.494,26.579-6.278,36.55,15.879,27.7,16.987,36.929" transform="translate(-3.491 -28.584)" fill="#fff"/>
        <path id="Path_4509" data-name="Path 4509" d="M70.824,265.025s-7.016-24.373-19.942-29.174c0,0-2.584-16.634,2.955-17.55s21.05-11.162,16.987-23.949-16.249,6.417-24.373,7.155S29.463,212.975,29.833,222.2s-8.494,26.579-6.278,36.55,15.879,27.7,16.987,36.929" transform="translate(-3.491 -28.584)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4510" data-name="Path 4510" d="M59.249,197.189s9.726,1.767,15.016-4.4-.8-6.569-.8-6.569Z" transform="translate(-8.915 -28.02)" fill="#fff"/>
        <path id="Path_4511" data-name="Path 4511" d="M59.249,197.189s9.726,1.767,15.016-4.4-.8-6.569-.8-6.569Z" transform="translate(-8.915 -28.02)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4512" data-name="Path 4512" d="M38.8,215.337a10.638,10.638,0,0,1,5,1.64" transform="translate(-5.838 -32.401)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4513" data-name="Path 4513" d="M38.454,219.5a9.356,9.356,0,0,1,4.858,1.923" transform="translate(-5.786 -33.027)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4514" data-name="Path 4514" d="M69.359,257.64s41.65-2.149,52.591-1.525" transform="translate(-10.436 -38.519)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4515" data-name="Path 4515" d="M119.335,252.334s10.476-.816,17,0" transform="translate(-17.956 -37.913)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <line id="Line_423" data-name="Line 423" x2="16.954" transform="translate(127.915 217.482)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4516" data-name="Path 4516" d="M162.639,145.7s74.446-6.646,104.358-2.851c0,0,8.07,14.841,6.318,90.915A952.854,952.854,0,0,1,171.98,241.3" transform="translate(-24.472 -21.318)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" stroke-dasharray="4 4"/>
        <g id="Group_2205" data-name="Group 2205" transform="translate(151.173 55.123)" opacity="0.1">
          <g id="Group_2204" data-name="Group 2204">
            <g id="Group_2203" data-name="Group 2203" clip-path="url(#clip-path-6)">
              <path id="Path_4517" data-name="Path 4517" d="M366.469,239.4c-2.115-8.995-5.215-12.377-8.319-14.976,0,0-.072-5.115-4.057-8a27.337,27.337,0,0,0-3.427-2.535c-3.74-2.8-7.864-5.978-7.864-5.979-1.937-5.607-4.56-12.395-7.393-19.074l.142,1.52c-2.9-7.092-5.751-14.157-6.552-16.5-.005-.015-.011-.033-.017-.048.827,2.257,1.707,4.435,2.638,6.454L320.976,142.67c-.253-1.515-.511-3-.779-4.436-.5-4.226-1.9-12.254-5.236-12.814a1.7,1.7,0,0,0-.372-.037s-3.929-1.706-3.929,5.275c-4.683,1.56-6.492,5.057-6.946,9.253a12.575,12.575,0,0,0-.079,3.989c.081,1.536.263,3.109.494,4.673l-15-19.754-1.464-1.927-18.584-24.468-25-32.914a11.7,11.7,0,0,0-16.4-2.239l-31,23.557-12.111,9.2c-9.288,4.836-6.863,14.384-4.289,17.773L234.367,189a5.657,5.657,0,0,0-.839-.042s-1.547-3.649-5.37-3.067a4.376,4.376,0,0,0-2.278.755,4.022,4.022,0,0,0-1.515,1.383,11.6,11.6,0,0,0-1.138,1.627c-1.951,3.334-.15,11.48,2.662,21.432.179.643.356,1.262.526,1.843.009.03.019.064.028.093q.353,1.228.722,2.485c2.752,9.552,7.9,28.3,8.852,37.488q.1,1.335.13,2.609c.062,10.584-4.638,28.312-2.619,35.628.266.962.534,1.884.811,2.788,1.5,4.894,2.847,9.522,5.206,14.124,2.873,5.6,6.3,10.981,9.5,16.41q8.822,14.967,18.252,29.564c2.891,4.474,6.283,8.547,9.563,12.762,1.5,1.927,4.783,4.827,5.354,7.3,0,0,29.077,2.4,45.357-7.937L326.034,343.1l10.913-62.742c3.118-4.726,12.709-13.973,14.671-16.9q1.481-2.179,2.9-4.308c7.184-6.7,10.779-11.928,12.354-17.935,0,0-.112-.687-.4-1.816m-101.83,12.278-.006-.016.009.02,0,0m1.161,1.431-.139-.279a34.046,34.046,0,0,0,9.33,2.72c-3.374-.392-7.2-.932-9.19-2.442m12.25,2.809h0q.762.065,1.563.106a10.79,10.79,0,0,1-1.559-.105m-12.572-3.45-1.95-3.876c-2.724-7.8-8.865-26.182-8.89-26.257l0-.005a104.829,104.829,0,0,0-4.792-15.5,9.262,9.262,0,0,0,8.68-1.364l14.863-11.291c1.535.926,3.11,1.757,4.65,2.49l13.92,10.886-11.041,45.392Zm56.363-104.546h0c1.149,5.427,2.981,13.222,5.4,20.775a136.28,136.28,0,0,1-5.4-20.775" transform="translate(-177.948 -64.886)"/>
              <path id="Path_4518" data-name="Path 4518" d="M273.052,419.379l.09.4s-.031-.146-.09-.4" transform="translate(-192.258 -118.225)"/>
            </g>
          </g>
        </g>
        <path id="Path_4519" data-name="Path 4519" d="M327.819,161.948c.882-.565,13.3-7.341,19.516,12.987s13.552,54.924,13.552,54.924l-14.812,2.9s-13.726-7.305-26.027-25.6c0,0-17.246-4.469-24.755-15.09-6.823-9.652,14.174-45.083,14.174-45.083Z" transform="translate(-44.226 -22.116)" fill="#fff"/>
        <path id="Path_4520" data-name="Path 4520" d="M327.531,194.379c3.673,5.506,10.623,17.084,20,20.732" transform="translate(-49.282 -29.247)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4521" data-name="Path 4521" d="M315.31,201.35c1.733.687,5.987,3.128,7.954,3.221,0,0,8.655,17.138,22.95,22.7" transform="translate(-47.443 -30.296)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4522" data-name="Path 4522" d="M305.962,208.775c5.315,4.445,11.637,7.48,15.9,7.681,0,0,8.655,17.138,22.951,22.7" transform="translate(-46.037 -31.414)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4523" data-name="Path 4523" d="M320.857,162.936l-46.978,35.686a9.273,9.273,0,0,1-12.994-1.775l-65.25-85.9a9.274,9.274,0,0,1,1.775-12.994L244.387,62.27a9.275,9.275,0,0,1,13,1.776l65.249,85.9a9.274,9.274,0,0,1-1.775,12.994" transform="translate(-29.152 -9.085)" fill="#263238"/>
        <path id="Path_4524" data-name="Path 4524" d="M319.775,160.672l-43.106,32.753a11.691,11.691,0,0,1-16.4-2.24L197.96,109.154A11.706,11.706,0,0,1,200.2,92.767l12.111-9.2,31-23.557a11.7,11.7,0,0,1,16.4,2.24l25,32.913,18.584,24.468,18.724,24.651a11.689,11.689,0,0,1-2.24,16.387" transform="translate(-29.427 -8.671)" fill="currentcolor"/>
        <g id="Group_2208" data-name="Group 2208" transform="translate(182.883 48.958)" opacity="0.1">
          <g id="Group_2207" data-name="Group 2207">
            <g id="Group_2206" data-name="Group 2206" clip-path="url(#clip-path-7)">
              <path id="Path_4525" data-name="Path 4525" d="M287.671,95.167l-72.4-11.6,31-23.558a11.7,11.7,0,0,1,16.4,2.24Z" transform="translate(-215.274 -57.629)"/>
            </g>
          </g>
        </g>
        <g id="Group_2211" data-name="Group 2211" transform="translate(166.442 95.991)" opacity="0.1">
          <g id="Group_2210" data-name="Group 2210">
            <g id="Group_2209" data-name="Group 2209" clip-path="url(#clip-path-8)">
              <path id="Path_4526" data-name="Path 4526" d="M319.827,169l-43.106,32.753a11.691,11.691,0,0,1-16.4-2.24l-62.312-82.031a11.6,11.6,0,0,1-2.09-4.491l108.886,16.9,17.26,22.722A11.689,11.689,0,0,1,319.827,169" transform="translate(-195.921 -112.993)"/>
            </g>
          </g>
        </g>
        <path id="Path_4527" data-name="Path 4527" d="M270.821,87.756,263.2,93.541a3.462,3.462,0,0,1-4.851-.663l-9.219-12.136a3.462,3.462,0,0,1,.663-4.852l7.615-5.784a3.462,3.462,0,0,1,4.852.663l9.219,12.136a3.462,3.462,0,0,1-.663,4.851" transform="translate(-37.38 -10.442)" fill="#fff"/>
        <path id="Path_4528" data-name="Path 4528" d="M270.821,87.756,263.2,93.541a3.462,3.462,0,0,1-4.851-.663l-9.219-12.136a3.462,3.462,0,0,1,.663-4.852l7.615-5.784a3.462,3.462,0,0,1,4.852.663l9.219,12.136A3.462,3.462,0,0,1,270.821,87.756Z" transform="translate(-37.38 -10.442)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <line id="Line_424" data-name="Line 424" x2="13.14" y2="17.854" transform="translate(216.391 62.43)" fill="#fff"/>
        <line id="Line_425" data-name="Line 425" x2="13.14" y2="17.854" transform="translate(216.391 62.43)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <line id="Line_426" data-name="Line 426" y1="9.96" x2="13.14" transform="translate(216.391 66.443)" fill="#fff"/>
        <line id="Line_427" data-name="Line 427" y1="9.96" x2="13.14" transform="translate(216.391 66.443)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4529" data-name="Path 4529" d="M279.431,204.8l-2.24,1.651-27.362-36.022,2.219-1.683Z" transform="translate(-37.591 -25.39)" fill="#263238"/>
        <path id="Path_4530" data-name="Path 4530" d="M283.891,201.483l-2.24,1.662L254.257,167.07l2.208-1.683Z" transform="translate(-38.257 -24.885)" fill="#263238"/>
        <path id="Path_4531" data-name="Path 4531" d="M288.349,198.181l-2.24,1.651-27.437-36.118,2.218-1.683Z" transform="translate(-38.921 -24.38)" fill="#263238"/>
        <path id="Path_4532" data-name="Path 4532" d="M292.88,194.959l-2.24,1.651-27.072-35.646,2.208-1.683Z" transform="translate(-39.658 -23.966)" fill="#263238"/>
        <path id="Path_4533" data-name="Path 4533" d="M297.691,191.392l-2.24,1.651-27.115-35.7,2.219-1.683Z" transform="translate(-40.375 -23.421)" fill="#263238"/>
        <path id="Path_4534" data-name="Path 4534" d="M297.691,141.946l-2.24,1.651-27.115-35.7,2.219-1.683Z" transform="translate(-40.375 -15.982)" fill="#263238"/>
        <rect id="Rectangle_558" data-name="Rectangle 558" width="4.357" height="126.329" transform="matrix(0.796, -0.605, 0.605, 0.796, 194.792, 65.947)" fill="#263238"/>
        <path id="Path_4535" data-name="Path 4535" d="M319.775,160.672l-43.106,32.753a11.691,11.691,0,0,1-16.4-2.24L197.96,109.154A11.706,11.706,0,0,1,200.2,92.767l12.111-9.2,31-23.557a11.7,11.7,0,0,1,16.4,2.24l25,32.913,18.584,24.468,18.724,24.651A11.689,11.689,0,0,1,319.775,160.672Z" transform="translate(-29.427 -8.671)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4536" data-name="Path 4536" d="M292.485,383.768s50.173,17.853,65.352-21.023c0,0-23.746-28.864-10.781-52.482,8.159-14.864,30.713-47.684,30.713-47.684s9.419-8.945,12.354-17.934c0,0-1.613-10.011-8.72-16.793,0,0-.072-5.115-4.057-8s-11.291-8.513-11.291-8.513-12.014-28.82-13.8-34.054-5.362-15.06-7.158-25.93-3.447-22.58-7.252-22.543c0,0-3.929-1.706-3.929,5.276,0,0-8.236,4.24-7.026,13.233s2.336,13.63,2.015,18.694,1.3,22.607,5.362,28.109c0,0,1.358,24.64,3.39,31.056.062.2.124.376.188.536,2.136,5.425-19.256,26.38-30.834,31.988,0,0-1.21,2.265-5.705,1.646s-12.1-.909-13.412-4.239-10-29.347-10-29.347-7.043-33.382-21.109-33.379c0,0-2.14-5.1-7.648-2.312s-1.9,17.978.536,26.286,8.613,30.152,9.617,40.2-4.69,30.179-2.5,38.1,4.513,13.265,11.346,26.229,21.955,51.58,24.358,62.882" transform="translate(-37.05 -19.353)" fill="#fff"/>
        <path id="Path_4537" data-name="Path 4537" d="M292.383,394.516s-10.738-40.427-26.527-66.559-6.534-35.388-6.534-57.166-17.725-58.8-12.945-66.965,11.312-1.632,14.034,5.99-1.089,11.978-8.711,12.521" transform="translate(-36.948 -30.101)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4538" data-name="Path 4538" d="M258.648,203.673s9.161-.554,14.8,14.1S288.1,269.625,297.493,280.9" transform="translate(-38.918 -30.645)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4539" data-name="Path 4539" d="M295.646,278.421c.053.117,13.121,7.405,33.411,1.018" transform="translate(-44.485 -41.893)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4540" data-name="Path 4540" d="M260.693,253.7a9.185,9.185,0,0,1,8.58-3.253" transform="translate(-39.225 -37.665)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4541" data-name="Path 4541" d="M262.747,259.269a7.3,7.3,0,0,1,7.376-2.4" transform="translate(-39.534 -38.616)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4542" data-name="Path 4542" d="M344.045,135.051c-13.339,4.442-3.413,24.583-5.01,31.926s2.554,21.424,5.746,29.053c0,8.94,3.193,30.65,3.193,30.65-1.972,6.9-18.417,27.261-34.4,33.634" transform="translate(-47.182 -20.321)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4543" data-name="Path 4543" d="M359.228,144.257s-.99-15.417-5.857-15.417-4.575,10.39-2.543,18.1S360.71,153.506,359.228,144.257Z" transform="translate(-52.579 -19.386)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4544" data-name="Path 4544" d="M360.961,146.987s4.147,24.241,11.174,39.464" transform="translate(-54.312 -22.116)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4545" data-name="Path 4545" d="M383.065,251.718c3.395-5.5,4.9-10.709,6.163-17.336-.32-4.153-5.083-17.717-10.655-30.851" transform="translate(-56.962 -30.625)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4546" data-name="Path 4546" d="M387.788,227.483c5.509,4.149,15.1,7.331,13.993,14.488a59.96,59.96,0,0,1-7.391,20.29" transform="translate(-58.349 -34.228)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4547" data-name="Path 4547" d="M376.231,381.971s-19.205-26.669-15.36-45.085c2.829-13.549,21.022-37.239,34.069-56.866,7.184-6.7,10.78-11.927,12.354-17.935-2.307-11.341-6.039-14.648-9.641-17.549" transform="translate(-54.222 -36.794)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4548" data-name="Path 4548" d="M382.807,232.557A16.252,16.252,0,0,0,370,234.3" transform="translate(-55.673 -34.901)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_4549" data-name="Path 4549" d="M382.488,238.013a12.039,12.039,0,0,0-7.6,1.335" transform="translate(-56.408 -35.792)" fill="none" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentIllustration',
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.payment-details-container {
  svg {
    max-width: 100%;
    min-height: 100%;
  }
}
</style>