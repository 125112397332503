<template>
  <div class="not-selected-container">
    <div class="not-selected-container__icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="203.902" height="139.183" viewBox="0 0 203.902 139.183">
        <g id="not-found" transform="translate(-900.95 -522.24)">
          <path id="Union_18" data-name="Union 18" d="M80.906,87.389a2.24,2.24,0,0,1-2.239-2.243V37.414a2.24,2.24,0,0,1,2.239-2.242h47.731a2.243,2.243,0,0,1,2.239,2.242V85.145a2.243,2.243,0,0,1-2.239,2.243Zm2.238-4.478H126.4V39.652H83.144ZM0,73.627l3.167-3.171,3.374,3.376L9.91,70.456l3.171,3.167L8.227,78.477l-1.15-1.15L5.391,79.018Zm110.966,2.841a6.008,6.008,0,1,0-12.016,0H94.473a10.487,10.487,0,1,1,20.974,0ZM115.4,58.039l-3.374,3.373-3.168-3.165,3.374-3.376L108.863,51.5l3.168-3.167,3.374,3.373,3.374-3.373,3.171,3.167-3.374,3.371,3.374,3.376-3.171,3.165Zm-20.889,0-3.373,3.373-3.167-3.165,3.373-3.376L87.975,51.5l3.167-3.167,3.373,3.373,3.374-3.373,3.167,3.167-3.374,3.371,3.374,3.376-3.167,3.165ZM7.12,45.622,3.742,49,.575,45.827l3.374-3.372L.575,39.078l3.167-3.163L7.12,39.286l3.369-3.372,3.171,3.163-3.377,3.377,3.377,3.372L10.49,49ZM6.541,9.709,3.167,13.082,0,9.911l3.374-3.37L0,3.163,3.167,0,6.541,3.374,9.91,0l3.171,3.163L9.708,6.541l3.374,3.37L9.91,13.082Z" transform="translate(950.806 573.535)" class="text-danger" fill="currentcolor" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
          <path id="Union_17" data-name="Union 17" d="M2.243,87.227A2.24,2.24,0,0,1,0,84.988v-12.3a2.241,2.241,0,0,1,2.243-2.24H26.827a2.24,2.24,0,0,1,2.239,2.24v12.3a2.239,2.239,0,0,1-2.239,2.239Zm2.239-4.481h20.1V74.93H4.481Zm34.693-.929a2.24,2.24,0,0,1,0-4.48h36.6v4.48ZM2.818,64.977A2.243,2.243,0,0,1,.575,62.735V38.151a2.239,2.239,0,0,1,2.243-2.238H27.407a2.236,2.236,0,0,1,2.238,2.238V62.735a2.239,2.239,0,0,1-2.238,2.242ZM5.056,60.5H25.164V40.389H5.056Zm34.121-2.448a2.238,2.238,0,0,1,0-4.477h36.6v4.477Zm0-10.819a2.24,2.24,0,0,1,0-4.481h36.6v4.481ZM2.243,29.065A2.241,2.241,0,0,1,0,26.822V2.239A2.238,2.238,0,0,1,2.243,0H26.827a2.237,2.237,0,0,1,2.239,2.239V26.822a2.24,2.24,0,0,1-2.239,2.243Zm2.235-4.481H24.589V4.477H4.477Zm34.7-2.4a2.239,2.239,0,0,1,0-4.478H85.763a2.239,2.239,0,1,1,0,4.478Zm0-10.819a2.24,2.24,0,0,1,0-4.481H102.5a2.24,2.24,0,0,1,0,4.481Z" transform="translate(942.813 565.545)" fill="#999" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
          <path id="Union_13" data-name="Union 13" d="M2.638,130.023A2.644,2.644,0,0,1,0,127.386V11.75A11.767,11.767,0,0,1,11.754,0h118.8a11.765,11.765,0,0,1,11.754,11.75V75.084H137.83V32.019H4.478v93.524H90.372v4.481ZM137.83,27.541V11.75a7.281,7.281,0,0,0-7.276-7.27H11.754a7.283,7.283,0,0,0-7.276,7.27V27.541Z" transform="translate(928.218 522.74)" fill="#ccc" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
          <path id="Union_15" data-name="Union 15" d="M190.6,116.053v-4.482h12.3v4.482Zm-190.6,0v-4.482H117.14v4.482ZM149.269,5.225V0h5.6V5.225Zm-12.471,0V0h5.6V5.225Zm-12.475,0V0h5.6V5.225Z" transform="translate(901.45 536.712)" class="text-primary" fill="currentcolor" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </svg>
    </div>
    <div class="not-selected-container__content">
      <h4 class="text-danger">No Category Selected</h4>
      <p class="mb-0">Please select a category to proceed</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotSelected",
  props: {},
  data() {
    return {};
  },
}
</script>

<style lang="scss">
  .not-selected-container {
    position: relative;
    background-color: rgba(var(--bs-danger-rgb), 0.03);
    border: 1px solid rgba(var(--bs-danger-rgb), 0.35);
    border-radius: calc(0.35rem * 1.5);
    padding: calc(1rem + 1.5vw);
    margin-block: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    &__icon {
      position: relative;
      svg {
        max-height: 120px;
        color: rgba(var(--bs-primary),1);
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
    }
  }
</style>