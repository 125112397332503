<template>
  <div class="category-selection">
    <div class="category-selection__list">
      <h6 v-if="shouldDisplayMainDev" class="mt-2">Selections:</h6>
      <div v-for="(item, x) in sortedOptions(dataList)" :key="customKey + x">
        <div
          class="category-selection__list__item"
          :class="{
            selected: item.state,
            disabled: item.disabled,
            'pre-selected': item.pre_selected,
          }"
          v-if="!item.options.length && item.user_selection == 1"
        >
          <b-form-checkbox
            @change="
              getItemsData(item.check, item.price, 'item', item?.options, x)
            "
            :id="item.item_id.toString()"
            v-model="item.check"
            :name="item.item?.name"
            :checked="item.check"
          >
            {{ item.item?.name }} ({{ category.currency?.name }}
            {{ item.price }})
          </b-form-checkbox>
        </div>
        <div
          class="category-selection__list__item"
          :class="{
            selected: item.state,
            disabled: item.disabled,
            'pre-selected': item.pre_selected,
          }"
          v-else-if="item.options.length && item.user_selection == 1"
        >
          <b-form-checkbox
            @change="
              getItemsData(item.check, item.price, 'item', item?.options, x)
            "
            :id="item.item_id.toString()"
            v-model="item.check"
            :name="item.item?.name"
            :checked="item.check"
          >
            {{ item.item?.name }} ({{ category.currency?.name }}
            {{ item.price }})
          </b-form-checkbox>
          <span v-for="(option, i) in item.options" :key="i">
            <div
              class="category-selection__list__sub"
              v-if="option.user_selection == 1 && item.check"
            >
              <div class="category-selection__list__sub__items">
                <div
                  :class="{
                    'quantity-block': option.state && !option.quantity < 1,
                    checked: option.state,
                  }"
                  class="category-selection__list__sub__items__checkbox"
                >
                  <b-form-checkbox
                    @change="
                      getItemsData(
                        option.check,
                        option.price,
                        'option',
                        item?.options,
                        i
                      )
                    "
                    :id="option.id.toString()"
                    v-model="option.check"
                    :name="option.name"
                  >
                    {{ option.name }} ({{ category.currency.name }}
                    {{ option.price }})
                  </b-form-checkbox>
                  <div
                    class="input-block"
                    v-if="option.check && option.multiple_quantity"
                    :class="{ error: option.quantity < 1 }"
                  >
                    <div class="d-flex gap-2">
                      <label for="" class="mb-0 flex-shrink-0">Quantity:</label>
                      <div class="d-flex align-items-center">
                        <span
                          class="minuse"
                          @click="quantityDecrement(option.id)"
                        >
                          <i class="fa-solid fa-minus"></i>
                        </span>
                        <input
                          type="number"
                          inputmode="numeric"
                          v-model="option.quantity"
                          @input="quantityValidation(option.quantity)"
                          :class="{ error: option.quantity < 1 }"
                          readonly
                        />

                        <span
                          class="plus"
                          @click="quantityIncrement(option.id)"
                        >
                          <i class="fa-solid fa-plus"></i>
                        </span>
                      </div>
                    </div>
                    <small
                      class="[ d-flex gap-2 align-items-center ]"
                      v-if="option.quantity < 1"
                    >
                      <i class="fa-solid fa-triangle-exclamation"></i>
                      {{ errorState_message }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>
        <template v-else-if="item.options.length && item.user_selection == 0">
          <template v-for="(option, i) in item.options">
            <div
              class="category-selection__list__item"
              :class="{
                selected: item.state,
                disabled: item.disabled,
                'pre-selected': item.pre_selected,
              }"
              v-if="option.user_selection == 1"
              :key="i"
            >
              <div class="category-selection__list__sub">
                <div class="category-selection__list__sub__items">
                  <div
                    :class="{
                      'quantity-block': option.state && !option.quantity < 1,
                      checked: option.state,
                    }"
                    class="category-selection__list__sub__items__checkbox"
                  >
                    <b-form-checkbox
                      @change="
                        getItemsData(
                          option.check,
                          option.price,
                          'option',
                          item?.options,
                          i
                        )
                      "
                      :id="option.id.toString()"
                      v-model="option.check"
                      :name="option.name"
                    >
                      {{ option.name }} ({{ category.currency.name }}
                      {{ option.price }})
                    </b-form-checkbox>
                    <div
                      class="input-block"
                      v-if="option.check && option.multiple_quantity"
                      :class="{ error: option.quantity < 1 }"
                    >
                      <div class="d-flex gap-2">
                        <label for="" class="mb-0 flex-shrink-0"
                          >Quantity:</label
                        >
                        <div class="d-flex align-items-center">
                          <span
                            class="minuse"
                            @click="quantityDecrement(option.id)"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </span>
                          <input
                            type="number"
                            inputmode="numeric"
                            v-model="option.quantity"
                            @input="quantityValidation(option.quantity)"
                            :class="{ error: option.quantity < 1 }"
                            readonly
                          />

                          <span
                            class="plus"
                            @click="quantityIncrement(option.id)"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </span>
                        </div>
                      </div>
                      <small
                        class="[ d-flex gap-2 align-items-center ]"
                        v-if="option.quantity < 1"
                      >
                        <i class="fa-solid fa-triangle-exclamation"></i>
                        {{ errorState_message }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategorySectionAndItems",
  props: {
    sectionDataList: [],
    category_data: {},
    items_options_api_errors: {},
  },
  computed: {
    checkQuantity: {
      get() {
        return this.quantityValidation();
      },
      set(value) {
        this.quantityValidation(value);
      },
    },
    shouldDisplayMainDev() {
      return (
        this.dataList.some(
          (item) =>
            (item.pre_selected === 1 && item.user_selection === 1) ||
            (item.pre_selected === 0 && item.user_selection === 1)
        ) ||
        this.dataList.some((item) =>
          item.options.some(
            (option) =>
              (option.pre_selected === 1 &&
                option.user_selection === 1 &&
                item.check &&
                item.pre_selected === 1) ||
              (option.pre_selected === 0 &&
                option.user_selection === 1 &&
                (item.check || item.pre_selected === 1))
          )
        )
      );
    },
  },
  data() {
    return {
      customKey: 1,
      dataList: this.sectionDataList,
      errorState: false,
      errorState_message: "Quantity cannot be less than 1",
      quantityItemId: null,
      category: {},
      sub_total: 0,
      totalPrice: 0,
      percentage_amount: 0,
    };
  },

  methods: {
    sortedOptions(options) {
      let opt = [...options];
      if (
        opt.some((option) =>
          Object.prototype.hasOwnProperty.call(option, "order")
        )
      ) {
        return opt.sort((a, b) => (a.order || 0) - (b.order || 0));
      } else {
        // If "order" property is not available, return opt as they are
        return opt;
      }
    },
    tax_calculation() {
      const inclusive_of_tax =
        this.$store.getters.getEditionData.edition_data.inclusive_of_tax;
      const percentage_tax = this.$store.getters.getVat / 100;

      if (inclusive_of_tax) {
        this.percentage_amount =
          this.$store.getters.getItemAndOptionPrice * percentage_tax;
        this.sub_total =
          this.$store.getters.getItemAndOptionPrice -
          parseFloat(this.percentage_amount.toFixed(2));
        this.$store.commit(
          "setTooltipMessage",
          `The prices above are inclusive of ${
            this.$store.getters.getVat
          }% Value added tax; ${
            this.$store.getters.getCurrency
          } ${this.percentage_amount.toFixed(2)}`
        );
      } else {
        this.percentage_amount =
          this.$store.getters.getActualPrice * percentage_tax;
        this.sub_total = this.$store.getters.getActualPrice;
        this.$store.commit(
          "setItemAndOptionPrice",
          this.sub_total + parseFloat(this.percentage_amount.toFixed(2))
        );
        this.$store.commit(
          "setTooltipMessage",
          `The prices above are exclusive of ${
            this.$store.getters.getVat
          }% Value added tax; ${
            this.$store.getters.getCurrency
          } ${this.percentage_amount.toFixed(2)}`
        );
      }
      this.$store.commit("setSubTotal", this.sub_total);
      this.$store.commit("setVatAmount", this.percentage_amount.toFixed(2));
    },
    reCalculatePrice(item, status) {
      item.forEach(
        function (option) {
          let optionPrice = 0;
          var total = 0;
          if (option && !status && option.check) {
            option.check = false;
            optionPrice = option.price * option.quantity;
            option.quantity = 1;
            total = this.$store.getters.getItemAndOptionPrice - optionPrice;
            this.$store.commit("setItemAndOptionPrice", total);
            this.$store.commit(
              "setActualPrice",
              this.$store.getters.getActualPrice - optionPrice
            );
            this.tax_calculation();
          }
          if (option && status && option.pre_selected == 1) {
            option.check = true;
            optionPrice = optionPrice + option.price;
            const total =
              this.$store.getters.getItemAndOptionPrice + optionPrice;
            this.$store.commit("setItemAndOptionPrice", total);
            this.$store.commit(
              "setActualPrice",
              this.$store.getters.getActualPrice + optionPrice
            );
            this.tax_calculation();
          }
        }.bind(this)
      );
      this.customKey++;
    },
    getItemsData(status, price, type, item, index) {
      if (status) {
        const total = price + this.$store.getters.getItemAndOptionPrice;
        this.$store.commit("setItemAndOptionPrice", total);
        this.$store.commit(
          "setActualPrice",
          this.$store.getters.getActualPrice + price
        );
        this.tax_calculation();
      } else {
        if (type == "option") {
          let OptionPrice = item[index].price * item[index].quantity;
          const total = this.$store.getters.getItemAndOptionPrice - OptionPrice;
          item[index].quantity = 1;
          this.$store.commit("setItemAndOptionPrice", total);
          this.$store.commit(
            "setActualPrice",
            this.$store.getters.getActualPrice - OptionPrice
          );
          this.tax_calculation();
        } else {
          const total = this.$store.getters.getItemAndOptionPrice - price;
          this.$store.commit("setItemAndOptionPrice", total);
          this.$store.commit(
            "setActualPrice",
            this.$store.getters.getActualPrice - price
          );
          this.tax_calculation();
        }
      }
      if (type == "item") {
        this.reCalculatePrice(item, status);
      }
      this.$emit("items_data", null);
      this.customKey++;
    },

    quantityValidation(quantity) {
      if (quantity < 1) {
        return (this.errorState = true);
      } else {
        this.errorState = false;
        return (quantity = 1);
      }
    },
    quantityIncrement(itemId) {
      let foundItem;
      this.dataList.some((item) => {
        foundItem = item.options.find((option) => option.id == itemId);
        return foundItem !== undefined;
      });

      if (foundItem) {
        let currentValue = +foundItem.quantity;
        foundItem.quantity = Number(currentValue + 1);
        const total =
          this.$store.getters.getItemAndOptionPrice + foundItem.price;
        this.$store.commit("setItemAndOptionPrice", total);
        this.$store.commit(
          "setActualPrice",
          this.$store.getters.getActualPrice + foundItem.price
        );
        this.tax_calculation();
      }
    },
    quantityDecrement(itemId) {
      let foundItem;
      this.dataList.some((item) => {
        foundItem = item.options.find((option) => option.id == itemId);
        return foundItem !== undefined;
      });
      if (foundItem) {
        let currentValue = +foundItem.quantity;
        if (currentValue === 1) {
          foundItem.quantity = Number(1);
        } else {
          foundItem.quantity = Number(currentValue - 1);
          const total =
            this.$store.getters.getItemAndOptionPrice - foundItem.price;
          this.$store.commit("setItemAndOptionPrice", total);
          this.$store.commit(
            "setActualPrice",
            this.$store.getters.getActualPrice - foundItem.price
          );
          this.tax_calculation();
        }
      }
    },
  },
  watch: {
    sectionDataList(value) {
      this.dataList = value;
    },
    api_errors(errors) {
      console.log(errors);
    },
    category_data(data) {
      this.category = data;
    },
  },
};
</script>

<style lang="scss">
.category-selection {
  position: relative;

  &__list {
    --section-padding-x: calc(0.5rem + 0.25vw);
    --section-padding-y: calc(0.35rem + 0.25vw);

    --section-border-color: var(--bs-gray-400);
    --section-bg-color: var(--bs-white);
    --section-color: var(--bs-dark);

    --section-checkbox-width: 1.25rem;
    --section-checkbox-bg-color: var(--bs-white);
    --section-checkbox-border-color: var(--bs-gray-400);
    --section-checkbox-color: var(--bs-white);

    --section-checkbox-checked-bg-color: rgba(var(--bs-primary), 1);
    --section-checkbox-checked-border-color: rgba(var(--bs-primary), 1);

    --section-border-active-color: rgba(var(--bs-primary), 1);
    --section-bg-active-color: rgba(var(--bs-primary), 0.075);
    --section-active-color: var(--bs-dark);
    --section-active-shadow-color: rgba(var(--bs-primary), 1);
    --section-shadow-size: 1px;

    --section-border-disabled-color: var(--bs-gray-300);
    --section-bg-disabled-color: var(--bs-gray-200);
    --section-disabled-color: var(--bs-dark);

    --section-border-radius: 0.35rem;

    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .custom-control {
      padding-left: calc(var(--section-checkbox-width) + 10px);

      &-label {
        cursor: pointer;

        &::before,
        &::after {
          width: var(--section-checkbox-width);
          height: var(--section-checkbox-width);
          top: calc(50% - calc(var(--section-checkbox-width) / 2.15));
          left: calc(-0px - var(--section-checkbox-width) - 10px);
          transition: var(--transition);
        }

        &::after {
          content: "\f00c";
          @include fontAwesome();
          scale: 0;
          background-image: url("@/../public/images/check-icon.svg") !important;
          background-image: none !important;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--section-checkbox-color);
        }

        &::before {
          background-color: var(--section-checkbox-bg-color);
          border-color: var(--section-checkbox-border-color);
        }
      }

      &-input:checked {
        ~ .custom-control-label {
          &::before {
            scale: 1.2;
            background-color: var(--section-checkbox-checked-bg-color);
            border-color: var(--section-checkbox-checked-border-color);
          }

          &::after {
            scale: 1;
          }
        }
      }
    }

    &__item {
      position: relative;
      padding-inline: var(--section-padding-x);
      padding-block: var(--section-padding-y);
      border: 1px solid var(--section-border-color);
      background-color: var(--section-bg-color);
      color: var(--section-color);
      border-radius: var(--section-border-radius);
      overflow: hidden;
      isolation: isolate;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 100%;
        transition: var(--transition);
        background-color: var(--section-bg-active-color);
        top: 0;
        right: 0;
        z-index: -1;
      }

      &:is(&.selected, .selected.pre-selected):not(.disabled) {
        box-shadow: 0 0 0 var(--section-shadow-size)
          var(--section-active-shadow-color);
        border-color: var(--section-border-active-color);
        background-color: transparent;
        color: var(--section-active-color);

        &::after {
          left: 0;
          width: 100%;
        }

        > .custom-control label {
          font-weight: 700;
        }
      }

      &:is(.disabled):not(.selected, .pre-selected) {
        border-color: var(--section-border-disabled-color);
        background-color: var(--section-bg-disabled-color);
        color: var(--section-disabled-color);
        cursor: not-allowed;

        .custom-control {
          pointer-events: none;

          &-label {
            &::after {
              content: "\f00d";
              color: var(--bs-gray-500);
              scale: 1;
              background-image: none;
            }
          }
        }
      }
    }

    .input-block {
      position: relative;
      gap: 1rem;
      background-color: var(--bs-white);
      color: var(--bs-dark);
      padding-inline: var(--section-padding-x);
      padding-block: calc(var(--section-padding-y) / 2);
      --input-height: 30px;
      border: solid 2px rgba(var(--bs-primary), 1);

      .minuse,
      .plus {
        display: flex;
        justify-content: center;
        align-items: center;
        height: var(--input-height);
        width: var(--input-height);
        // box-shadow: inset 0 0 0 1px var(--bs-gray-400);
        background-color: var(--bs-gray-200);
        transition: var(--transition);
        color: var(--bs-dark);

        &:hover {
          background-color: rgba(var(--bs-primary), 1);
          color: var(--bs-white);
        }
      }

      .minuse {
        border-radius: 0.25rem 0 0 0.25rem;
      }

      .plus {
        border-radius: 0 0.25rem 0.25rem 0;
      }

      input {
        outline: none;
        outline-color: transparent;
        height: var(--input-height);
        border: 0;
        font-size: 125%;
        max-width: 50px;
        text-align: center;
        background-color: var(--bs-gray-200);
        // box-shadow: inset 0 0 0 1px var(--bs-gray-400);
        transition: var(--transition);
        appearance: none;

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        // &[type=number] { -moz-appearance: textfield; }

        &:focus {
          box-shadow: inset 0 0 0 1px var(--item-bg-active-color);
        }
      }

      &.error {
        background-color: var(--bs-danger);
        color: var(--bs-white);
      }
    }

    &__sub {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: calc(0.5rem + 0.25vw);
      padding-block: var(--section-padding-y)
        calc(var(--section-padding-y) / 1.75);
      transition: var(--transition);

      &__items {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 0.6rem;

        --item-border-color: var(--bs-gray-400);
        --item-border-active-color: rgba(var(--bs-primary), 1);
        --item-bg-color: var(--bs-gray-200);
        --item-bg-active-color: rgba(var(--bs-primary), 1);
        --item-color: var(--bs-dark);
        --item-active-color: var(--bs-white);

        --item-checkbox-bg-color: var(--bs-white);
        --item-checkbox-border-color: var(--bs-gray-500);
        --item-checkbox-color: rgba(var(--bs-primary), 1);

        --item-checkbox-checked-bg-color: var(--bs-white);
        --item-checkbox-checked-border-color: rgba(var(--bs-primary), 1);

        &__checkbox {
          position: relative;
          box-shadow: inset 0 0 0 1px var(--item-border-color);
          background-color: var(--item-bg-color);
          color: var(--item-color);
          border-radius: var(--section-border-radius);
          overflow: hidden;
          cursor: pointer;
          width: max-content;

          .custom-control {
            padding: 0;
            flex-shrink: 0;

            &-label {
              --section-checkbox-width: 1.25rem;
              padding-block: calc(var(--section-padding-y) / 1.25);
              padding-inline: calc(var(--section-checkbox-width) + 18px)
                var(--section-padding-x);

              &::before,
              &::after {
                left: calc(var(--section-checkbox-width) / 2);
                padding-block-start: 0.15rem;
              }

              &::before {
                background-color: var(--item-checkbox-bg-color);
                border-color: var(--item-checkbox-border-color);
              }

              &::after {
                color: var(--item-checkbox-color);
                background-position: top calc(var(--section-checkbox-width) / 4)
                  center;
              }
            }

            &-input:checked {
              ~ .custom-control-label {
                font-weight: 700;
                color: var(--item-active-color);

                // &::before { background-color: var(--section-bg-active-color); }
                &::after {
                  color: var(--item-checkbox-color);
                }

                &::before {
                  background-color: var(--item-checkbox-checked-bg-color);
                  border-color: var(--item-checkbox-checked-border-color);
                }
              }
            }
          }

          &.checked {
            background-color: var(--item-bg-active-color);
            box-shadow: 0 0 0 1px rgba(var(--bs-primary), 1),
              0 10px 10px -5px rgba(var(--bs-primary), 0.25) !important;
          }
        }
      }
    }
  }
}
</style>
