<template>
  <b-tab title="Category Details" id="tab2">
    <div class="form-tab-body-content pb-5">
      <template v-if="exception_message">
        <div
          class="alert alert-danger"
          role="alert"
          v-html="exception_message"
        ></div>
      </template>
      <div class="row">
        <div class="col-12 col-lg-10 col-xl-8 mx-auto">
          <div class="input-block w-100">
            <label for="title" class="mb-1">
              Registration Category <i class="fa-solid fa-star-of-life"></i>
            </label>
            <b-form-select
              v-if="getCategories"
              v-model="selected_reg_category"
              @change="getItemsAndOptions"
            >
              <b-form-select-option :value="null" disabled
                >Please Select a Category</b-form-select-option
              >
              <b-form-select-option
                :value="category.registration_category_id"
                v-for="(category, index) in getCategories"
                :key="index"
              >
                {{ category.name }}
                <i v-if="category.price"
                  >- {{ category.currency.name }} {{ category.price }}</i
                >
              </b-form-select-option>
            </b-form-select>
            <div
              class="text-center text-danger"
              v-if="api_errors?.membership_error == 1"
            >
              Your SDS membership is invalid or will end before SIDC conference
              date, Please renew your membership at
              <a href="https://sds.org.sa" target="_blank">sds.org.sa</a>
            </div>
            <div class="text-center text-danger" v-else-if="api_errors?.debug">
              {{ api_errors.debug }}
            </div>
            <div
              v-if="
                selected_reg_category === null && errors.selected_reg_category
              "
              class="justify-content-center"
            >
              <p class="text-danger">{{ errors.selected_reg_category }}</p>
            </div>
          </div>
          <NotSelected
            v-if="
              selected_reg_category === null || selected_reg_category === ''
            "
          />
          <CategoryShortDetails
            @category_password_field="handleCategoryPasswordFieldData"
            @membership_field_data="handleMembershipCodeFieldData"
            @category_field_data="handleCategoryFieldData"
            :category_data="category_data"
            :categories="getCategories"
            :items="items"
            :encrypted_registration_id="
              this.registration_data.encrypted_registration_id
            "
            :errors="errors"
            :api_errors="api_errors"
            @items_data="handleItemsData"
            @get_file="handleFileObject"
            v-else
          />
        </div>
      </div>
    </div>
    <div class="form-tab-footer">
      <div>
        <FooterPaymentSummary
          :key="dynamicKey"
          ref="footer_summary"
          :category_data="category_data"
          :total_price="total_price"
          :get_item="get_item"
          :get_option="get_option"
          :get_category_items_options_pricing="category_pricing"
        />
      </div>
      <b-button-group>
        <b-button size="lg" variant="white" @click="decrementTab(tabIndexing)">
          <i class="fa-solid fa-angle-left"></i>
          Attendee Information
        </b-button>
        <b-button
          variant="primary"
          class=""
          size="lg"
          @click="tabIndexingHandler(tabIndexing, 'increement')"
        >
          Continue
          <i class="fa-solid fa-angle-right"></i>
        </b-button>
      </b-button-group>
    </div>
    <PartnersAndSoponsersLogos />
  </b-tab>
</template>

<script>
// import CategoryShortDetails from './CategoryShortDetails.vue';
import NotSelected from "./NotSelected.vue";
import FooterPaymentSummary from "./FooterPaymentSummary.vue";
import gql from "graphql-tag";
import PartnersAndSoponsersLogos from "../PartnersAndSoponsersLogos.vue";
import CategoryShortDetails from "@/components/tabs-data/CategoryShortDetails.vue";

export default {
  name: "Tab_categoriesDetails",
  props: ["tabIndex", "registration_data"],
  // components: { FooterPaymentSummary, NotSelected, CategoryShortDetails },
  components: {
    CategoryShortDetails,
    FooterPaymentSummary,
    NotSelected,
    PartnersAndSoponsersLogos,
  },
  beforeCreate() {
    this.$store.dispatch("getEditionData", {
      type: this.$route.query.type ?? this.$route.params.type,
      event: this.$route.query.event ?? this.$route.params.event_id,
    });
  },
  data() {
    return {
      dynamicKey: 0,
      promotion_code_status: false,
      sub_total: 0,
      actual_price: 0,
      parent_check: false,
      tabIndexing: this.tabIndex,
      reg_categories: [],
      selected_reg_category: null,
      category_items_options: {},
      category_data: {},
      items: [],
      errorState_message: "Quantity cannot be less than 1",
      category_password: null,
      category_password_error_message: null,
      membership_error_message: null,
      membership_code: null,
      item_option: [],
      total_price: 0,
      get_item: {},
      get_option: {},
      errors: {
        selected_reg_category: null,
        category_password: null,
        membership_code: null,
        professional_id: null,
      },
      api_errors: {},
      customObjects: [],
      professional_id: null,
      uploadedFile: null,
      category_pricing: null,
      exception_message: "",
    };
  },
  methods: {
    decrementTab(index) {
      let btn1 = document.getElementById("tab1___BV_tab_button__");
      btn1.scrollIntoView(true);

      this.$scrollToTop();

      return this.$emit("getIndex", (this.tabIndexing = index - 2));
    },
    handleItemsData(data) {
      console.log("data object array:ffff", data);
      console.log(this.items);
    },
    handleCategoryPasswordFieldData(data) {
      this.category_password = data.field;
      this.category_password_error_message = data.error;
    },
    handleMembershipCodeFieldData(data) {
      this.membership_code = data;
    },
    handleCategoryFieldData(data) {
      this.selected_reg_category = data;
      this.getItemsAndOptions();
    },
    handleFileObject(data) {
      console.log("file data", data);
      this.professional_id = data;
    },
    getFile(e) {
      // console.log(e.target.files[0])
      this.professional_id = e.target.files[0];
    },
    getItem() {
      this.get_item = this.items;
      this.$refs.footer_summary.setItems(this.items);
    },
    getOption(option) {
      this.get_option = option;
      this.$refs.footer_summary.setOptions();
    },
    getItemAndOptionPrice(item) {
      if (item.check === true) {
        this.total_price = this.total_price + item.price;
      } else {
        this.total_price = this.total_price - item.price;
      }
    },
    categoryTabValidation() {
      console.log(this.category_data);
      let flag = true;
      const registration_category_id = this.selected_reg_category;
      if (registration_category_id === null) {
        flag = false;
        this.errors.selected_reg_category = "Category field required.";
      }

      if (
        this.category_data.needs_password &&
        this.category_password === null
      ) {
        flag = false;
        this.errors.category_password = "Category password field required.";
      } else if (
        this.category_data.password != this.category_password &&
        this.category_password != null &&
        this.category_data.needs_password
      ) {
        flag = false;
        this.store_log_pass_member_professional(
          false,
          "Password is incorrect",
          "Password"
        );
        this.errors.category_password = "Password incorrect.";
      } else {
        this.errors.category_password = "";
        if (this.category_data.needs_password) {
          this.store_log_pass_member_professional(
            true,
            "Password is match",
            "Password"
          );
        }
      }
      if (
        this.category_data.membership_id_required &&
        this.membership_code === null
      ) {
        flag = false;
        this.errors.membership_code =
          this.category_data.membership_id_not_found_message;
      }
      if (
        this.category_data.professional_id_required &&
        this.professional_id === null
      ) {
        flag = false;
        this.errors.professional_id = "Professional id field required.";
        this.store_log_pass_member_professional(
          false,
          "Professional id field is empty",
          "Professional id"
        );
      } else {
        this.errors.professional_id = "";
        if (this.category_data.professional_id_required) {
          this.store_log_pass_member_professional(
            true,
            "Professional id provide",
            "Professional id"
          );
        }
      }
      if (flag === true) {
        Object.keys(this.errors).reduce((acc, key) => {
          acc[key] = null;
          return acc;
        }, {});
      }
      return flag;
    },
    tabIndexingHandler() {
      this.exception_message = "";
      let btn3 = document.getElementById("tab3___BV_tab_button__");
      btn3.scrollIntoView({
        block: "center",
      });

      const registration_category_id = this.selected_reg_category;
      const membership_code = this.membership_code;
      const encrypted_registration_id =
        this.registration_data.encrypted_registration_id;
      const category_password = this.category_password;
      const event = this.$store.state.edition_details.edition_data.event_id;
      const edition_id =
        this.$store.state.edition_details.edition_data.edition_id;
      const pre_selected_items = this.items
        .filter((item) => item.pre_selected === 1 && item.user_selection === 0)
        .map(function (item) {
          return item.id;
        });
      console.log("file object>>>>>>>>>>>", this.professional_id);
      console.log(pre_selected_items);
      console.log("category_id" + registration_category_id);
      console.log("membership_code" + membership_code);
      console.log("encrypted_registration_id" + encrypted_registration_id);
      console.log("category password" + category_password);
      console.log("event" + event);
      console.log("edition", edition_id);
      let items = [];
      items = pre_selected_items;
      const options = [];
      this.items.filter((el) => {
        if (el.check) {
          items.push(el.id);
        }
        el.options.filter((option) => {
          if (
            option.check ||
            (option?.pre_selected == 1 && option?.user_selection == 0)
          ) {
            options.push({ id: option.id, quantity: option.quantity });
          }
        });
      });
      items = [...new Set(items)];
      console.log("new items array >>>>>>> ", items);
      if (this.categoryTabValidation() === false) {
        return;
      }
      const request_data = {
        registration_category_id: registration_category_id,
        encrypted_registration_id: encrypted_registration_id,
        item_check: items,
        option_check: options,
        event_id: event,
        edition_id: edition_id,
        membership_code: membership_code,
        step: "2",
        promotion_code_status: this.promotion_code_status,
        // professional_id: this.professional_id
      };
      // console.log(typeof encrypted_registration_id);
      // console.log("options: ", options);
      this.$apollo
        .mutate({
          mutation: gql`
            mutation get_category_items_options_price(
              $registration_category_id: Int!
              $encrypted_registration_id: String!
              $item_check: [Int]
              $option_check: [Option]
              $event_id: Int!
              $edition_id: Int!
              $membership_code: String
              $step: String
              #              $professional_id: Upload
              $promotion_code_status: Boolean #                $professional_id: Upload
            ) {
              get_category_items_options_price(
                registration_category_id: $registration_category_id
                encrypted_registration_id: $encrypted_registration_id
                item_check: $item_check
                option_check: $option_check
                event_id: $event_id
                edition_id: $edition_id
                membership_code: $membership_code
                step: $step
                promotion_code_status: $promotion_code_status
                #                professional_id: $professional_id
              )
            }
          `,
          // mutation: this.$options.apollo.get_category_items_options_price.,
          variables: request_data,
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          const data = JSON.parse(res.data.get_category_items_options_price);
          if (data.code === 200) {
            this.$store.dispatch("getCategoryItemsOptionsPricing", data);
            // this.$scrollToTop();
            this.$store.dispatch("setCategoryOptionsRequestData", request_data);
            return this.$emit("getIndex", (this.tabIndexing = 2));
          } else {
            this.api_errors = data;
            if (
              this.api_errors.min_item_error ||
              this.api_errors.max_item_error
            ) {
              this.api_errors.debug = this.api_errors.item_error;
            } else if (
              this.api_errors.min_option_error == 1 ||
              this.api_errors.max_option_error == 1
            ) {
              this.api_errors.debug = this.api_errors.option_error;
            }
            console.log(this.api_errors);
          }
        })
        .catch(() => {
          this.exception_message =
            "Something went wrong.<br> Please contact " +
            this.editionManagerName +
            " on " +
            this.editionManagerPhone +
            " for assistance.";
          this.$scrollToTop();
        });
    },
    getItemsAndOptions() {
      let url_items = JSON.parse(
        this.$store.state.edition_details.url_details.items
      );
      let category_id = this.selected_reg_category;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation get_items_options_on_category_selection(
              $registration_category_id: Int!
              $url_items: [String!]!
            ) {
              get_items_options_on_category_selection(
                registration_category_id: $registration_category_id
                url_items: $url_items
              )
            }
          `,
          variables: {
            registration_category_id: category_id,
            url_items: url_items,
          },
        })
        .then((data) => {
          let res = JSON.parse(
            data.data.get_items_options_on_category_selection
          );
          // inclusive_of_tax
          let category_price = res.category_data?.price;
          let option_price = 0;
          let item_price = 0;

          this.$store.commit(
            "setVat",
            this.$store.getters.getEditionData.edition_data.vat
          );
          this.category_data = res.category_data;
          this.items = res.items;
          if (!Array.isArray(this.items)) {
            this.items = Object.values(this.items);
          }
          this.items.forEach(function (item) {
            if (item.pre_selected == 1) {
              item.check = true;
              item_price = item_price + item?.price;
            }
            if (item?.options) {
              item?.options.forEach(function (option) {
                if (option.pre_selected == 1 && item.pre_selected == 1) {
                  option.check = true;
                  option_price = option_price + option?.price;
                }
              });
            }
          });

          const totalPrice = option_price + item_price + category_price;
          if (totalPrice != 0) {
            this.$store.commit("setItemAndOptionPrice", totalPrice);
            this.$store.commit("setActualPrice", totalPrice);
          } else {
            this.$store.commit("setItemAndOptionPrice", 0);
            this.$store.commit("setActualPrice", 0);
          }
          this.$store.commit("setCategoryData", this.category_data);
          this.$store.commit("setDiscount", 0);
          this.tax_calculation();
        })
        .catch(() => {
          this.exception_message =
            "Something went wrong.<br> Please contact " +
            this.editionManagerName +
            " on " +
            this.editionManagerPhone +
            " for assistance.";
          this.$scrollToTop();
        });
    },
    tax_calculation() {
      const inclusive_of_tax =
        this.$store.getters.getEditionData.edition_data.inclusive_of_tax;
      const percentage_tax = this.$store.getters.getVat / 100;
      const percentage_amount =
        this.$store.getters.getItemAndOptionPrice * percentage_tax;
      if (inclusive_of_tax) {
        this.sub_total =
          this.$store.getters.getItemAndOptionPrice -
          parseFloat(percentage_amount.toFixed(2));
        this.$store.commit(
          "setTooltipMessage",
          `The prices above are inclusive of ${
            this.$store.getters.getVat
          }% Value added tax; ${
            this.$store.getters.getCurrency
          } ${percentage_amount.toFixed(2)}`
        );
      } else {
        this.sub_total = this.$store.getters.getItemAndOptionPrice;
        this.$store.commit("setActualPrice", this.sub_total);
        this.$store.commit(
          "setItemAndOptionPrice",
          this.sub_total + parseFloat(percentage_amount.toFixed(2))
        );
        this.$store.commit(
          "setTooltipMessage",
          `The prices above are exclusive of ${
            this.$store.getters.getVat
          }% Value added tax; ${
            this.$store.getters.getCurrency
          } ${percentage_amount.toFixed(2)}`
        );
      }
      this.$store.commit("setSubTotal", this.sub_total);
      this.$store.commit("setVatAmount", percentage_amount.toFixed(2));
    },
    quantityValidation(quantity) {
      console.log(quantity);
      if (quantity < 1) {
        quantity = 1;
        return (this.errorState = true);
      } else {
        this.errorState = false;
        return (quantity = 1);
      }
    },
    quantityIncrement(option) {
      return option.quantity++;
    },
    quantityDecrement(option) {
      if (option.quantity < 1) {
        option.quantity = 1;
        return option;
      }
      return option.quantity--;
    },
    checkCategoryPassword() {
      if (this.category_password !== this.category_data.password) {
        this.category_password_error_message = "Password incorrect";
        return false;
      } else {
        this.category_password_error_message = null;
        return true;
      }
    },

    store_log_pass_member_professional(status, message, lable_msg) {
      let registration_id = this.registration_data.registration_id;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation store_log_pass_member_professional_save(
              $registration_id: Int
              $status: Boolean
              $message: String
              $lable_msg: String
            ) {
              store_log_pass_member_professional_save(
                registration_id: $registration_id
                status: $status
                message: $message
                lable_msg: $lable_msg
              )
            }
          `,
          variables: {
            registration_id: registration_id,
            status: status,
            message: message,
            lable_msg: lable_msg,
          },
        })
        .then((data) => {
          console.log(data);
        });
    },

    checkMembershipCode() {
      let membership_code = this.membership_code;
      let membership_id = Number(this.category_data.membership_id);
      this.$apollo
        .mutate({
          mutation: gql`
            mutation verify_membership_code(
              $membership_id: Int!
              $value: String!
            ) {
              verify_membership_code(
                membership_id: $membership_id
                value: $value
              )
            }
          `,
          variables: {
            membership_id: membership_id,
            value: membership_code,
          },
        })
        .then((data) => {
          let res = JSON.parse(data.data.verify_membership_code);
          if (res.code === 404) {
            this.membership_error_message =
              this.category_data.membership_id_invalid_message;
          } else {
            this.membership_error_message = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectCategory() {
      var catergories =
        this.$store.state.edition_details?.url_details?.categories;
      if (catergories.length == 1) {
        this.selected_reg_category = catergories[0].registration_category_id;
        this.getItemsAndOptions();
      }
    },
    WatchTabIndex() {
      if (this.tabIndex == 1) {
        this.selected_reg_category = null;
        this.$store.commit("setItemAndOptionPrice", 0);
        this.$store.commit("setSubTotal", 0);
        this.$store.commit("setVatAmount", 0);
        this.$store.commit("setDiscount", 0);
        this.$store.commit("setActualPrice", 0);
        this.$store.commit(
          "setTooltipMessage",
          "The prices above are inclusive of 0.00% Value added tax; AED 0.00"
        );
        this.dynamicKey++;
        this.selectCategory();
      }
    },
  },
  mounted() {
    if (!this.selected_reg_category) {
      this.$store.commit("setItemAndOptionPrice", 0);
      this.$store.commit("setSubTotal", 0);
      this.$store.commit("setVatAmount", 0);
      this.$store.commit("setDiscount", 0);
      this.$store.commit("setActualPrice", 0);
      this.$store.commit(
        "setTooltipMessage",
        "The prices above are inclusive of 0.00% Value added tax; AED 0.00"
      );
      this.dynamicKey++;
    }
  },
  watch: {
    checkTabIndex() {
      this.WatchTabIndex();
    },
  },
  computed: {
    checkTabIndex() {
      return this.tabIndex;
    },
    getCategories() {
      return this.$store.state.edition_details?.categories;
    },
    editionManagerName() {
      return (
        this.$store.state.edition_details?.edition_data?.edition_manager_name ??
        ""
      );
    },
    editionManagerPhone() {
      return (
        this.$store.state.edition_details?.edition_data
          ?.edition_manager_phone ?? ""
      );
    },
  },
};
</script>

<style lang="scss">
.category-short-details {
  position: relative;

  &__content {
    position: relative;
  }

  &__text {
    --block-padding-x: 1rem;
    --continue-btn-width: 150px;
    position: relative;
    padding-inline-start: var(--block-padding-x);
    box-shadow: inset calc(var(--block-padding-x) / 3) 0 0
      rgba(var(--bs-primary), 0.75);
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    span:not(.continue-reading) {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - var(--continue-btn-width) - 0.5vw);
    }

    .continue-reading {
      width: var(--continue-btn-width);
      position: absolute;
      right: 0;
      text-decoration: underline;
      padding-inline-start: 1rem;
      font-style: italic;
      font-weight: 600;
      transition: var(--transition);

      &:hover {
        font-style: normal;
        color: rgba(var(--bs-primary), 1);
        cursor: pointer;
      }
    }
  }
}

.selected-pills {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.55rem;

  &__pill {
    --pill-bg-color: rgba(var(--bs-primary), 1);
    --pill-bg-hover-color: rgba(var(--bs-secondary), 1);
    --pill-color: var(--bs-white);
    --pill-hover-color: var(--bs-white);

    cursor: default;
    padding-block: 0.35rem;
    padding-inline: 0.75rem;
    border-radius: 0.25rem;
    background-color: rgba(var(--bs-primary), 1);
    color: var(--pill-color);
    display: flex;
    align-items: center;
    gap: 0.75rem;
    transition: var(--transition);
    border: solid 1px rgba(var(--bs-primary), 1);

    &:hover {
      background-color: #fff;
      color: rgba(var(--bs-primary), 1);
    }
  }
}

.modal {
  &-body {
    position: relative;

    .nav-pills {
      --bs-nav-pills-border-radius: 0.375rem;
      --bs-nav-pills-link-active-color: var(--bs-white);
      --bs-nav-pills-link-active-bg: rgba(var(--bs-primary), 1);
      display: flex;
      gap: 0.5rem;

      .nav-link {
        --bs-nav-link-color: var(--bs-dark);
        --bs-nav-bg-color: var(--bs-gray-200);
        --bs-nav-bg-hover-color: rgba(var(--bs-secondary), 1);
        --bs-nav-link-hover-color: var(--bs-dark);

        background-color: var(--bs-nav-bg-color);
        color: var(--bs-nav-link-color);
        z-index: 0;

        &.active {
          // box-shadow: 0 10px 20px -10px rgba(var(--bs-primary), .5) !important;
          z-index: 2;
        }

        &:hover {
          z-index: 1;
          background-color: rgba(var(--bs-primary), 1);
          color: var(--bs-white);
        }
      }
    }

    .tab-pane {
      position: relative;
      height: 100%;
    }

    &-tab-content {
      border-left: 1px solid var(--bs-gray-300);
      position: relative;
      padding-inline: calc(1rem + 1vw) calc(1rem + 0.25vw);
      padding-block-end: 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.tab-container {
  position: relative;

  @media (max-width: 767.85px) {
    padding: calc(var(--bs-gutter-x) / 2);

    .col-auto {
      padding: 0;
      margin-bottom: var(--bs-gutter-x);
    }

    .tab-content {
      padding: 0;

      .modal-body-tab-content {
        border-left: 0;
        border-top: 1px solid var(--bs-gray-300);
        padding: var(--bs-gutter-x) 0 0;
      }
    }
  }
}

.uploading-document-container {
  position: relative;
  padding: 1rem;
  border: 1px solid var(--bs-gray-400);
  background-color: var(--bs-gray-100);
  border-radius: 0.35rem;
}

.category-selection {
  position: relative;

  &__list {
    --section-padding-x: calc(0.5rem + 0.25vw);
    --section-padding-y: calc(0.35rem + 0.25vw);

    --section-border-color: var(--bs-gray-400);
    --section-bg-color: var(--bs-white);
    --section-color: var(--bs-dark);

    --section-checkbox-width: 1.25rem;
    --section-checkbox-bg-color: var(--bs-white);
    --section-checkbox-border-color: var(--bs-gray-400);
    --section-checkbox-color: var(--bs-white);

    --section-checkbox-checked-bg-color: rgba(var(--bs-primary), 1);
    --section-checkbox-checked-border-color: rgba(var(--bs-primary), 1);

    --section-border-active-color: rgba(var(--bs-primary), 1);
    --section-bg-active-color: rgba(var(--bs-primary), 0.075);
    --section-active-color: var(--bs-dark);
    --section-active-shadow-color: rgba(var(--bs-primary), 1);
    --section-shadow-size: 1px;

    --section-border-disabled-color: var(--bs-gray-300);
    --section-bg-disabled-color: var(--bs-gray-200);
    --section-disabled-color: var(--bs-dark);

    --section-border-radius: 0.35rem;

    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .custom-control {
      padding-left: calc(var(--section-checkbox-width) + 10px);

      &-label {
        cursor: pointer;

        &::before,
        &::after {
          width: var(--section-checkbox-width);
          height: var(--section-checkbox-width);
          top: calc(50% - calc(var(--section-checkbox-width) / 2.15));
          left: calc(-0px - var(--section-checkbox-width) - 10px);
          transition: var(--transition);
        }

        &::after {
          content: "\f00c";
          @include fontAwesome();
          scale: 0;
          background-image: url("@/../public/images/check-icon.svg") !important;
          background-image: none !important;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--section-checkbox-color);
        }

        &::before {
          background-color: var(--section-checkbox-bg-color);
          border-color: var(--section-checkbox-border-color);
        }
      }

      &-input:checked {
        ~ .custom-control-label {
          &::before {
            // --checkbox-bg-checked: rgba(var(--bs-primary), 1);
            scale: 1.2;
            // background-color: var(--bs-primary);
            // border-color: var(--bs-primary);
            background-color: rgba(var(--bs-primary), 1);
            border-color: rgba(var(--bs-primary), 1);
          }

          &::after {
            scale: 1;
          }
        }
      }
    }

    &__item {
      position: relative;
      padding-inline: var(--section-padding-x);
      padding-block: var(--section-padding-y);
      border: 1px solid var(--section-border-color);
      background-color: var(--section-bg-color);
      color: var(--section-color);
      border-radius: var(--section-border-radius);
      overflow: hidden;
      isolation: isolate;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 100%;
        transition: var(--transition);
        background-color: var(--section-bg-active-color);
        top: 0;
        right: 0;
        z-index: -1;
      }

      &:is(&.selected, .selected.pre-selected):not(.disabled) {
        box-shadow: 0 0 0 var(--section-shadow-size)
          var(--section-active-shadow-color);
        border-color: var(--section-border-active-color);
        background-color: transparent;
        color: var(--section-active-color);

        &::after {
          left: 0;
          width: 100%;
        }

        > .custom-control label {
          font-weight: 700;
        }
      }

      &:is(.disabled):not(.selected, .pre-selected) {
        border-color: var(--section-border-disabled-color);
        background-color: var(--section-bg-disabled-color);
        color: var(--section-disabled-color);
        cursor: not-allowed;

        .custom-control {
          pointer-events: none;

          &-label {
            &::after {
              content: "\f00d";
              color: var(--bs-gray-500);
              scale: 1;
              background-image: none;
            }
          }
        }
      }
    }

    .input-block {
      position: relative;
      gap: 1rem;
      background-color: var(--bs-white);
      color: var(--bs-dark);
      padding-inline: var(--section-padding-x);
      padding-block: calc(var(--section-padding-y) / 2);
      --input-height: 30px;

      .minuse,
      .plus {
        display: flex;
        justify-content: center;
        align-items: center;
        height: var(--input-height);
        width: var(--input-height);
        // box-shadow: inset 0 0 0 1px var(--bs-gray-400);
        background-color: var(--bs-gray-200);
        transition: var(--transition);
        color: var(--bs-dark);

        &:hover {
          background-color: rgba(var(--bs-primary), 1);
          color: var(--bs-white);
        }
      }

      .minuse {
        border-radius: 0.25rem 0 0 0.25rem;
      }

      .plus {
        border-radius: 0 0.25rem 0.25rem 0;
      }

      input {
        outline: none;
        outline-color: transparent;
        height: var(--input-height);
        border: 0;
        font-size: 125%;
        max-width: 50px;
        text-align: center;
        background-color: var(--bs-gray-200);
        // box-shadow: inset 0 0 0 1px var(--bs-gray-400);
        transition: var(--transition);
        appearance: none;

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        // &[type=number] { -moz-appearance: textfield; }

        &:focus {
          box-shadow: inset 0 0 0 1px var(--item-bg-active-color);
        }
      }

      &.error {
        background-color: var(--bs-danger);
        color: var(--bs-white);
      }
    }

    &__sub {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: calc(0.5rem + 0.25vw);
      padding-block: var(--section-padding-y)
        calc(var(--section-padding-y) / 1.75);
      transition: var(--transition);

      &__items {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 0.6rem;

        --item-border-color: var(--bs-gray-400);
        --item-border-active-color: rgba(var(--bs-primary), 1);
        --item-bg-color: var(--bs-gray-200);
        --item-bg-active-color: rgba(var(--bs-primary), 1);
        --item-color: var(--bs-dark);
        --item-active-color: var(--bs-white);

        --item-checkbox-bg-color: var(--bs-white);
        --item-checkbox-border-color: var(--bs-gray-500);
        --item-checkbox-color: rgba(var(--bs-primary), 1);

        --item-checkbox-checked-bg-color: var(--bs-white);
        --item-checkbox-checked-border-color: rgba(var(--bs-primary), 1);

        &__checkbox {
          position: relative;
          box-shadow: inset 0 0 0 1px var(--item-border-color);
          background-color: var(--item-bg-color);
          color: var(--item-color);
          border-radius: var(--section-border-radius);
          overflow: hidden;
          cursor: pointer;
          width: max-content;

          .custom-control {
            padding: 0;
            flex-shrink: 0;

            &-label {
              --section-checkbox-width: 1.25rem;
              padding-block: calc(var(--section-padding-y) / 1.25);
              padding-inline: calc(var(--section-checkbox-width) + 18px)
                var(--section-padding-x);

              &::before,
              &::after {
                left: calc(var(--section-checkbox-width) / 2);
                padding-block-start: 0.15rem;
              }

              &::before {
                background-color: var(--item-checkbox-bg-color);
                border-color: var(--item-checkbox-border-color);
              }

              &::after {
                color: var(--item-checkbox-color);
                background-position: top calc(var(--section-checkbox-width) / 4)
                  center;
              }
            }

            &-input:checked {
              ~ .custom-control-label {
                font-weight: 700;
                background-color: rgba(var(--bs-primary), 1);
                color: var(--item-active-color);
                width: 100%;

                // &::before { background-color: var(--section-bg-active-color); }
                &::after {
                  color: var(--item-checkbox-color);
                }

                &::before {
                  background-color: var(--item-checkbox-checked-bg-color);
                  border-color: var(--item-checkbox-checked-border-color);
                }
              }
            }
          }

          &.checked {
            background-color: var(--item-bg-active-color);
            box-shadow: 0 0 0 1px rgba(var(--bs-primary), 1),
              0 10px 10px -5px rgba(var(--bs-primary), 0.25) !important;
          }
        }
      }
    }
  }
}
</style>
