export function trackPageView (gtag, route, page_title) {
  try {
    if (!gtag) {
      return
    }
    gtag.pageview({
      page_title: page_title,
      page_path: route.path,
      page_location: window.location.href,
      send_page_view: true,
    })
  } catch (e) {
    console.error('analytics: ', e)
  }
}
