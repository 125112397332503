<template>
  <section>
    <div>

      <div class="share-modal-body" v-if="showSocialMediaModal && getRegistrationData?.edition_data?.feedback_enabled && feedback_required && !loading">
        <FeedBack @hideFeedbackArea="hideFeedbackArea" />
      </div>
    </div>

    <div class="container registration-conformation-form-new">
   
      <div class="row justify-content-center">
        <div class="col-xl-9">
          <template v-if="exception_message">
            <div class="alert alert-danger" role="alert" v-html="exception_message"></div>
          </template>
          <div class="conformation-container">
            <div class="thankyou-msg">
              <div class="row justify-content-center">
                <div class="col-md-8 p-0">
                  <div class="content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="145.383" height="126.692" viewBox="0 0 145.383 126.692">
  <g id="Group_6872" data-name="Group 6872" transform="translate(0.034)">
    <path id="Path_4745" data-name="Path 4745" d="M229.453,31.616c4.17.405,12.891-.938,14.42-9.556s5.618-11,7.471-11.119" transform="translate(-107.586 -5.173)" fill="none" stroke="#5cb85c" stroke-linecap="round" stroke-width="3"/>
    <circle id="Ellipse_147" data-name="Ellipse 147" cx="41.69" cy="41.69" r="41.69" transform="translate(43.149 19.905)" fill="#5cb85c"/>
    <g id="Group_2528" data-name="Group 2528" transform="translate(48.914 25.669)">
      <circle id="Ellipse_148" data-name="Ellipse 148" cx="35.925" cy="35.925" r="35.925" fill="none" stroke="rgba(255,255,255,0.7)" stroke-width="2"/>
    </g>
    <path id="Path_4746" data-name="Path 4746" d="M161.128,95.706a4.187,4.187,0,0,1-1.255,3.014l-18.83,18.241a4.7,4.7,0,0,1-3.159,1.253,4.526,4.526,0,0,1-3.118-1.253l-9.435-9.12a4.19,4.19,0,0,1-1.255-3.014,4.3,4.3,0,0,1,1.3-3.014,4.793,4.793,0,0,1,3.118-1.253,4.565,4.565,0,0,1,3.118,1.214l6.276,6.067L153.6,92.653a4.564,4.564,0,0,1,3.118-1.213,4.639,4.639,0,0,1,3.118,1.292A4.138,4.138,0,0,1,161.128,95.706Z" transform="translate(-57.764 -43.232)" fill="#fff"/>
    <path id="Path_4747" data-name="Path 4747" d="M218.426,195.908c3.892,1.125,11.416,5.4,10.378,13.491" transform="translate(-102.372 -92.623)" fill="none" stroke="#5cb85c" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    <path id="Path_4748" data-name="Path 4748" d="M1.9,159.1c5.972,2.25,18.848,3.863,22.571-7.679m0,0c.543-3.18,0-9.47-6.515-9.191C16.713,145.3,16.279,151.425,24.469,151.425Zm0,0a12.4,12.4,0,0,0,12.565-5.933" transform="translate(0 -67.243)" fill="none" stroke="#5cb85c" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" opacity="0.2"/>
    <circle id="Ellipse_149" data-name="Ellipse 149" cx="3.996" cy="3.996" r="3.996" transform="translate(92.679 118.7)" fill="#5cb85c" opacity="0.3"/>
    <circle id="Ellipse_150" data-name="Ellipse 150" cx="3.732" cy="3.732" r="3.732" transform="translate(130.719 55.685)" fill="none" stroke="#5cb85c" stroke-width="1"/>
    <circle id="Ellipse_151" data-name="Ellipse 151" cx="4.376" cy="4.376" r="4.376" transform="translate(66.642 0)" fill="#5cb85c" opacity="0.2"/>
    <circle id="Ellipse_152" data-name="Ellipse 152" cx="2.311" cy="2.311" r="2.311" transform="translate(81.132 9.451)" fill="#5cb85c"/>
    <circle id="Ellipse_153" data-name="Ellipse 153" cx="3.786" cy="3.786" r="3.786" transform="translate(28.72 41.915)" fill="none" stroke="#5cb85c" stroke-width="1"/>
    <circle id="Ellipse_154" data-name="Ellipse 154" cx="4.19" cy="4.19" r="4.19" transform="translate(37.621 105.373)" fill="none" stroke="#5cb85c" stroke-width="1"/>
    <circle id="Ellipse_155" data-name="Ellipse 155" cx="2.128" cy="2.128" r="2.128" transform="translate(86.378 120.241) rotate(180)" fill="#5cb85c"/>
  </g>
</svg>

                    <h4 v-if="$store.state?.edition_details?.url_details?.confirmation_message_title">{{ $store.state?.edition_details?.url_details?.confirmation_message_title }}</h4>
                    <h4 v-else>Thank you! Your Registration is Confirmed.</h4>
                    <p v-if="$store.state?.edition_details?.url_details?.confirmation_message" v-html="$store.state?.edition_details?.url_details?.confirmation_message"></p>
                    <p v-else>Your event registration is confirmed. We look forward to welcoming you for a memorable experience.</p>
                  </div>
                </div>
              </div>
            </div>
            <ShareModal v-if="getRegistrationData?.edition_data?.shareable"/>
            <div class="downloadable-area" id="receipt">
              <ConformationPreloader  v-if="loading"/>
            <table class="table table-head" v-if="!loading">
              <tbody>
                <tr>
                  <td>
                    <div class="heading">
                      <h3>TAX RECEIPT</h3>
                      <p>
                        Thank you for registering for the<br />
                        <b>{{ edition_name }} </b>
                      </p>
                    </div>
                  </td>
                  <td align="right">
                    <div class="barcode">
                      <img
                        id="barcodeImage"
                        :src="`${backend_base_url}/barcode.php?id=${getRegistrationData?.contact?.registration_id}`"
                        alt="barcode"
                      />
                      <br />
                      <span id="registration_id">{{
                        getRegistrationData?.contact?.registration_id
                      }}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" align="right">
                    <span
                      ><b>Registration Date:</b>
                      {{
                        formatDate(getRegistrationData?.contact?.created_at)
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-body" v-if="!loading">
              <tbody>
                <tr>
                  <td colspan="2" height="10"></td>
                </tr>
                <tr>
                  <td>
                    <label for="">Name</label>
                    <span v-if="getRegistrationData?.contact?.first_name"
                      >{{ getRegistrationData?.contact?.first_name }}
                      {{ getRegistrationData?.contact?.last_name }}</span
                    >
                  </td>
                  <td>
                    <label for="">Registration Category</label>
                    <span>{{
                      getRegistrationData?.contact?.registration_category?.name
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td v-if="getRegistrationData?.edition_data?.address_line_1">
                    <label for="">Venue</label>

                    <span>
                      {{ getRegistrationData?.edition_data?.address_line_1 }}
                      <br />
                      {{ getRegistrationData?.edition_data?.address_line_2 }}
                    </span>
                  </td>
                  <td v-if="getRegistrationData?.contact?.entity_name">
                    <label for="">Company</label>
                    <span>{{ getRegistrationData?.contact?.entity_name }}</span>
                  </td>
                  
                </tr>
                <tr v-if="getRegistrationData?.contact?.address">
                  <td>
                    <label for="">Address</label>
                    <span>{{ getRegistrationData?.contact?.address }}</span>
                  </td>
                </tr>
                <tr>
                  <td v-if="getRegistrationData?.contact?.email">
                    <label for="">Email</label>
                    <span>{{ getRegistrationData?.contact?.email }}</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" height="10"></td>
                </tr>
              </tbody>
            </table>
            <table class="table table-footer" v-if="!loading">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Selection</th>
                  <th>QTY</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    {{
                      getRegistrationData?.contact?.registration_category?.name
                    }}
                  </td>
                  <td>1</td>
                  <td>
                    {{ getRegistrationData?.contact?.currency?.name }}
                    {{
                      getRegistrationData?.contact?.registration_category?.price.toFixed(2)
                    }}
                  </td>
                </tr>
                <tr
                  :key="key"
                  v-for="(item, key) in getPricingDetails?.items ??
                  getRegistrationItems"
                >
                  <td>{{ ++key + 1 }}</td>
                  <td>{{ item.name ?? item.item.name }}</td>
                  <td>1</td>
                  <td>
                    {{ getRegistrationData?.contact?.currency?.name }}
                    {{ item.price.toFixed(2) }}
                  </td>
                </tr>
                <tr
                  :key="`option_${key}`"
                  v-for="(item, key) in getPricingDetails?.options ??
                  getRegistrationData?.contact?.reg_options"
                >
                  <td>
                    {{
                      getPricingDetails?.items?.length > 0
                        ? ++key + getPricingDetails?.items?.length + 1
                        : ++key + getRegistrationItems?.length + 1
                    }}
                  </td>
                  <td>
                    {{
                      item.name ??
                      getRegistrationData?.contact?.registration_options?.filter(
                        (option) => option.id === item.option_id
                      )[0].name
                    }}
                  </td>
                  <td>{{ item.quantity ?? item?.qunatity }}</td>
                  <td>
                    {{ getRegistrationData?.contact?.currency?.name }}
                    {{
                      optionPrice(item.price
                        ? item.price * item?.qunatity
                        : item.r_gross_amount * item?.qunatity)
                    }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td align="right" colspan="4">
                    <table class="table table-total" width="260px">
                      <tbody>
                        <tr>
                          <td colspan="2" height="15"></td>
                        </tr>
                        <tr>
                          <td>Discount</td>
                          <td>
                            {{ getRegistrationData?.contact?.currency?.name }}
                            {{ discount.toFixed(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span
                              class="__label [ d-flex align-items-center gap-2 ]"
                              >Tax
                              <span class="custom-tooltip aligned--bottom">
                                <i
                                  class="fa-solid fa-info-circle custom-tooltip__symbol"
                                ></i>
                                <span class="custom-tooltip__content">
                                  The prices above are
                                  {{
                                    inclusive_of_tax == 1
                                      ? "Inclusive"
                                      : "Exclusive"
                                  }}
                                  of {{ vat }} Value added tax;
                                  {{
                                    getRegistrationData?.contact?.currency?.name
                                  }}
                                  {{ percentage_amount }}
                                </span>
                              </span>
                            </span>
                          </td>
                          <td>
                            {{ getRegistrationData?.contact?.currency?.name }}
                            {{ percentage_amount }}
                          </td>
                        </tr>

                        <tr>
                          <td>Subtotal</td>
                          <td>
                            {{ getRegistrationData?.contact?.currency?.name }}
                            {{ sub_total }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" height="15"></td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total</td>
                          <td id="total_price">
                            {{ getRegistrationData?.contact?.currency?.name }}
                            {{ total_price }}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          </div>
        </div>
      </div>

      <br />
      <div class="container-fluid buttons-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-9">
              <div class="btnns">
                <ul>
                  <li>
                    <button
                      @click="returnMain()"
                      class="btn btn-primary btn-lg"
                    >
                      Return to Main
                    </button>
                  </li>
                  <li>
                    <button
                      @click="downloadAsPDF"
                      class="btn btn-primary btn-lg"
                      >Download Receipt</button
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ShareModal from "../components/ShareModal.vue";
import FeedBack from "../components/FeedBack.vue";
// import ShareModalPopup from "../components/ShareModalPopup.vue";
import format from "date-fns/format";
import gql from "graphql-tag";
import ConformationPreloader from "../components/ConformationPreLoader.vue";
import { trackPageView } from '@/utils/analytics'
// import html2pdf from "html2pdf.js";
export default {
  name: "RegistrationConfirmationView",
  components: {
    ShareModal,
    FeedBack,
    ConformationPreloader,
    // ShareModalPopup
  },
  data() {
    return {
      loading:true,
      time_zone:'Asia/Dubai',
      total_price: 0,
      percentage_amount: 0,
      discount: 0,
      inclusive_of_tax: "",
      social_media_images: "",
      modalShow: true,
      showSocialMediaModal: true,
      edition_name: "",
      sub_total: "",
      vat: "",
      created_at: "",
      exception_message: '',
      backend_base_url: process.env.VUE_APP_Base_url,
      feedback_required: true
    };
  },
  beforeCreate() {
    this.exception_message = ''
    this.$apollo
      .mutate({
        mutation: gql`
          mutation registration_confirmation(
            $id: Int
            $payment_method_id: String
            $type: String!
            $event: String!
          ) {
            registration_confirmation(
              id: $id
              payment_method_id: $payment_method_id
              type: $type
              event: $event
            )
          }
        `,
        variables: {
          id: Number(
            this.$route.params.registration_id ??
              this.$route.query.registration_id
          ),
          event: this.$route.params.event_id ?? this.$route.query.event,
          type: this.$route.params.type ?? this.$route.query.type,
        },
      })
      .then((res) => {
        const data = JSON.parse(res.data.registration_confirmation);
        this.feedback_required = data?.data?.feedback_required
        this.discount = data?.data?.contact?.r_discount_amount;
        this.edition_name = data?.data?.edition_data.name;
        this.vat = data?.data?.edition_data.vat;
        this.inclusive_of_tax = data?.data?.edition_data?.inclusive_of_tax;
        const percentage_tax = data?.data?.edition_data?.vat / 100;
        // item price
        if (data?.data?.contact?.promotion_code) {
          this.total_price = data?.data?.contact?.r_payable_amount;
          var rItemPrice = data?.data?.contact?.registration_items || [];
          rItemPrice.forEach((item) => {
            this.discount = this.discount + item.pivot.r_discount_amount;
            this.total_price+= item.pivot.r_payable_amount;
          });

          // option price
          var rOptionPrice = data?.data?.contact?.registration_options || [];
          rOptionPrice.forEach((option) => {
            this.discount = this.discount + (option.pivot.r_discount_amount*option.pivot.qunatity)
            this.total_price += (option.pivot.r_payable_amount*option.pivot.qunatity);
          });
          this.percentage_amount = this.total_price * percentage_tax.toFixed(2);
          this.percentage_amount = parseFloat(this.percentage_amount).toFixed(
            2
          );
          this.sub_total = this.total_price - this.percentage_amount;
          this.sub_total = parseFloat(this.sub_total).toFixed(2);
          this.total_price = parseFloat(this.total_price).toFixed(2);
        } else {
          var ItemPrice = data?.data?.contact?.registration_items || [];
          var totalItemPrice = ItemPrice.reduce(
            (sum, item) => sum + item.price,
            0
          );
          ItemPrice.forEach((item) => {
            this.discount = this.discount + item.pivot.r_discount_amount;
          });

          // option price
          var optionPrice = data?.data?.contact?.registration_options || [];
          this.total_price = totalItemPrice + data?.data?.contact?.r_net_amount;
          optionPrice.forEach((option) => {
            this.discount += (option.pivot.r_discount_amount * option.pivot.qunatity);
            this.total_price += (option.pivot.r_gross_amount * option.pivot.qunatity);
          });
          if (this.inclusive_of_tax) {
            this.percentage_amount = this.total_price * percentage_tax;
            this.percentage_amount = parseFloat(this.percentage_amount).toFixed(2);
            this.sub_total = this.total_price - this.percentage_amount;
            this.total_price = parseFloat(this.total_price).toFixed(2);
          } else {
            this.percentage_amount = this.total_price * percentage_tax;
            this.percentage_amount = parseFloat(this.percentage_amount).toFixed(2);
            this.sub_total = this.total_price;
            this.sub_total = parseFloat(this.sub_total).toFixed(2);
            this.total_price = parseFloat(this.total_price) + parseFloat(this.percentage_amount);
          }
        }
        this.$store.dispatch(
          "getRegistrationItems",
          data.data.contact.registration_items
        );
        this.$store.dispatch(
          "getCategoryData",
          data.data.contact.registration_category
        );
        this.$store.dispatch("getRegistrationResponse", data.data);
        setTimeout(() => {
        this.loading=false;
       }, 500);
      })
      .catch(() => {
        this.exception_message = 'Something went wrong.<br> Please contact ' + this.editionManagerName + ' on ' + this.editionManagerPhone + ' for assistance.'
        this.$scrollToTop()
      });
  },
  methods: {
    optionPrice(optionPriceFormat) {
      return optionPriceFormat.toFixed(2);
    },
    downloadAsPDF() {
      print()
    },
  
    hideFeedbackArea() {
      this.showSocialMediaModal = false;
    },

    formatDate (originalDate) {
      if (!originalDate) {
        return ''
      }
      try {
        const date = new Date(originalDate)
        if (isNaN(date.getTime())) {
          return 'Invalid Date'
        }
        const options = {
          timeZone: this.time_zone
        }
        const new_date = date.toLocaleString(undefined, options)
        return format(new_date, 'MM-dd-yyyy HH:mm')
      } catch (error) {
        return ''
      }
    },
    returnMain() {
      let event = this.$route.query.event ?? this.$route.params.event_id;
      let type = this.$route.query.type ?? this.$route.params.type;
      window.location.href = "/" + event + "/" + type;
    },
    scrollToTop() {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Optional: Adds a smooth scrolling effect
      });
    },
    trackPurchase (total_price, transaction_id) {
      try {
        this.$gtag.purchase({
          value: total_price,
          transaction_id: transaction_id
        })
      } catch (e) {
        console.error('google analytics is not configured')
      }
    }
  },
  computed: {
    getRegistrationData() {
      return this.$store.getters.getRegistrationResponse;
    },
    getPricingDetails() {
      if (
        Object.keys(this.$store.getters.getCategoryItemsOptionsPricingData)
          .length !== 0
      ) {
        return this.$store.getters.getCategoryItemsOptionsPricingData.items;
      } else {
        return this.$store.getters.getRegistrationItems;
      }
    },
    getCategoryData() {
      return this.$store.getters.getCategory;
    },
    getRegistrationItems() {
      return this.$store.getters.getRegistrationItems;
    },
    getConfirmationMessage() {
      return this.$store.getters.getEditionData?.url_details;
    },
    editionManagerName() {
      return this.$store.state.edition_details?.edition_data?.edition_manager_name ?? '';
    },
    editionManagerPhone() {
      return this.$store.state.edition_details?.edition_data?.edition_manager_phone ?? '';
    }
  },
  
  mounted() {
    trackPageView(this.$gtag, this.$route,"Step 5 - Registration Confirmation Screen");
    setTimeout(() => {
      this.time_zone=this.$store?.state?.edition_details?.edition_data?.time_zone;
      this.scrollToTop();
      if (
        this.$store?.state?.edition_details?.url_details?.social_media_images
      ) {
        this.social_media_images =
          this.$store?.state?.edition_details?.url_details?.social_media_images.split(
            ","
          );
        if (this.social_media_images.length > 0) {
          this.showSocialMediaModal = true;
        }
      }

      this.$nextTick(() => {
        if (this.total_price != undefined && this.getRegistrationData?.contact?.registration_id != undefined) {
          const total_price = this.total_price
          const order_id = this.getRegistrationData?.contact?.registration_id
          this.trackPurchase(total_price,order_id)
        }
      })
    }, 1000);
  },
};
</script>

<style>
  @media print {
      .header,
      .thankyou-msg,
      .modalsettings,
      .buttons-section,
      .footer {
        display: none !important;
      }
      .downloadable-area {
        display: block;
      }
      .table-footer thead tr th {
      background: rgba(var(--bs-primary),1) !important;
      -webkit-background: rgba(var(--bs-primary),1) !important;
        opacity: 1;
      }
    }
</style>

<style scoped>
.registration-conformation-form-new {
  background-color: #fff;
  max-width: 1296px;
}

.registration-conformation-form-new .conformation-container {
  background-color: #fff;
  padding: 15px;
}

.table-head *,
.table-body *,
.table-total * {
  border: 0;
}

/* .table-head {
  border-top: solid 4px rgba(var(--bs-primary), 1);
} */

.table-head {
}

.registration-conformation-form-new .conformation-container table tbody {
}

.registration-conformation-form-new .conformation-container table tbody tr {
}

.registration-conformation-form-new
  .conformation-container
  table
  tbody
  tr
  td
  .heading
  h3 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: #1b1b1b;
}

.registration-conformation-form-new
  .conformation-container
  table
  tbody
  tr
  td
  .heading
  p {
  margin: 0;
  font-size: 14px;
  color: #1b1b1b;
}

.registration-conformation-form-new
  .conformation-container
  table
  tbody
  tr
  td
  span {
}

.registration-conformation-form-new .conformation-container table tbody tr td {
}

.barcode {
  text-align: center;
  display: inline-block;
}

.table-body {
  border-top: solid 1px rgba(var(--bs-primary), 1);
}

.table-body tbody tr td {
}

.table-body tbody tr td:first-child {
  width: 65%;
}

.table-body tbody tr td:last-child {
  width: 35%;
}

.table-body tbody tr td {
  font-size: 14px;
}

.table-body tbody tr td label {
  font-weight: 600;
  display: block;
  color: #1b1b1b;
  margin: 0;
}

.table-body tbody tr td span {
  color: #545454;
  max-width: 360px;
  display: block;
}

.table-footer thead {
}

.table-footer thead tr th {
  background-color: rgba(var(--bs-primary), 1);
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 0;
}

.table-footer thead tr th:first-child,
.table-footer tbody tr td:first-child {
  width: 5%;
}

.table-footer thead tr th:nth-child(2),
.table-footer tbody tr th:nth-child(2) {
  width: 75%;
}

.table-footer thead tr th:nth-child(3),
.table-footer tbody tr td:nth-child(3) {
  width: 5%;
  text-align: center;
}

.table-footer thead tr th:last-child,
.table-footer tbody tr td:last-child {
  width: 15%;
  text-align: right;
}

.table-footer tfoot tr:last-child td {
  border-bottom: 0;
}

.table-footer tbody tr td {
  font-size: 14px;
  color: #1b1b1b;
}

.table-footer > tbody > tr:last-child td {
  border-bottom: solid 3px rgba(var(--bs-primary), 1);
}

.table-total {
  width: 260px;
}

.table-total tbody tr td {
  padding-top: 0;
  padding-bottom: 0;
}

.table-total tbody tr td:last-child {
  text-align: right;
}

.table-total tfoot tr td {
  font-weight: bold;
  border-top: solid 1px rgba(var(--bs-primary), 1);
}

.table-total tfoot tr td:last-child {
  text-align: right;
}

.buttons-section {
  border-top: solid 1px #ccc;
}

.btnns {
}

.btnns ul {
  margin: 15px 0;
  padding: 0;
  text-align: right;
}

.btnns ul li {
  list-style: none;
  margin-left: 20px;
  display: inline-block;
  margin-block: 3px;
}

.btnns ul li a {
  padding: 15px 30px;
  display: block;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  text-decoration: none;
}

.btnns ul li:first-child a {
  background-color: #e6e6e6;
  color: #1b1b1b;
}

.btnns ul li:last-child a {
  background-color: rgba(var(--bs-primary),0.9);
  color: #fff;
}

.share-modal-body {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(27, 27, 27, 0.47);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 30px;
  z-index: 99;
  /* overflow-y: scroll; */
}

.share-modal-body .cross-btn {
  display: block;
}

.buttons-section {
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .share-modal-body {
    padding-block: 30px;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 590px) {
  .table-head tbody tr td {
    width: 100%;
    display: block;
    text-align: center;
  }
  .table-body tbody tr td {
    width: 100% !important;
    display: block;
  }
}
.table > :not(caption) > * > * {
  padding-block: 5px;
}

.thankyou-msg {
  margin-top: 30px;
}
.thankyou-msg .content {
  text-align: center;
}
.thankyou-msg .content svg {
  max-height: 126px;
}

.thankyou-msg .content svg .fill-color {
  fill: rgba(var(--bs-primary),1)
}
.thankyou-msg .content svg .stroke-color {
  stroke: rgba(var(--bs-primary),1)
}
.thankyou-msg .content h4 {
  font-size: 28px;
  font-weight: 600;
  /* color: rgba(var(--bs-primary), 1); */
  color: #5cb85c;
  margin: 24px 0 10px 0;
}
.thankyou-msg .content p {
  font-size: 14px;
  line-height: 22px;
  color: #1B1B1B;
}

@media only screen and (max-width: 767px) {
  .thankyou-msg .content svg {
    max-height: 100px;
  }
  .thankyou-msg .content h4 {
    font-size: 22px;
  }
}

</style>
