import { render, staticRenderFns } from "./RegistrationCancel.vue?vue&type=template&id=71e5093d&scoped=true"
import script from "./RegistrationCancel.vue?vue&type=script&lang=js"
export * from "./RegistrationCancel.vue?vue&type=script&lang=js"
import style0 from "./RegistrationCancel.vue?vue&type=style&index=0&id=71e5093d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e5093d",
  null
  
)

export default component.exports