// Import necessary libraries
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { Loading } from 'element-ui'; // Assuming you are using Element UI for the loader

// HTTP connection to the API
const httpLink = createUploadLink({
  uri: process.env.VUE_APP_END_POINT,
});

// Cache implementation
const cache = new InMemoryCache();

// Create loading instance
const loadingInstance = Loading.service({
  fullscreen: true,
  lock: true,
  text: 'Loading...',
  spinner: 'el-icon-loading',
  background: 'rgba(0, 0, 0, 0.7)',
});

// Middleware to show/hide loader
const loaderMiddleware = new ApolloLink((operation, forward) => {
  loadingInstance.visible = true; // Show loader before request

  return forward(operation).map((response) => {
    loadingInstance.visible = false; // Hide loader after response
    return response;
  });
});

// Create the Apollo client with the middleware
const apolloClient = new ApolloClient({
  link: ApolloLink.from([loaderMiddleware, httpLink]),
  cache,
});

export default apolloClient;
