<template>
  <div class="container-fluid adds-component" v-if="sliders.length > 0">
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <div class="add-slider" >
            <VueSlickCarousel v-bind="settings">
              <div class="slide-item" v-for="(ads, index ) in sliders" :key="index">
                <a :href="ads?.external_link ? ads?.external_link : 'javascript:;'" target="_blank"
                  :title="ads?.title"><img :src="image_base_url + ads?.banner"
                    :alt="ads?.description ? ads?.description : ''" /></a>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import gql from "graphql-tag";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "NewDesign",
  props: {
    registration_tab: [String, Number]
  },
  components: { VueSlickCarousel },
  data() {
    return {
      sliders: {},
      image_base_url: process.env.VUE_APP_Image_url,
      settings: {
        dots: false,
        arrows: false,
        draggable: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    };
  },
  methods: {
    getAds() {
      const variables = {
        event: this.$route.params.event_id ? this.$route.params.event_id : this.$route.query.event_id,
        type: this.$route.params.type ? this.$route.params.type : this.$route.query.type,
      };
      this.$apollo.query({
        query: gql`
                    query ads_management($event: String! $type: String!){
                      ads_management(event: $event type: $type){
                        id,
                        title  
                        banner,
                        registration_tab
                        description
                        external_link
                        }
                    }`,
        variables,
      }).then((data) => {
        if (data?.data?.ads_management) {
          this.sliders = data.data.ads_management.filter(element =>
            element.registration_tab.includes(this.registration_tab === 0
              ? 'Attendee Information Form'
              : this.registration_tab === 1
                ? 'Category Selection'
                : this.registration_tab == 2 ? 'Payment Gateway / Promotion Code' : this.registration_tab)
          );
        }
      }).catch((error) => {
        console.error(error)
      });
    },
  },
  created() {
    this.getAds();

  },
};
</script>
  
<style lang="scss"></style>