<template>
  <div class="payment-summary-container">
    <!-- <small class="text-uppercase d-block mb-2" style="letter-spacing: 0.15rem; font-weight: 500;">Payment Summary</small> -->
    <div class="sub-vat-detail">
      <ul class="short-info d-none"> <!-- hidden for now -->
        <li>
          <span class="__label">Sub Total</span>
          <span class="__value fw-bold">AED 660.00</span>
        </li>
        <li>
          <span class="__label [ d-flex align-items-center ]">
            VAT
            <span class="custom-tooltip aligned--right mx-2">
              <i class="fa-solid fa-info-circle custom-tooltip__symbol"></i>
              <span class="custom-tooltip__content">
                <!-- The prices above are inclusive of 5.00% Value added tax; AED 33.00 -->
              </span>
            </span>
          </span>
          <span class="__value fw-bold">AED 33.00</span>
        </li>
      </ul>
      <div class="total-payable">
        <p class="mb-1">Total Payable:</p>
        <h3 class="[ d-flex gap-2 align-items-center ]">
          {{ this.$store.getters.getCurrency }} {{ this.$store.getters.getItemAndOptionPrice }}
          <i class="fa-solid fa-info-circle fs-5" v-b-modal.payable-table-modal></i>
        </h3>
      </div>
    </div>
    <b-modal id="payable-table-modal" title="Payment Summary" hide-footer size="md">
      <div class="payment-card">
        <div class="payment-card__body pt-0">
          <PaymentDetailsTable
              ref="payment_details_table"
            :tableData="payment_breakdowns" 
            :shortSummary="short_summary"
            :currency="this.$store.getters.getCurrency"
            :category_items_options_pricing="category_pricing"
          />
        </div>
        <div class="payment-card__footer">
          <h5 class="fw-normal text-right mb-0">
            Total Payable:
            <strong class="text-primary">{{ this.$store.getters.getCurrency }} {{ this.$store.getters.getItemAndOptionPrice }}</strong>
          </h5>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import PaymentDetailsTable from './PaymentDetailsTable.vue';

export default {
  name: "FooterPaymentSummary",
  props:["category_data","total_price","get_item","get_option","get_category_items_options_pricing"],
  data() {
    return {

      payableAmountTableData: [
        // { "#": 1, items: "Dental Hygienist Early Bird", price: "AED 693.00" },
        // { "#": 2, items: "Conference", price: "AED 0.00" },
        // { "#": 3, items: "Exhibition", price: "AED 0.00" },
      ],
      payment_breakdowns: {
        head: [
          { id: '#', item: 'Items', price: 'price' },
        ],
        body: [
          { id: 'xyz121', item: 'Dental Hygienist Early Bird', amount: { price: 693.00, currency: 'AED' } },
          { id: 'xyz122', item: 'Conference', amount: { price: 10.50, currency: 'AED' } },
          { id: 'xyz123', item: 'Exhibition', amount: { price: 0.00, currency: 'AED' } },
        ],
        item_head:{ id: '#', item: 'Item', price: 'price' },
        option_head:{ id: '#', item: 'Option', price: 'price' },
        items:[],
        options:[]
      },
      
      short_summary: {
        sub_total: { 
          label: 'Sub Total', 
          currency: 'AED', 
          amount: 0.00 
        },
        vat: { 
          label: "VAT", 
          currency: 'AED', 
          amount: 0.00, 
          tooltip: true,
          tooltip_message: 'The prices above are inclusive of 0.00% Value added tax; AED 0.00',
        },
      },
      totol_payable: 0,
      currency: "AED",
      category_pricing: null
    };
  },
  components: { PaymentDetailsTable },
  watch:{
    category_data(update_value){
      const percentage_tax = (this.$store.getters.getVat / 100);
      this.currency = update_value.currency.name;
      this.totol_payable = update_value.price;
      const percentage_amount = this.$store.getters.getItemAndOptionPrice * percentage_tax;
      this.short_summary.sub_total.currency = update_value.currency.name;
      this.short_summary.sub_total.amount = this.$store.getters.getItemAndOptionPrice - parseFloat(percentage_amount.toFixed(2));
      this.short_summary.vat.currency = update_value.currency.name;
      this.short_summary.vat.amount = percentage_amount.toFixed(2);
      this.short_summary.vat.tooltip_message = `The prices above are inclusive of ${this.$store.getters.getVat}% Value added tax; ${this.currency} ${percentage_amount.toFixed(2)}`;

    },
    total_price(update_value){
      this.totol_payable = update_value;
    },
    get_item(items){
      this.payment_breakdowns.items = [];
      this.payment_breakdowns.items = items.filter( item => item.check && item.check === true );
    },
    get_option(option){
      this.payment_breakdowns.options.push(option);
    },
    get_category_items_options_pricing(data){
      console.log(data);
    }
  },
  methods:{
    setItems(items){
      this.payment_breakdowns.items = items.filter( item => item.check && item.check === true );
    },
    setOptions(){
      this.payment_breakdowns.options = this.payment_breakdowns.options.filter( item => item.check && item.check === true );
      // console.log(this.payment_breakdowns.options)
    },
    get_category_pricing(pricing){
      // console.log("category pricing:",pricing)
      this.category_pricing = pricing;
      this.$refs.payment_details_table.get_category_pricing(pricing);
    }
  },
}
</script>

<style lang="scss">
  .payment-summary-container {
    position: relative;
    .sub-vat-detail {
      position: relative;
      display: flex;
      align-items: center;
      ul {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-left: calc(1rem + 0.5vw);
        padding-left: calc(1rem + 0.5vw);
        border-left: 1px solid var(--bs-gray-700);
        li {
          display: flex;
          gap: .25rem;
          .__label {
            display: inline-block;
            min-width: 80px;
          }
          .__value {
            &::before { content: ':'; display: inline-block; margin-right: 0.5rem;}
          }
        }
      }
      .total-payable { display: flex; gap: 1rem; align-items: center; }
    }
  }
</style>