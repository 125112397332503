<template>
  <b-tab title="Confirmation" id="tab3">
    <div class="form-tab-body-content payment-tab-body-content pb-5">
      <div class="row">
        <div class="col-12 col-xl-10 mx-auto">
          <div class="row">
            <div class="col-12 col-lg-5 col-xl-6 [ d-flex align-items-center justify-content-center ]">
              <PaymentIllustration class="hidden-md" />
            </div>
            <div class="col-12 col-lg-7 col-xl-6">
              <div class="payment-card">
                <div class="payment-card__header">
                 
                  <PromoCode :key="dynamickey" :promoCode="getPromoCodeField" @getPromoCode="promo_code = $event"
                    :request_data="getCategoryOptionsRequestData" />
                </div>
                <div class="payment-card__body">
                  <h5 class="text-dark text-uppercase">Payment Summary </h5>
                  <div class="payment-details-table header-borderless footer-borderless row-gap--2">
                    <div class="payment-details-table__header" v-if="getPricingDetails?.items?.length > 0">
                      <div class="payment-details-table__row" v-for="(tHead, index) in tableData.head" :key="index">
                        <div class="payment-details-table__cell indexing">{{ tHead.id }}</div>
                        <div class="payment-details-table__cell">{{ tHead.item }}</div>
                        <div class="payment-details-table__cell pricing fw-bold">Price</div>
                      </div>
                    </div>
                    <div class="payment-details-table__body">
                      <div class="payment-details-table__body">
                        <div class="payment-details-table__row">
                          <div class="payment-details-table__cell indexing">1</div>
                          <div class="payment-details-table__cell">{{ getPricingDetails?.registration_category?.name }}
                          </div>
                          <div class="payment-details-table__cell pricing fw-bold">{{ currency }} {{
                            getPricingDetails?.registration_category?.price }}</div>
                        </div>
                      </div>
                      <div class="payment-details-table__row" v-for="(item, itemIndex) in getPricingDetails?.items"
                        :key="itemIndex">
                        <div class="payment-details-table__cell indexing">{{ ++itemIndex+1 }}</div>
                        <div class="payment-details-table__cell">{{ item.name }}</div>
                        <div class="payment-details-table__cell pricing fw-bold">{{ currency }} {{ item.price }}</div>
                      </div>
                    </div>
                    <div class="payment-details-table__header" v-if="getPricingDetails?.options?.length > 0">
                    </div>
                    <div class="payment-details-table__body">
                      <div class="payment-details-table__row" v-for="(option, itemIndex) in getPricingDetails?.options"
                        :key="itemIndex">
                        <div class="payment-details-table__cell indexing">{{ ++itemIndex+getPricingDetails?.items?.length+1 }}</div>
                        <div class="payment-details-table__cell">{{ option.name }}</div>
                        <div class="payment-details-table__cell pricing fw-bold">{{ currency }} {{ option.price }} X {{
                          option.quantity }}</div>
                      </div>
                    </div>
                    <div class="payment-details-table__footer">
                      <div class="payment-details-table__row">
                        <div class="payment-details-table__cell">
                          <ul class="short-info">
                            <li>
                              <span class="__label [ d-flex align-items-center gap-2 ]">Discount</span>
                              <span class="__value fw-bold">{{ currency }}
                                {{ this.$store.getters.getDiscount }}</span>
                            </li>
                            <li>
                              <span class="__label [ d-flex align-items-center gap-2 ]">
                                VAT
                                <span class="custom-tooltip aligned--bottom">
                                  <i class="fa-solid fa-info-circle custom-tooltip__symbol"></i>
                                  <span class="custom-tooltip__content"
                                    v-if="getPricingDetails?.promotion && Object.keys(getPricingDetails?.promotion).length">
                                    {{ this.$store.getters.getTooltipMessage }}
                                    <!-- The prices above are inclusive of {{ getPricingDetails?.category_vat }}% Value added tax; {{getPricingDetails?.currency}} {{ getPricingDetails?.promotion?.vat_amount.toFixed(2)  }} -->
                                  </span>
                                  <span class="custom-tooltip__content" v-else>
                                    {{ this.$store.getters.getTooltipMessage }}
                                    <!-- The prices above are inclusive of {{ getPricingDetails?.category_vat }}% Value added tax; {{getPricingDetails?.currency}} {{  getPricingDetails?.amount?.vat_amount.toFixed(2) }} -->
                                  </span>
                                </span>
                              </span>

                              <!-- <span class="__value fw-bold" v-if="getPricingDetails?.promotion && Object.keys(getPricingDetails?.promotion).length" >{{ getPricingDetails?.currency }} {{ getPricingDetails?.promotion?.vat_amount.toFixed(2) }}</span> -->
                              <span class="__value fw-bold">{{ currency }}
                                {{ this.$store.getters.getVatAmount }} </span>
                            </li>
                            <li>
                              <span class="__label [ d-flex align-items-center gap-2 ]">
                                Sub Total
                              </span>
                              <span class="__value fw-bold">{{ currency }}
                                {{ this.$store.getters.getSubTotal }} </span>
                              
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
                <div class="payment-card__footer">
                  <h5 class="fw-normal text-right mb-0">
                    Total Payable:
                    <strong class="text-primary"
                      v-if="getPricingDetails?.promotion && Object.keys(getPricingDetails?.promotion).length">{{
                        getPricingDetails?.currency }} {{ getPricingDetails?.promotion?.payable_amount.toFixed(2)
  }}</strong>
                    <strong class="text-primary" v-else>{{ this.$store.getters.getCurrency }} {{
                      getItemAndOptionPrice }}</strong>
                    <!-- {{ getPricingDetails?.amount?.total_price.toFixed(2) }}</strong> -->
                  </h5>
                </div>
              </div>
              <div class="" v-if="this.$store.getters.getItemAndOptionPrice != 0">
                <div class="trn-number mt-5" v-if="this.$store.getters.getCategory?.show_trn">
                  <div class="line-heading">
                    <h4>TAX REGISTRATION NUMBER</h4>
                  </div>
                </div>

                <div class="input-block" v-if="this.$store.getters.getCategory?.show_trn">
                  <label for="" class="mb-1">TRN No</label>
                  <input type="text" placeholder="" v-model="trn_number" class="form-control">
                </div>

                <div class="payment-methods mt-4">
                  <div class="line-heading">
                    <h4>PAYMENT METHOD *</h4>
                    <p>Select your payment method, then click proceed to pay</p>
                  </div>
                  <ul>
                    <li v-for="(method, index) in getPaymentMethods" :key="index">
                      <label :for="'payment'+index" :class="payment_method == method.id && 'active'" @click="selectPaymentMethod(method.id)">
                        <input name="radio" :id="'payment'+index" type="radio" v-model="payment_method" :value="method.id" />
                        {{ method.label }}
                        <img v-if="method?.logo" alt="" :src="method?.logo" />
                      </label>
                    </li>
                    <li v-if="payment_method_error" class="alert alert-danger">{{ payment_method_error }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div> 
    <div class="form-tab-footer">
      <div></div>
      <b-button-group>
        <b-button size="lg" variant="white" @click="decrementTab(tabIndexing)">
          <i class="fa-solid fa-angle-left"></i>
          Categories Details
        </b-button>
        <b-button variant="primary" v-if="!disabled && (payment_method !== null && getItemAndOptionPrice != 0)"
          class="" size="lg" @click="tabIndexingHandler()">
          Proceed to Pay
          <i class="fa-solid fa-angle-right"></i>
        </b-button>
        <b-button variant="primary" v-if="!disabled && getItemAndOptionPrice == 0"
          class="" size="lg" @click="tabIndexingHandler()">
          Complete Registration
          <i class="fa-solid fa-angle-right"></i>
        </b-button>
        <b-button v-if="disabled" variant="primary">
          <i class="fa fa-refresh fa-spin"></i>Processing
        </b-button>
      </b-button-group>
    </div>
    <PartnersAndSoponsersLogos />
  </b-tab>
</template>

<script>
import PaymentIllustration from '../PaymentIllustration.vue';
import PartnersAndSoponsersLogos from '../PartnersAndSoponsersLogos.vue';
// import PaymentDetailsTable from './PaymentDetailsTable.vue';
import PromoCode from './PromoCode.vue';
import gql from "graphql-tag";
import Bowser from "bowser";
export default {
  beforeCreate() {
    setTimeout(() => {
      this.currency = this.$store.getters.getCurrency ? this.$store.getters.getCurrency : ''
    }, 2000);


    // This is where you can perform logic before the instance is created
  },
  name: "PaymentConfirmation",
  props: ["category_pricing_details"],
  data() {
    return {
      payment_method_error:'',
      trn_number:'',
      disabled:false,
      dynamickey:0,
      sub_total: 0,
      vat: 0,
      vat_amount: 0,
      tooltip_message: '',
      currency: 'AED',
      payment_method: null,
      tableData: {
        head: [
          { id: '#', item: 'Items', price: 'Price' },
        ],
        option_head: { id: '#', item: 'Options', price: 'Price' },
      },
      tabIndexing: this.tabIndex,
      payment_breakdowns: {
        head: [
          { id: '#', item: 'Items', price: 'price' },
        ],
        body: [
          { id: 'xyz121', item: 'Dental Hygienist Early Bird', amount: { price: 693.00, currency: 'AED' } },
          { id: 'xyz122', item: 'Conference', amount: { price: 10.50, currency: 'AED' } },
          { id: 'xyz123', item: 'Exhibition', amount: { price: 0.00, currency: 'AED' } },
        ]
      },

      shortSummary: {
        promo_code: {
          label: 'Discount',
          currency: 'AED',
          amount: 30.00,
          tooltip: true,
          tooltip_message: 'Applied Promo Code: #xyz123',
        },
        sub_total: {
          label: 'Sub Total',
          currency: 'AED',
          amount: 660.00
        },
        vat: {
          label: "VAT",
          currency: 'AED',
          amount: 30.00,
          tooltip: true,
          tooltip_message: 'The prices above are inclusive of 5.00% Value added tax; AED 33.00',
        },
      },
      totol_payable: 'AED 793.00',
      promo_code: null,
    }
  },
  methods: {
    selectPaymentMethod(payment_method_id){
      let paymentMethods=this.$store.getters.getPaymentMethods;
        let method=paymentMethods.find(method => method.id === payment_method_id);
        if(method.label=='Mada' || method.label=='Visa / Master Card'){
            if(!this.getPricingDetails?.registration_data?.first_name){
               this.payment_method_error='First Name Filed Is Required In Registration';
               this.disabled=true
               return
            }
               else if(!this.getPricingDetails?.registration_data?.last_name){
                this.payment_method_error='Last Name Filed Is Required In Registration';
                this.disabled=true
                return
              }
              else if(!this.getPricingDetails?.registration_data?.phone){
                this.payment_method_error='Phone Number Filed Is Required In Registration';
                this.disabled=true
                return
              }
              else if(!this.getPricingDetails?.registration_data?.city_id){
                this.payment_method_error='City Filed Is Required In Registration';
                this.disabled=true
                return
              }else if(!this.getPricingDetails?.registration_data?.state_id){
                this.payment_method_error='State Filed Is Required In Registration';
                this.disabled=true
                return
              }else if(!this.getPricingDetails?.registration_data?.country_id){
                this.payment_method_error='Country Filed Is Required In Registration';
                this.disabled=true
                return
              }
              else if(!this.getPricingDetails?.registration_data?.p_o_box){
                this.payment_method_error='P O Box Filed Is Required In Registration';
                this.disabled=true
                return
              }
              else if(!this.getPricingDetails?.registration_data?.address){
                this.payment_method_error='Address Filed Is Required In Registration';
                this.disabled=true
                return
              }

              
        }
        this.payment_method_error='';
        this.disabled=false
    },
    decrementTab(index) {
      this.payment_method_error='';
      this.disabled=false
      this.payment_method=null;
      this.trn_number='';
      this.dynamickey++;
      this.$scrollToTop();
      return this.$emit("getIndex", (this.tabIndexing = index - 1));
    },
    tabIndexingHandler: function () {

      let btn2 = document.getElementById('tab2___BV_tab_button__');

      btn2.scrollIntoView({
        
      });
  
     this.disabled=true;
      let request_data = {};
      request_data['id'] = Number(this.getPricingDetails?.registration_data?.registration_id);
      request_data['payment_method_id'] = this.payment_method ? this.payment_method.toString() : null;
      request_data['type'] = this.$route.params.type ?? this.$route.query.type;
      request_data['event'] = this.$route.params.event_id ?? this.$route.query.event;
      request_data['price'] = this.$store.getters.getItemAndOptionPrice;
      request_data['trn_number']=this.trn_number;
      request_data['browser_detail'] = this.getBrowserDetails()
      let get_payment_method = this.getPaymentMethods.filter((item) => item.id === this.payment_method)[0]
      if (get_payment_method?.type === 0 || this.payment_method == null) {
        this.$apollo
          .mutate({
            mutation: gql`
                  mutation payment_confirmation(
                      $id: Int,
                      $payment_method_id: String,
                      $type: String!,
                      $event: String!
                      $trn_number: String
                      $price: Float
                      $browser_detail: BrowserDetail
                  ){
                  payment_confirmation(
                          id: $id,
                          payment_method_id: $payment_method_id,
                          type: $type,
                          event: $event,
                          price: $price,
                          trn_number:$trn_number,
                          browser_detail:$browser_detail
                      )
                  }
              `,
            variables: request_data
          })
          .then((res) => {
            this.disabled=false;
            const data = JSON.parse(res.data.payment_confirmation);
            if(data.data.dtcm_error){
              this.$router.push({
                name: 'registration-cancel',
                params: {
                  event_id: request_data.event,
                  type: request_data.type,
                  registration_id: request_data.id
                },
                query: {
                dtcm: true},
              })
            }
            else if (data.code === 200 || data.status===200) {
              this.$store.dispatch('getRegistrationResponse', data.data);
              this.$router.push({
                name: 'registration-confirmation',
                params: {
                  event_id: request_data.event,
                  type: request_data.type,
                  registration_id: request_data.id
                },
                // query: {type: request_data.type}
              })
            }
          })
          .catch((error) => {
            this.disabled=false;
            console.log(error);
          })
      } else {
        this.disabled=false;
        let trn_number = this.trn_number ? "&trn_number="+this.trn_number : '';
        let edition_logo=process.env.VUE_APP_Image_url + this.$store.state.edition_details?.edition_data?.edition_logo;
        const registration_id = this.getCategoryOptionsRequestData.encrypted_registration_id;
        window.location.href = process.env.VUE_APP_Base_url + '/payment-processor?registration_id=' + registration_id + '&payment_configuration_id=' + request_data.payment_method_id + '&type=' + request_data.type + '&event=' + request_data.event+trn_number+'&edition_logo='+edition_logo;
      }

    },
    getBrowserDetails () {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return {
        'browser': browser.getBrowserName(),
        'browser_version': browser.getBrowserVersion(),
        'os': browser.getOSName(),
        'os_version': browser.getOSVersion(),
        'platform_type': browser.getPlatformType(),
      }
    }
  },
  watch: {
    getRegistrationOrContact() {
      if(this.$store.getters.getPaymentMethods && this.$store.getters.getItemAndOptionPrice){
         let payment_method=this.$store.getters.getPaymentMethods;
         this.payment_method=payment_method[0]?.id;
         this.selectPaymentMethod(this.payment_method);
        }  
      if(this.getRegistrationOrContact?.trn_number){
        this.trn_number = this.getRegistrationOrContact?.trn_number;
      }
      
    }
  },
  computed: {
    getRegistrationOrContact() {
      return this.$store.getters.getRegistrationOrContact;
    },
    getPricingDetails() {
      return this.$store.getters.getCategoryItemsOptionsPricingData;
    },
    getPaymentMethods() {
      return this.$store.getters.getPaymentMethods.map(method => {
      const config = JSON.parse(method.payment_method_configuration || '{}');
      if (config.logo) {
        return { ...method, logo: config.logo };
      }
      return method;
    });
    },
    getCategoryOptionsRequestData() {
      return this.$store.getters.getCategoryOptionsRequestData;
    },
    getItemAndOptionPrice(){
     return this.$store.getters.getItemAndOptionPrice;
    },
    getPromoCodeField() {
      return this.$store.getters.getPromocode;
    },
  },
  // components: { PaymentIllustration, PaymentDetailsTable, PromoCode }
  components: { PaymentIllustration, PromoCode, PartnersAndSoponsersLogos }
}
</script>

<style lang="scss">
.payment-card {
  --card-padding-x: 1rem;
  --card-padding-y: 1rem;
  --card-border-radius: 0.35rem;
  --card-border-color: rgba(var(--bs-primary), 1);
  --card-bg-color: var(--bs-white);
  --card-color: var(--bs-dark);
  --card-footer-bg-color: rgba(var(--bs-secondary), 0.1);

  @media (max-width: 767.85px) {
    --card-padding-x: 0.75rem;
    --card-padding-y: 0.75rem;
  }

  position: relative;
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);
  border-radius: var(--card-border-radius);

  &__header {
    position: relative;
    padding-inline: var(--card-padding-x);
    padding-block-start: var(--card-padding-y);

  }

  &__body {
    padding-inline: var(--card-padding-x);
    padding-block: var(--card-padding-y);
  }

  &__footer {
    padding-inline: var(--card-padding-x);
    padding-block: var(--card-padding-y);
    background-color: rgba(var(--bs-primary),0.15);
  }
}

.payment-tab-body-content {
  @media (max-width: 767.85px) {
    .col-12 {
      padding-inline: 0;
    }
  }
}

.payment-methods {
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: block;
      margin: 3px 0;
      position: relative;
      label {
        color: #1B1B1B;
        font-size: 13px;
        line-height: 18px;
        padding: 16px 15px;
        display: block;
        border: 1px solid #DDDDDD;
        border-radius: 6px;
        font-weight: 600;
        transition: all 0.5s;

        &.active,
        &:hover {
          color: rgba(var(--bs-primary),1);
          border-color: rgba(var(--bs-primary),1);
        }
        input {
          margin-right: 10px;
          appearance: none;
          position: relative;
          top: 3px;
          --btnheight: 13px;

          &:after {
            width: var(--btnheight);
            height: var(--btnheight);
            border-radius: 15px;
            top: -2px;
            left: -1px;
            position: relative;
            background-color: #fff;
            content: '';
            display: inline-block;
            visibility: visible;
            border: 1px solid #707070;
            box-shadow: inset 0 0 0 2px white;
          }

          &:checked:after {
              width: var(--btnheight);
              height: var(--btnheight);
              border-radius: 15px;
              top: -2px;
              left: -1px;
              position: relative;
              background-color: rgba(var(--bs-primary),1);
              content: '';
              display: inline-block;
              visibility: visible;
              border-color: rgba(var(--bs-primary),1);
          }
          &:checked {
            color: red;
          }
        }
       img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        height: 25px;
        object-fit: contain;
       }
      }
    }
  }
}
.line-heading {
  position: relative;
  z-index: 1;
  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
    position: absolute;
    top: 11px;
    right: 0;
    z-index: -1;
  }
  h4 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    background-color: #fff;
    display: inline-block;
    padding-right: 5px;
  }
  p {
    font-size: 14px;
    color: #676767;
  }
}
</style>