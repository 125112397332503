<template>
    <div class="container registration-conformation-form">
      <div class="row">
  
        <div class="col-xl-10 offset-xl-1">
          <div class="conformation-container">
            <div class="form-group m-form__group m-form__section m-form__section--first">
              <br>
              <div style="text-align: center;" class="registration-conformation-banner">
                <div class="alert alert-danger cancel-registration" v-if="message">
                 <b>"{{message}}"</b>
                </div>
                <div class="alert alert-danger cancel-registration" v-if="currency && !message">
                  The transaction request cannot be completed as the currency <b>"{{currency}}"</b> is not accepted by the merchant. The supported currency for this transaction is <b v-if="currency=='SAR'">"AED"</b> <b v-else>"SAR"</b>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </template>
  
  <script>

  
  import { trackPageView } from '@/utils/analytics'

  export default {
    name: "RegistrationFailed",
    data() {
    return {
      currency: this.$route.params.currency,
      message: this.$route.query.message
    };
  },
    mounted () {
      trackPageView(this.$gtag, this.$route,"Registration Failed");
    }
  }
  </script>
  
<style scoped>
  .cancel-registration {
    margin: 30px 0;
  }

</style>