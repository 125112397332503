<template>
  <div class="multi-input" ref="parentElementPosition" @click="(e) => getPos(e)">
    <div class="multi-input__input-box input-block">
     
      <input 
        type="text" 
        class="form-control"
        :class="{ 'is-invalid': error }"
        id="" 
        v-click-outside="hideDropdown"
        @focus="dropdownListToggle(true),  $emit('onFocus',  $event)"
        @keydown.esc="dropdownListToggle(false)"
        v-model="checkInput" 
        :required="required"
        placeholder="Search Area of Interests">
        <span class="d-block mt-2 fs-h6 text-danger" v-if="error">This field is required</span>
      <div 
        :class="{'aligned-top': dropwdown_alignment}"
        class="multi-input__selection-dropdown" 
        v-if="elementFocused" ref="dropdownElement">
        <!-- <div class="multi-input__selection-dropdown__search"></div> -->
        <ul class="multi-input__selection-dropdown__list">
          <li 
            class="multi-input__selection-dropdown__list__item" 
            :class="{'active': list.selected || selected_list?.find(item => item.product_category_id == list.product_category_id)}" 
            @click="itemSelection(list.product_category_id)"
            v-for="list in searchDataList" 
            :key="list.product_category_id">
            <i class="fa-regular fa-square"></i>
            {{ list.name }}
          </li>
        </ul>       
      </div>
    </div>
    <div class="multi-input__selected__pills" v-if="selected_list.length > 0">
      <div 
        @click="removeItems(selectedItem.product_category_id)"
        class="multi-input__selected__pills__item" 
        v-for="selectedItem of selected_list" 
        :key="selectedItem.product_category_id">
        <i class="fa-solid fa-square-check"></i>
        <span>{{ selectedItem.name }}</span>
        <!-- <span class="multi-input__selected__pills__item--close">&times;</span> -->
      </div>
    </div>
  </div>
</template>
<script>
import directive from '@/directives/click-outside.directive';

export default {
  name: "InputMultiSelect",
  directives:{ directive },
  components: {},
  props: {
    required: { type: Boolean }, 
    dropdownList: {},
    selected_item:[]
  },
  data() {
    return {
      error: false,
      inputValue: '',
      windowTop: null,
      windowHeight: window.innerHeight,
      dropwdown_alignment: false,
      area_of_interest_data: this.dropdownList,
      searchDataList: null,
      elementFocused: false,
      selected_list: [],
    }
  },
  mounted() {
    setTimeout(() => {
    if(this.selected_item!== null && this.selected_item!== undefined){
      this.selected_list=this.selected_item;
    }
  }, 300);
  },
  computed: {
    checkInput: {
      get() { return this.inputValue },
      set(value) {
        this.inputValue = value;
        this.searchItem(value);
      }
    }
  },
  methods: {
    // ========= [ Searching Items ] ========= //
    searchItem(event) {
      // if(event !== '') {
        this.searchDataList = this.area_of_interest_data.filter(searchList => {
          return Object.values(searchList).join(' ').toLowerCase().includes(event.toLowerCase());
        })
      // } else {
      //   this.searchDataList.length > 0 ? this.searchDataList : this.area_of_interest_data;
      // }
    },

    // ========= [ Item selection ] ========= //
    itemSelection(id) {
      // this.dropdownListToggle(false);
      let newItem = this.area_of_interest_data.find(item => item.product_category_id === id);
      this.area_of_interest_data.map(item => item.product_category_id === id ? item.selected = !item.selected : item.selected);

      let already_selected = this.selected_list.some(tag => tag.product_category_id === id);
      // console.log("already exist >>>> ",already_selected);
      if(!already_selected) {
        console.log("not already selected");
      //   this.error = false;
        this.selected_list.push(newItem);
        this.$emit('getSelectedData', this.selected_list)
      } else {
        this.removeItems(id)
      }
    },
    removeItems(id) {
      this.selected_list = this.selected_list.filter(item => item.product_category_id !== id);
      this.area_of_interest_data.map(item => {
        if(item.product_category_id === id) item.selected = false
      });
      // this.validation();
      this.$emit('getSelectedData', this.selected_list)
    },
    // ========= [ Dropdown Toggler ] ========= //
    dropdownListToggle(event) {
      this.elementFocused = event;
      this.searchDataList  = this.area_of_interest_data;
    },
    hideDropdown() {
      this.dropdownListToggle(false)
    },
    getPos(e) {
      e.stopImmediatePropagation();
       /* eslint-disable */ 
      const parentOffsetHeight = this.$refs.parentElementPosition?.getBoundingClientRect()
      const dropdownElement = this.$refs.dropdownElement?.getBoundingClientRect()

      if((dropdownElement?.top + dropdownElement?.height + parentOffsetHeight.height) > this.windowHeight) {
        console.log('window is heigher');
        this.dropwdown_alignment = true;
      } else {
        console.log('element is heigher');
        this.dropwdown_alignment = false;
      }
    },
    validation() {
      if(this.selected_list?.length <= 0 && this.required) {
        return this.error = true;
      }
      this.error = false;
    }
  },
}
</script>

<style lang="scss">
  .multi-input {
    position: relative;
    z-index: 1;
    &__input-box {
      --label-height: 27px;
      position: relative;
      z-index: 1;
      input { 
        height: var(--base-input-element-height); 
        background-image: url('@/../public/images/sort-icon.svg');
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 8px 10px;
        position: relative;
        z-index: 1;
      }
    }
    &__selection-dropdown {
      position: absolute;
      &:not(.aligned-top) { top: 100%; }
      // &:is(.aligned-top) { bottom: calc(100% - var(--label-height)); }
      width: 100%;
      border-radius: 0.35rem;
      background-color: var(--bs-white);
      z-index: 1;
      max-height: 40vh;
      overflow-y: scroll;
      @include scroller();
      box-shadow:
        0 0 0 1px rgba(var(--bs-primary), 0.5),
        0px 2.8px 2.2px rgba(0, 0, 0, 0.011),
        0px 6.7px 5.3px rgba(0, 0, 0, 0.016),
        0px 12.5px 10px rgba(0, 0, 0, 0.02),
        0px 22.3px 17.9px rgba(0, 0, 0, 0.024),
        0px 41.8px 33.4px rgba(0, 0, 0, 0.029),
        0px 100px 80px rgba(0, 0, 0, 0.04)
      ;
      &__list {
        position: relative;
        list-style: none;
        margin: 0;
        padding: 0;
        --border-color: var(--bs-gray-200);
        --border-hover-color: var(--bs-gray-200);

        --list-bg-color: var(--bs-white);
        --list-bg-hover-color: var(--bs-gray-200);
        --list-color: var(--bs-dark);
        --list-hover-color: var(--bs-dark);
        --list-active-bg-color: rgba(var(--bs-primary),1);
        --list-active-color: var(--bs-white);
        --list-active-hover-bg-color: var(--bs-danger);
        --list-active-hover-color: var(--bs-white);

        &__item {
          position: relative;
          padding: 0.45rem 1rem;
          display: flex;
          align-items: center;
          gap: 1rem;
          transition: var(--transition);
          background-color: var(--list-bg-color);
          color: var(--list-color);
          i { font-size: 120%;}
          &:not(&:first-child) {
            border-top: 1px solid var(--bs-gray-200);
          }
          &:hover {
            background-color: var(--list-bg-hover-color);
            color: var(--list-hover-color);
            cursor: pointer;
          }
          &.active, &.selected {
            background-color: var(--list-active-bg-color);
            color: var(--list-active-color);
            i:before { 
              content: '\f14a'; 
              font-weight: 900;
            }
            &:hover {
              background-color: rgba(var(--bs-primary),0.8);
              i:before { 
                content: '\f2d3'; 
              }              
            }
          }
        }
      }
    }
    &__selected__pills {
      padding-block: .5rem;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      line-height: normal;
      &__item {
        transition: var(--transition);
        position: relative;
        padding-block: 0.4rem;
        padding-inline: 0.6rem 1rem;
        border-radius: .35rem;
        background-color: rgba(var(--bs-primary), 1);
        color: var(--bs-white);
        display: flex;
        align-items: center;
        gap: .75rem;
        min-height: 35px;
        i {
          font-size: 140%;
        }
        &--close {
          flex-shrink: 0;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 150%;
          border-radius: .25rem;
          transition: var(--transition);          
          cursor: pointer;
          &:hover {
            background-color: var(--bs-white);
            color: var(--bs-danger);
          }
        }
        &:hover {
          box-shadow: inset 0 0 0 1px rgba(var(--bs-primary),1);
          background-color: var(--bs-white);
          color: rgba(var(--bs-primary),1);
          cursor: pointer;
          i {
            &::before { content: '\f2d3';}
          }
        }
      }
    }
  }
</style>