<template>
  <b-tab title="Attendee Information" id="tab1">
    <div class="form-tab-body-content">
      <template v-if="exception_message">
        <div class="alert alert-danger" role="alert" v-html="exception_message"></div>
      </template>
      <div class="row gx-4 gy-3 mx-0">
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.salutation_id">
          <div class="input-block">
            <label for="title" class="mb-1">

              {{ getFormFields?.salutation_id?.label }} <i class="fa-solid fa-star-of-life"
                v-if="getFormFields?.salutation_id.required"></i>
              <i v-if="getFormFields?.salutation_id.help_text" v-b-tooltip.hover
                :title="getFormFields?.salutation_id.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>

            <b-form-select v-model="salutation_value">
              <b-form-select-option :value="null" selected>
                {{ getFormFields?.salutation_id?.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="salution?.salutation_id" :key="key"
                v-for="(salution, key) in $store.state.edition_details.salutations">
                {{ salution.salutation }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.salutation_value !== null && salutation_value === null" class="justify-content-center">
              <p class="text-danger">{{ errors.salutation_value }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.first_name">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.first_name.label }} <i v-if="getFormFields.first_name.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.first_name.help_text" v-b-tooltip.hover :title="getFormFields?.first_name.help_text"
                class="iconSetting fa-regular fa-circle-question"></i></label>

            <b-form-input v-model="first_name" :placeholder="getFormFields.first_name.placeholder" />
            <div v-if="errors.first_name && !first_name" class="justify-content-center">
              <p class="text-danger">{{ errors.first_name }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.middle_name">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.middle_name.label }} <i v-if="getFormFields.middle_name.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.middle_name.help_text" v-b-tooltip.hover
                :title="getFormFields?.middle_name.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="middle_name" :placeholder="getFormFields.middle_name.placeholder" />
            <div v-if="errors.middle_name && (middle_name === null || middle_name === '')" class="justify-content-center">
              <p class="text-danger">{{ errors.middle_name }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.last_name">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.last_name.label }} <i v-if="getFormFields.last_name.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.last_name?.help_text" v-b-tooltip.hover :title="getFormFields?.last_name.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="last_name" :placeholder="getFormFields.last_name.placeholder" />
            <div v-if="errors.last_name && (last_name === null || last_name === '')" class="justify-content-center">
              <p class="text-danger">{{ errors.last_name }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.gender">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.gender.label }} <i v-if="getFormFields.gender.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.gender?.help_text" v-b-tooltip.hover :title="getFormFields?.gender.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="gender">
              <b-form-select-option :value="null" selected>
                {{ getFormFields?.gender?.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="gender" :key="key" v-for="(gender, key) in getFormFields.gender.options">
                {{ gender }}
              </b-form-select-option>
            </b-form-select>
          </div>
          <div v-if="errors.gender && (gender === null || gender === '')" class="justify-content-center">
            <p class="text-danger">{{ errors.gender }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.phone">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.phone.label }} <i v-if="getFormFields.phone.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.phone?.help_text" v-b-tooltip.hover :title="getFormFields?.phone.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <!-- <VuePhoneNumberInput v-model="phone" @update="onPhoneCountryChanged" :fetch-country="true"
              :placeholder="getFormFields.phone.placeholder" /> -->
              <vue-tel-input
                @open="updateSearchBoxPlaceholder"
                v-model="phone"
                @input="onPhoneCountryChanged"
                :placeholder="getFormFields.phone.placeholder"
                :validCharactersOnly="true"
                :dropdownOptions="{showDialCodeInSelection: true, showFlags: true, showDialCodeInList: true, showSearchBox: true}"
                :inputOptions="{ autocomplete: 'off' }"
              ></vue-tel-input>
            <!-- <small>Include country code (97145208888), do not use “+” or “00”</small> -->
            <div v-if="errors.phone" class="justify-content-center">
              <p class="text-danger">{{ errors.phone }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.mobile">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.mobile.label }} <i v-if="getFormFields.mobile.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.mobile?.help_text" v-b-tooltip.hover :title="getFormFields?.mobile.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <!-- <VuePhoneNumberInput v-model="mobile" @update="onMobileCountryChanged" :fetch-country="true"
              :placeholder="getFormFields.mobile.placeholder" /> -->
              <vue-tel-input
               @open="updateSearchBoxPlaceholder"
                v-model="mobile"
                @input="onMobileCountryChanged"
                :placeholder="getFormFields.mobile.placeholder"
                :validCharactersOnly="true"
                :dropdownOptions="{showDialCodeInSelection: true, showFlags: true, showDialCodeInList: true, showSearchBox: true, }"
                :inputOptions="{ autocomplete: 'off' }"
              ></vue-tel-input>
            <!-- <small>Include country code (97145208888), do not use “+” or “00”</small> -->
            <div v-if="errors.mobile" class="justify-content-center">
              <p class="text-danger">{{ errors.mobile }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.age_group_id">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.age_group_id.label }} <i v-if="getFormFields.age_group_id.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.age_group_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.age_group_id.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="age_group">
              <b-form-select-option :disabled="true" :selected="true" :value="null">{{
                getFormFields.age_group_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="age_group.age_group_id" :key="key"
                v-for="(age_group, key) in $store.state.edition_details.age_groups">
                {{ age_group.age_group }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.age_group && (age_group === null || age_group === '')" class="justify-content-center">
              <p class="text-danger">{{ errors.age_group }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.authority_number">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.authority_number.label }} <i v-if="getFormFields.authority_number.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.authority_number?.help_text" v-b-tooltip.hover
                :title="getFormFields?.authority_number.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="authority_number" :placeholder="getFormFields.authority_number.placeholder"
              :value="getFormFields.authority_number.default_value" />
            <div v-if="errors.authority_number && (authority_number === null || authority_number === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.authority_number }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.nationality_id">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.nationality_id.label }} <i v-if="getFormFields.nationality_id.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.nationality_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.nationality_id.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="selected_nationality">
              <b-form-select-option :disabled="true" :selected="true" :value="null">{{
                getFormFields.nationality_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="country.country_id" :key="key"
                v-for="(country, key) in $store.state.edition_details.countries">
                {{ country.country }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.selected_nationality && (selected_nationality === null || selected_nationality === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.selected_nationality }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.marketing_source">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.marketing_source.label }} <i v-if="getFormFields.marketing_source.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.marketing_source?.help_text" v-b-tooltip.hover
                :title="getFormFields?.marketing_source.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="marketing_source">
              <b-form-select-option :disabled="true" :selected="true" :value="null">{{
                getFormFields.marketing_source.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="marketing_source.value" :key="key"
                v-for="(marketing_source, key) in marketing_source_data">
                {{ marketing_source.value }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.marketing_source && (marketing_source === null || marketing_source === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.marketing_source }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="marketing_source === 'other'">
          <div class="input-block">
            <label for="title" class="mb-1">
              Other
              <i v-if="getFormFields?.marketing_source?.help_text" v-b-tooltip.hover
                :title="getFormFields?.marketing_source.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="other_source" placeholder="Enter marketing source" />
            <div v-if="errors.marketing_source && (marketing_source === null || marketing_source === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.marketing_source }}</p>
            </div>
          </div>
        </div>
        <!-- Add Role Filed -->
        <div
          class="col-12 col-md-6 col-lg-4"
          v-if="getFormFields && getFormFields?.role"
        >
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.role?.label }}
              <i
                v-if="getFormFields.role?.required"
                class="fa-solid fa-star-of-life"
              ></i>
              <i
                v-if="getFormFields.role?.help_text"
                v-b-tooltip.hover
                :title="getFormFields.role?.help_text"
                class="iconSetting fa-regular fa-circle-question"
              ></i>
            </label>
            <b-form-select v-model="role">
              <b-form-select-option
                :disabled="true"
                :selected="true"
                :value="null"
                >{{ getFormFields?.role?.placeholder }}
              </b-form-select-option>
              <b-form-select-option
                v-for="(roleOption, index) in rolesData"
                :key="index"
                :value="roleOption.value"
              >
                {{ roleOption.label }}
              </b-form-select-option>
            </b-form-select>
            <div
              v-if="errors.role && (role === null || role === '')"
              class="justify-content-center"
            >
              <p class="text-danger">{{ errors.role }}</p>
            </div>
          </div>
        </div>
        <!-- End Role Filed -->
        <div class="col-12" v-if="getFormFields?.are_you_a_buyer">
          <div class="input-block input-block__radios mt-2" :class="{ 'bg-priamry-color text-white': is_buyer }">
            <b-form-group :label="getFormFields.are_you_a_buyer.label">
              <i style="font-size: 50%;color:var(--bs-danger);margin-top: 7px;"
                v-if="getFormFields?.are_you_a_buyer?.required" class="fa-solid fa-star-of-life"></i>
              <i style="margin-top: 5px;" v-if="getFormFields?.are_you_a_buyer?.help_text" v-b-tooltip.hover
                :title="getFormFields?.are_you_a_buyer?.help_text" class="iconSetting fa-regular fa-circle-question"></i>
              <b-form-radio v-model="is_buyer" :value="true">Yes</b-form-radio>
              <b-form-radio v-model="is_buyer" :value="false">No</b-form-radio>
            </b-form-group>
            <div v-if="errors.is_buyer && (is_buyer === null || is_buyer === '')" class="justify-content-center">
              <p class="text-danger">{{ errors.is_buyer }}</p>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="getFormFields?.e_market_consent">
          <div class="input-block input-block__radios mt-2"
            :class="{ 'bg-priamry-color text-white': e_market_consent }">
            <b-form-group :label="getFormFields.e_market_consent.label">
              <i style="font-size: 50%;color:var(--bs-danger);margin-top: 7px;"
                v-if="getFormFields?.e_market_consent?.required" class="fa-solid fa-star-of-life"></i>
              <i style="margin-top: 5px;" v-if="getFormFields?.e_market_consent?.help_text" v-b-tooltip.hover
                :title="getFormFields?.e_market_consent?.help_text" class="iconSetting fa-regular fa-circle-question"></i>
              <b-form-radio v-model="e_market_consent" :value="true">Yes</b-form-radio>
              <b-form-radio v-model="e_market_consent" :value="false">No</b-form-radio>
            </b-form-group>
            <div v-if="errors.e_market_consent && (e_market_consent === null || e_market_consent === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.e_market_consent }}</p>
            </div>
          </div>
        </div>

        <!-- ============= [ Address Details ] ============= -->
        <div class="sec-heading [ d-flex gap-3 align-items-center mt-4 ]" v-if="getFormFields?.address">
          <p class="white-space-nowrap fw-bold m-0 text-uppercase">Address Details</p>
          <hr class="w-100" />
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.address">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.address.label }} <i v-if="getFormFields.address.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.address?.help_text" v-b-tooltip.hover :title="getFormFields?.address.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="address" :placeholder="getFormFields.address.placeholder" />
          </div>
          <div v-if="errors.address && (address === null || address === '')" class="justify-content-center">
            <p class="text-danger">{{ errors.address }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.country_id">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.country_id.label }} <i v-if="getFormFields.country_id.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.country_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.country_id.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select @change="getStatesData" v-model="selected_country">
              <b-form-select-option :disabled="true" :selected="true" :value="null">{{
                getFormFields.country_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="country.country_id" :key="key"
                v-for="(country, key) in $store.state.edition_details.countries">
                {{ country.country }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.selected_country && (selected_country === null || selected_country === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.selected_country }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.state_id">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.state_id.label }} <i v-if="getFormFields.state_id.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.state_id?.help_text" v-b-tooltip.hover :title="getFormFields?.state_id.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="selected_state"
              @change="getCitiesData(); setValidationFieldNull('selected_state', selected_state)">
              <b-form-select-option :value="null" :disabled="true" :selected="true">
                {{ getFormFields.state_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="state.state_id" v-for="(state, index) in states_data" :key="index">
                {{ state.state }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.selected_state && (selected_state === null || selected_state === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.selected_state }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.city_id">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.city_id.label }} <i v-if="getFormFields.city_id.required" class="fa-solid fa-star-of-life"></i>

              <i v-if="getFormFields?.city_id?.help_text" v-b-tooltip.hover :title="getFormFields?.city_id.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="selected_city" @change="setValidationFieldNull('selected_city', selected_city)">
              <b-form-select-option :value="city.city_id" v-for="(city, index) in cities_data" :key="index">
                {{ city.name }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.selected_city && (selected_city === null || selected_city === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.selected_city }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.p_o_box">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.p_o_box.label }} <i v-if="getFormFields.p_o_box.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.p_o_box?.help_text" v-b-tooltip.hover :title="getFormFields?.p_o_box.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="zip_postal_code" :placeholder="getFormFields.p_o_box.placeholder" />
            <div v-if="errors.zip_postal_code && (zip_postal_code === null || zip_postal_code === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.zip_postal_code }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.zip_code">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.zip_code.label }} <i v-if="getFormFields.zip_code.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.zip_code?.help_text" v-b-tooltip.hover :title="getFormFields?.zip_code.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="zip_code" :placeholder="getFormFields.zip_code.placeholder" />
            <div v-if="errors.zip_code && (zip_code === null || zip_code === '')" class="justify-content-center">
              <p class="text-danger">{{ errors.zip_code }}</p>
            </div>
          </div>
        </div>

        <!-- ============= [ Industry Details Details ] ============= -->
        <div class="sec-heading [ d-flex gap-3 align-items-center mt-4 ]" v-if="getFormFields?.industry_id">
          <p class="white-space-nowrap fw-bold m-0 text-uppercase">Industry Details</p>
          <hr class="w-100" />
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.industry_id">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.industry_id.label }} <i v-if="getFormFields.industry_id.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.industry_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.industry_id.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="selected_industry"
              @change="getIndustryJobFunctionsData(); getEditionIndustriesJobTitles(); setValidationFieldNull('selected_industry', selected_industry)">
              <b-form-select-option :value="null" :disabled="true" :selected="true">
                {{ getFormFields.industry_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="industry.industry_id" v-for="(industry, index) in editionIndustries"
                :key="index">
                {{ industry.name }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.selected_industry && (selected_industry === null || selected_industry === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.selected_industry }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.job_title_id">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.job_title_id.label }} <i v-if="getFormFields.job_title_id.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.job_title_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.job_title_id.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="selected_jobTitle"
              @change="setValidationFieldNull('selected_jobTitle', selected_jobTitle)">
              <b-form-select-option :value="null" :disabled="true" :selected="true">
                {{ getFormFields.job_title_id.label }}
              </b-form-select-option>
              <b-form-select-option :value="job.job_title_id" v-for="(job, index) in industry_job_titles_data"
                :key="index">
                {{ job.name }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.selected_jobTitle && (selected_jobTitle === null || selected_jobTitle === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.selected_jobTitle }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="selected_jobTitle == 23">
          <div class="input-block" :key="otherTitleKey">
            <label for="title" class="mb-1">
              Other Title
              <i class="fa-solid fa-star-of-life"></i>
            </label>
            <b-form-input v-model="other_title" placeholder="Enter job title" />
            <div v-if="errors?.other_title && !other_title"
              class="justify-content-center">
              <p class="text-danger">{{ errors.other_title }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.industry_job_function_id">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.industry_job_function_id.label }} <i v-if="getFormFields.industry_job_function_id.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.industry_job_function_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.industry_job_function_id.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="selected_industry_job_function"
              @change="setValidationFieldNull('selected_industry_job_function', selected_industry_job_function)">
              <b-form-select-option :value="null" :disabled="true" :selected="true">
                {{ getFormFields.industry_job_function_id.label }}
              </b-form-select-option>
              <b-form-select-option :value="industry_job_function.job_function_id"
                v-for="(industry_job_function, index) in industry_job_functions_data" :key="index">
                {{ industry_job_function.job_function }}
              </b-form-select-option>
            </b-form-select>
            <div
              v-if="errors.selected_industry_job_function && (selected_industry_job_function === null || selected_industry_job_function === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.selected_industry_job_function }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getSeniorityLevels?.length > 0 && getFormFields?.seniority_level_id">
          <div class="input-block">
            <label for="title" class="mb-1">
              {{ getFormFields.seniority_level_id.label }} <i v-if="getFormFields.seniority_level_id.required"
                class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.seniority_level_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.seniority_level_id.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="selected_seniorityLevel"
              @change="setValidationFieldNull('selected_seniorityLevel', selected_seniorityLevel)">
              <b-form-select-option :value="null" :disabled="true" :selected="true">
                {{ getFormFields.seniority_level_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="level.seniority_level_id" v-for="(level, index) in getSeniorityLevels"
                :key="index">
                {{ level.seniority_level }}
              </b-form-select-option>
            </b-form-select>
            <div
              v-if="errors.selected_seniorityLevel && (selected_seniorityLevel === null || selected_seniorityLevel === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.selected_seniorityLevel }}</p>
            </div>
          </div>
        </div>

        <!-- ============= [ Company Entity Details ] ============= -->
        <div class="sec-heading [ d-flex gap-3 align-items-center mt-4" v-if="getFormFields?.entity_name">
          <p class="white-space-nowrap fw-bold m-0 text-uppercase">
            Company &amp; Entity Details
          </p>
          <hr class="w-100" />
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_name">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_name.label }}
              <i v-if="getFormFields.entity_name.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_name?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_name.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="entity_name" :placeholder="getFormFields.entity_name.placeholder" />
          </div>
          <div v-if="errors.entity_name && (entity_name === null || entity_name === '')" class="justify-content-center">
            <p class="text-danger">{{ errors.entity_name }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_industry_id">
          <div class="input-block">
            <label for="title" class="mb-1">
              <label for="" class="mb-1">{{ getFormFields.entity_industry_id.label }}
                <i v-if="getFormFields.entity_industry_id.required" class="fa-solid fa-star-of-life"></i>
                <i v-if="getFormFields?.entity_industry_id?.help_text" v-b-tooltip.hover
                  :title="getFormFields?.entity_industry_id.help_text"
                  class="iconSetting fa-regular fa-circle-question"></i>
              </label>
            </label>
            <b-form-select v-model="selected_companyIndustry"
              @change="getCompanyActivities(); setValidationFieldNull('selected_companyIndustry', selected_companyIndustry)">
              <b-form-select-option :value="null" :disabled="true" :selected="true">
                {{ getFormFields.entity_industry_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="industry.industry_id" v-for="(industry, index) in editionIndustries"
                :key="index">
                {{ industry.name }}
              </b-form-select-option>
            </b-form-select>
            <div
              v-if="errors.selected_companyIndustry && (selected_companyIndustry === null || selected_companyIndustry === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.selected_companyIndustry }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_business_activity_id">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_business_activity_id.label }}
              <i v-if="getFormFields.entity_business_activity_id.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_business_activity_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_business_activity_id.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="entity_business_activity_id"
              @change="setValidationFieldNull('entity_business_activity_id', entity_business_activity_id)">
              <b-form-select-option :value="null" :disabled="true" :selected="true">
                {{ getFormFields.entity_business_activity_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="activity.industry_business_activity_id"
                v-for="(activity, index) in company_activities" :key="index">
                {{ activity.name }}
              </b-form-select-option>
            </b-form-select>
            <div
              v-if="errors.entity_business_activity_id && (entity_business_activity_id === null || entity_business_activity_id === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.entity_business_activity_id }}</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_email">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_email.label }}
              <i v-if="getFormFields.entity_email.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_email?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_email.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>

            <b-form-input v-model="entity_email" :placeholder="getFormFields.entity_email.placeholder" />
          </div>
          <div v-if="errors.entity_email && (entity_email === null || entity_email === '')"
            class="justify-content-center">
            <p class="text-danger">{{ errors.entity_email }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_phone">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_phone.label }}
              <i v-if="getFormFields.entity_phone.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_phone?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_phone.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <!-- <VuePhoneNumberInput :default-country-code="defaultCountry" v-model="entity_phone" @update="onPhoneCountryChanged"
              :placeholder="getFormFields.entity_phone.placeholder" /> -->
              <vue-tel-input
                @open="updateSearchBoxPlaceholder"
                v-model="entity_phone"
                @input="onEntityPhoneCountryChanged"
                :placeholder="getFormFields.entity_phone.placeholder"
                :validCharactersOnly="true"
                :dropdownOptions="{showDialCodeInSelection: true, showFlags: true, showDialCodeInList: true, showSearchBox: true, }"
                :inputOptions="{ autocomplete: 'off' }"
              ></vue-tel-input>
            <!-- <small>Include country code (97145208888), do not use “+” or “00”</small> -->
          </div>
          <div v-if="errors.entity_phone"
            class="justify-content-center">
            <p class="text-danger">{{ errors.entity_phone }}</p>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_p_o_box">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_p_o_box.label }}
              <i v-if="getFormFields.entity_p_o_box.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_p_o_box?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_p_o_box.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="entity_p_o_box" :placeholder="getFormFields.entity_p_o_box.placeholder" />
          </div>
          <div v-if="errors.entity_p_o_box && (entity_p_o_box === null || entity_p_o_box === '')"
            class="justify-content-center">
            <p class="text-danger">{{ errors.entity_p_o_box }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_fax">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_fax.label }}
              <i v-if="getFormFields.entity_fax.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_fax?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_fax.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="entity_fax" :placeholder="getFormFields.entity_fax.placeholder" />
          </div>
          <div v-if="errors.entity_fax && (entity_fax === null || entity_fax === '')" class="justify-content-center">
            <p class="text-danger">{{ errors.entity_fax }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_website">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_website.label }}
              <i v-if="getFormFields.entity_website.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_website?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_website.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="entity_website" :placeholder="getFormFields.entity_website.placeholder" />
          </div>
          <div v-if="errors.entity_website && (entity_website === null || entity_website === '')"
            class="justify-content-center">
            <p class="text-danger">{{ errors.entity_website }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_address">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_address.label }}
              <i v-if="getFormFields.entity_address.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_address?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_address.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="entity_address" :placeholder="getFormFields.entity_address.placeholder" />
          </div>
          <div v-if="errors.entity_address && (entity_address === null || entity_address === '')"
            class="justify-content-center">
            <p class="text-danger">{{ errors.entity_address }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4"
          v-if="$store.state.edition_details.entity_types?.length > 0 && getFormFields?.entity_type_id">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_type_id.label }}
              <i v-if="getFormFields.entity_type_id.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_type_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_type_id.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="entity_type_id" @change="setValidationFieldNull('entity_type_id', entity_type_id)">
              <b-form-select-option :value="null" :disabled="true" :selected="true">
                {{ getFormFields.entity_type_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="entity_type.entity_type_id"
                v-for="(entity_type, index) in $store.state.edition_details.entity_types" :key="index">
                {{ entity_type.entity_type }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.entity_type_id && (entity_type_id === null || entity_type_id === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.entity_type_id }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_country_id">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_country_id.label }}
              <i v-if="getFormFields.entity_country_id.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_country_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_country_id.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="entity_country_id"
              @change="getEntityStatesData(); setValidationFieldNull('entity_country_id', entity_country_id)">
              <b-form-select-option :value="null" :disabled="true" :selected="true">
                {{ getFormFields.entity_country_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="country.country_id"
                v-for="(country, index) in $store.state.edition_details.countries" :key="index">
                {{ country.country }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.entity_country_id && (entity_country_id === null || entity_country_id === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.entity_country_id }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_state_id">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_state_id.label }}
              <i v-if="getFormFields.entity_state_id.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_state_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_state_id.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="entity_state_id"
              @change="getEntityCitiesData(); setValidationFieldNull('entity_state_id', entity_state_id)">
              <b-form-select-option :value="null" :disabled="true" :selected="true">
                {{ getFormFields.entity_state_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="state.state_id" v-for="(state, index) in get_entity_states" :key="index">
                {{ state.state }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.entity_state_id && (entity_state_id === null || entity_state_id === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.entity_state_id }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_city_id">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_city_id.label }}
              <i v-if="getFormFields.entity_city_id.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_city_id?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_city_id.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-select v-model="entity_city_id" @change="setValidationFieldNull('entity_city_id', entity_city_id)">
              <b-form-select-option :value="null" :disabled="true" :selected="true">
                {{ getFormFields.entity_city_id.placeholder }}
              </b-form-select-option>
              <b-form-select-option :value="city.city_id" v-for="(city, index) in get_entity_cities" :key="index">
                {{ city.name }}
              </b-form-select-option>
            </b-form-select>
            <div v-if="errors.entity_city_id && (entity_city_id === null || entity_city_id === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.entity_city_id }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.entity_zip_code">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.entity_zip_code.label }}
              <i v-if="getFormFields.entity_zip_code.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.entity_zip_code?.help_text" v-b-tooltip.hover
                :title="getFormFields?.entity_zip_code.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="entity_zip_code" :placeholder="getFormFields.entity_zip_code.placeholder" />
            <div v-if="errors.entity_zip_code && (entity_zip_code === null || entity_zip_code === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.entity_zip_code }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.internal_notes">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.internal_notes.label }}
              <i v-if="getFormFields.internal_notes.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.internal_notes?.help_text" v-b-tooltip.hover
                :title="getFormFields?.internal_notes.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="internal_notes" :placeholder="getFormFields.internal_notes.placeholder" />
            <div v-if="errors.internal_notes && (internal_notes === null || internal_notes === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.internal_notes }}</p>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="getFormFields?.optionalFieldOne">
          <div class="input-block input-block__radios mt-2"
            :class="{ 'bg-priamry-color text-white': optionalFieldOne }">
            <b-form-group :label="getFormFields.optionalFieldOne.label">
              <i style="font-size: 50%;color:var(--bs-danger);margin-top: 7px;"
                v-if="getFormFields?.optionalFieldOne?.required" class="fa-solid fa-star-of-life"></i>
              <i style="margin-top: 5px;" v-if="getFormFields?.optionalFieldOne?.help_text" v-b-tooltip.hover
                :title="getFormFields?.optionalFieldOne?.help_text" class="iconSetting fa-regular fa-circle-question"></i>
              <b-form-radio v-model="optionalFieldOne" :value="true">Yes</b-form-radio>
              <b-form-radio v-model="optionalFieldOne" :value="false">No</b-form-radio>
            </b-form-group>
          </div>
          <div v-if="errors.optionalFieldOne && (optionalFieldOne === null || optionalFieldOne === '')"
            class="justify-content-center">
            <p class="text-danger">{{ errors.optionalFieldOne }}</p>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.optionalFieldTwo">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.optionalFieldTwo.label }}
              <i v-if="getFormFields.optionalFieldTwo.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.optionalFieldTwo?.help_text" v-b-tooltip.hover
                :title="getFormFields?.optionalFieldTwo.help_text" class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="optionalFieldTwo" :placeholder="getFormFields.optionalFieldTwo.placeholder" />
            <div v-if="errors.optionalFieldTwo && (optionalFieldTwo === null || optionalFieldTwo === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.optionalFieldTwo }}</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.optionalFieldThree">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields.optionalFieldThree.label }}
              <i v-if="getFormFields.optionalFieldTwo.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.optionalFieldThree?.help_text" v-b-tooltip.hover
                :title="getFormFields?.optionalFieldThree.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <b-form-input v-model="optionalFieldThree" :placeholder="getFormFields.optionalFieldThree.placeholder" />
            <div v-if="errors.optionalFieldThree && (optionalFieldThree === null || optionalFieldThree === '')"
              class="justify-content-center">
              <p class="text-danger">{{ errors.optionalFieldThree }}</p>
            </div>
          </div>
        </div>

        <!--         ============= [ Area of Interests ] =============-->
        <div class="sec-heading [ d-flex gap-3 align-items-center mt-4 ]"
          v-if="getFormFields?.interested_product_categories">
          <p class="white-space-nowrap fw-bold m-0 text-uppercase">
            Areas of interests
          </p>
          <hr class="w-100" />
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-if="getFormFields?.interested_product_categories">
          <div class="input-block">
            <label for="" class="mb-1">{{ getFormFields?.interested_product_categories?.label }}
              <i v-if="getFormFields.interested_product_categories.required" class="fa-solid fa-star-of-life"></i>
              <i v-if="getFormFields?.interested_product_categories?.help_text" v-b-tooltip.hover
                :title="getFormFields?.interested_product_categories.help_text"
                class="iconSetting fa-regular fa-circle-question"></i>
            </label>
            <InputMultiSelect @onFocus="scrollToTopOnMobile" :key="dynamicKey" :selected_item="selected_area_of_interest_data" :dropdownList="getProductCategoryData"
              @getSelectedData="handleInterestedAreas" required />
            <span v-if="errors.interested_product_categories && interested_product_categories.length == 0"
              class="justify-content-center">
              <p class="text-danger">{{ errors.interested_product_categories }}</p>
            </span>
          </div>
        </div>

      </div>
    </div>
    <!-- ============= [ Tab Block Footer ] ============= -->
    <div class="form-tab-footer">
      <div></div>
      <b-button-group>
        <b-button variant="primary" class="" size="lg" @click="tabIndexingHandler(tabIndexing);">
          Continue
          <i class="fa-solid fa-angle-right"></i>
        </b-button>
      </b-button-group>
    </div>
    <PartnersAndSoponsersLogos />
  </b-tab>
</template>

<script>
import InputMultiSelect from '../InputMultiSelect.vue';
import PartnersAndSoponsersLogos from '../PartnersAndSoponsersLogos.vue';
import gql from "graphql-tag";
// import VuePhoneNumberInput from 'vue-phone-number-input';
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

// import { mapGetters } from 'vuex'
// import tabIndexingHandler from '@/commonFunctions/tab-indexing';
export default {
  name: "Tab_AttendeeInformations",
  
  components: { InputMultiSelect, PartnersAndSoponsersLogos, VueTelInput },
  props: ["tabIndex", "registration_data"],
  async beforeCreate() {
    await this.$store.dispatch('getEditionData', {
      event: this.$route.query.event ?? this.$route.params.event_id,
      type: this.$route.query.type ?? this.$route.params.type
    });
    if(!this.$store.state.edition_details?.url_details?.new_design){
      var event= this.$route.query.event ?? this.$route.params.event_id;
      var type =this.$route.query.type ?? this.$route.params.type;
      var token= this.$route.query.token ?? this.$route.params.token;
          window.location.href = process.env.VUE_APP_old_online_registration+"registration/form/"+event+"/"+token+'?type='+type;
          return false;
        }
    // this.edition_details = this.$store.state.edition_details;
    // this.salutations = this.edition_details.salutations;
  },
  created() {
    this.form_fields = this.$store.state.edition_details.fields
  },
  data() {
    return {
      rolesData: [
        {
          value: "Clinician",
          label: "Clinician",
        },
        {
          value: "Nurse/Allied Health Provider",
          label: "Nurse/Allied Health Provider",
        },
        {
          value: "Physiotherapist/Occupational Therapist",
          label: "Physiotherapist/Occupational Therapist",
        },
        { value: "Academic/Researcher", label: "Academic/Researcher" },
        { value: "Industry", label: "Industry" },
        {
          value: "Patient/Patient Representative",
          label: "Patient/Patient Representative",
        },
        { value: "Patient Organization", label: "Patient Organization" },
      ],
      role: null,
      otherTitleKey: 1,
      contact_id:null,
      defaultCountry:"AE",
      dynamicKey:0,
      other_title: null,
      entity_p_o_box: null,
      entity_name: null,
      entity_website: null,
      e_market_consent: false,
      internal_notes: null,
      entity_fax: null,
      entity_phone: null,
      entity_zip_code: null,
      entity_email: null,
      authority_number: null,
      entity_country_id: null,
      marketing_source: null,
      entity_state_id: null,
      entity_type_id: null,
      entity_address: null,
      entity_city_id: null,
      zip_code: null,
      other_source: "",
      phone_code: null,
      mobile_code: null,
      tabIndexing: this.tabIndex,
      salutations: [],
      states_data: [],
      cities_data: [],
      get_entity_states: [],
      get_entity_cities: [],
      edition_details: {},
      titles: [
        { value: null, text: "-- Select Title --" },
      ],
      selected_title: null,
      gender: null,
      genders_data: [
        { value: "Male", text: "Male" },
        { value: "Female", text: "Female" },
      ],
      ageGroups: null,
      age_group: null,
      countries_list: null,
      selected_nationality: null,
      selected_industry: null,
      jobDepartment: null,
      selected_industry_job_function: null,
      industry_job_functions_data: [],
      industry_job_titles_data: [],
      selected_jobTitle: null,
      seniorityLevel: null,
      selected_seniorityLevel: null,
      selected_companyIndustry: null,
      entity_business_activity_id: null,
      company_activities: [],
      selected_companyActivity: null,
      first_name: null,
      middle_name: null,
      last_name: null,
      phone: null,
      mobile: null,
      is_buyer: false,
      address: null,
      selected_country: null,
      selected_state: null,
      zip_postal_code: null,
      selected_city: null,
      company: null,
      company_website: null,
      contact_details: null,
      optionalFieldOne: null,
      optionalFieldTwo: null,
      optionalFieldThree: null,
      
      marketing_source_data: [
        { value: "Eflyer", text: "Eflyer" },
        { value: "Social Media", text: "Social Media" },
        { value: "Newspaper advertisement", text: "Newspaper advertisement" },
        { value: "Brochures", text: "Brochures" },
        { value: "Website", text: "Website" },
        { value: "other", text: "other" }
      ],

      selected_area_of_interest_data: null,
      salutation_value: null,
      errors: {
        entity_website:null,
        optionalFieldOne: null,
        optionalFieldTwo: null,
        optionalFieldThree: null,
        salutation_value: null,
        first_name: null,
        last_name: null,
        gender: null,
        selected_seniorityLevel: null,
        selected_companyActivity: null,
        selected_companyIndustry: null,
        entity_business_activity_id: null,
        company: null,
        selected_jobTitle: null,
        selected_industry_job_function: null,
        selected_industry: null,
        zip_postal_code: null,
        selected_city: null,
        selected_state: null,
        selected_country: null,
        address: null,
        is_buyer: null,
        selected_nationality: null,
        age_group: null,
        mobile: null,
        phone: null,
        authority_number: null,
        middle_name: null,
        marketing_source: null,
        zip_code: null,
        entity_zip_code: null,
        entity_email: null,
        interested_product_categories: null,
        selected_area_of_interest_data: null
      },
      form_fields: {},
      event: this.$route.params.event_id,
      type: this.$route.query.type,
      interested_product_categories: [],
      exception_message:"",
      full_mobile: null,
      full_phone: null,
      full_entity_phone: null
    };
  },
  methods: {
    updateSearchBoxPlaceholder(){
      this.$nextTick(() => {
        const searchBoxInputs = document.querySelectorAll('.vti__search_box');
        searchBoxInputs.forEach((input) => {
          input.setAttribute('placeholder', `Search by country name or country code`);
        });
      });
    },
    scrollToTopOnMobile(event) {
      if (window.innerWidth <= 767) {
        const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
        if (currentScrollPosition < 450) {
          event.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
          window.scrollBy(0, +450);
        }
      }
    },
    handleInterestedAreas(data) {

      let result = data.map(item => item.product_category_id);
      this.interested_product_categories = result;

    },
    async setPreSelectedValue() {
        const registration = await this.getRegistrationOrContact;
        const allFiledData = this.getFormFields;
        if (registration?.first_name) {
          if (registration?.interested_product_categories != undefined && registration?.interested_product_categories != null) {
            this.selected_area_of_interest_data = registration?.interested_product_categories;
            this.handleInterestedAreas(registration?.interested_product_categories)
          }
          if (registration?.contact_id) {
            this.contact_id = registration?.contact_id
          }
          if (registration?.role) {
          this.role = registration?.role;
        }
          if (registration?.salutation_id) {
            this.salutation_value = Number(registration?.salutation_id)
          }
          if (registration?.first_name) {
            this.first_name = registration?.first_name
          }
          if (registration?.last_name) {
            this.last_name = registration?.last_name
          }
          if (registration?.middle_name) {
            this.middle_name = registration?.middle_name
          }
          if (registration?.phone) {
            this.phone = "+" + registration?.phone
          }
          if (registration?.mobile) {
            this.mobile = "+" + registration?.mobile
          }
          if (registration?.gender) {
            this.gender = registration?.gender
          }
          if (registration?.age_group_id) {
            this.age_group = Number(registration?.age_group_id)
          }
          if (registration?.authority_number) {
            this.authority_number = registration?.authority_number
          }
          if (registration?.nationality_id) {
            this.selected_nationality = registration?.nationality_id
          }
          if (registration?.address) {
            this.address = registration?.address
          }

          if (registration?.country_id) {
            this.selected_country = Number(registration?.country_id)
            this.getStatesData();
          }
          if (registration?.state_id) {
            this.selected_state = Number(registration?.state_id)
            this.getCitiesData();
          }
          if (registration?.city_id) {
            this.selected_city = Number(registration?.city_id)
          }
          if (registration?.p_o_box) {
            this.zip_postal_code = registration?.p_o_box
          }
          if (registration?.zip_code) {
            this.zip_code = registration?.zip_code
          }

          if (registration?.industry_id) {
            this.selected_industry = registration?.industry_id
            this.getIndustryJobFunctionsData();
            this.getEditionIndustriesJobTitles();
          }
          if (registration?.industry_job_function_id) {
            this.selected_industry_job_function = Number(registration?.industry_job_function_id);
          }
          if (registration?.job_title_id) {
            this.selected_jobTitle = registration?.job_title_id;
          }
          if (registration?.other_title) {
            this.other_title = registration?.other_title
          }
          if (registration?.seniority_level_id) {
            this.selected_seniorityLevel = registration?.seniority_level_id
          }
          if (registration?.entity_industry_id) {
            this.entity_industry_id = registration?.entity_industry_id
            this.selected_companyIndustry = registration?.entity_industry_id
            this.getCompanyActivities();
          }
          if (registration?.entity_name) {
            this.entity_name = registration?.entity_name
          }
          if (registration?.entity_email) {
            this.entity_email = registration?.entity_email
          }
          if (registration?.entity_phone) {
            this.entity_phone = "+" + registration?.entity_phone
          }
          if (registration?.entity_p_o_box) {
            this.entity_p_o_box = registration?.entity_p_o_box
          }
          if (registration?.entity_fax) {
            this.entity_fax = registration?.entity_fax
          }
          if (registration?.entity_website) {
            this.entity_website = registration?.entity_website
          }
          if (registration?.entity_address) {
            this.entity_address = registration?.entity_address
          }
          if (registration?.entity_type_id) {
            this.entity_type_id = registration?.entity_type_id
          }
          if (registration?.entity_zip_code) {
            this.entity_zip_code = registration?.entity_zip_code
          }

          if (registration?.entity_country_id) {
            this.entity_country_id = Number(registration?.entity_country_id)
            this.getEntityStatesData();
          }

          if (registration?.entity_state_id) {
            this.entity_state_id = Number(registration?.entity_state_id)
            this.getEntityCitiesData();
          }
          if (registration?.entity_city_id) {
            this.entity_city_id = Number(registration?.entity_city_id)
          }

          if (registration?.internal_notes) {
            this.internal_notes = registration?.internal_notes
          }
          if (registration?.marketing_source) {
            this.marketing_source = registration?.marketing_source
          }
          if (registration?.optionalFieldTwo) {
            this.optionalFieldTwo = registration?.optionalFieldTwo
          }
          if (registration?.optionalFieldThree) {
            this.optionalFieldThree = registration?.optionalFieldThree
          }

          if (registration?.entity_business_activities != null && registration?.entity_business_activities != undefined) {
            this.entity_business_activity_id = registration?.entity_business_activities[0]?.industry_business_activity_id
          }
          if (registration?.optionalFieldOne && registration?.optionalFieldOne == 1) {
            this.optionalFieldOne = true
          }
          if (registration?.optionalFieldOne == 0) {
            this.optionalFieldOne = false
          }


        }
        else {
          if (allFiledData?.first_name?.default_value) {
            this.first_name = allFiledData?.first_name?.default_value
          }
          if (allFiledData?.salutation_id?.default_value) {
            this.salutation_value = Number(allFiledData?.salutation_id?.default_value)
          }
          if (allFiledData?.last_name?.default_value) {
            this.last_name = allFiledData?.last_name?.default_value
          }

          if (allFiledData?.middle_name?.default_value) {
            this.middle_name = allFiledData?.middle_name?.default_value
          }
          if (allFiledData?.gender?.default_value) {
            this.gender = allFiledData?.gender?.default_value
          }
          if (allFiledData?.role?.default_value) {
          this.role = allFiledData?.role?.default_value;
          }
          if (allFiledData?.phone?.default_value) {
            this.phone = "+" + allFiledData?.phone?.default_value
          }
          if (allFiledData?.mobile?.default_value) {
            this.mobile = "+" + allFiledData?.mobile?.default_value
          }
          if (allFiledData?.age_group_id?.default_value) {
            this.age_group = Number(allFiledData?.age_group_id?.default_value)
          }
          if (allFiledData?.authority_number?.default_value) {
            this.authority_number = allFiledData?.authority_number?.default_value
          }
          if (allFiledData?.nationality_id?.default_value) {
            this.selected_nationality = allFiledData?.nationality_id?.default_value
          }
          if (allFiledData?.address?.default_value) {
            this.address = allFiledData?.address?.default_value
          }
          if (allFiledData?.country_id?.default_value) {
            this.selected_country = Number(allFiledData?.country_id?.default_value)
            this.getStatesData();
          }
          if (allFiledData?.state_id?.default_value) {
            this.selected_state = Number(allFiledData?.state_id?.default_value)
            this.getCitiesData();
          }
          if (allFiledData?.city_id?.default_value) {
            this.selected_city = Number(allFiledData?.city_id?.default_value)
          }
          if (allFiledData?.p_o_box?.default_value) {
            this.zip_postal_code = allFiledData?.p_o_box?.default_value

          }
          if (allFiledData?.zip_code?.default_value) {
            this.zip_code = allFiledData?.zip_code?.default_value

          }
          if (allFiledData?.industry_id?.default_value) {
            this.selected_industry = Number(allFiledData?.industry_id?.default_value)
            this.getIndustryJobFunctionsData();
            this.getEditionIndustriesJobTitles();
            this.selected_industry_job_function = Number(allFiledData?.industry_job_function_id?.default_value);
            this.selected_jobTitle = registration?.Number(allFiledData?.job_title_id?.default_value);
          }


          if (allFiledData?.seniority_level_id?.default_value) {
            this.selected_seniorityLevel = allFiledData?.seniority_level_id?.default_value

          }
          if (allFiledData?.entity_industry_id?.default_value) {
            this.selected_companyIndustry = Number(allFiledData?.entity_industry_id?.default_value)
            this.getCompanyActivities();
            this.entity_business_activity_id = allFiledData?.entity_business_activity_id?.default_value
          }
          if (allFiledData?.entity_name?.default_value) {
            this.entity_name = allFiledData?.entity_name?.default_value

          }
          if (allFiledData?.entity_email?.default_value) {
            this.entity_email = allFiledData?.entity_email?.default_value

          }
          if (allFiledData?.entity_phone?.default_value) {
            this.entity_phone = "+" + allFiledData?.entity_phone?.default_value

          }
          if (allFiledData?.entity_p_o_box?.default_value) {
            this.entity_p_o_box = allFiledData?.entity_p_o_box?.default_value

          }
          if (allFiledData?.entity_fax?.default_value) {
            this.entity_fax = allFiledData?.entity_fax?.default_value
          }
          if (allFiledData?.entity_website?.default_value) {
            this.entity_website = allFiledData?.entity_website?.default_value
          }
          if (allFiledData?.entity_address?.default_value) {
            this.entity_address = allFiledData?.entity_address?.default_value
          }
          if (allFiledData?.entity_type_id?.default_value) {
            this.entity_type_id = Number(allFiledData?.entity_type_id?.default_value)
          }
          if (allFiledData?.entity_zip_code?.default_value) {
            this.entity_zip_code = allFiledData?.entity_zip_code?.default_value
          }
          if (allFiledData?.entity_country_id?.default_value) {
            this.entity_country_id = Number(allFiledData?.entity_country_id?.default_value)
            this.getEntityStatesData();
          }
          if (allFiledData?.entity_state_id?.default_value) {
            this.entity_state_id = Number(allFiledData?.entity_state_id?.default_value)
            this.getEntityCitiesData();
          }
          if (allFiledData?.entity_city_id?.default_value) {
            this.entity_city_id = allFiledData?.entity_city_id?.default_value
          }


          if (allFiledData?.internal_notes?.default_value) {
            this.internal_notes = allFiledData?.internal_notes?.default_value
          }

          if (allFiledData?.optionalFieldOne?.default_value && allFiledData?.optionalFieldOne?.default_value == 1) {
            this.optionalFieldOne = true
          }
          if (allFiledData?.optionalFieldOne?.default_value == 0) {
            this.optionalFieldOne = false
          }
          if (allFiledData?.optionalFieldTwo?.default_value) {
            this.optionalFieldTwo = allFiledData?.optionalFieldTwo?.default_value
          }
          if (allFiledData?.optionalFieldThree?.default_value) {
            this.optionalFieldThree = allFiledData?.optionalFieldThree?.default_value
          }
        }
        this.dynamicKey++;

    },
    onMobileCountryChanged(country, phoneObject) {
      if(phoneObject.nationalNumber){
        this.mobile=phoneObject.nationalNumber
      }
      if (phoneObject.valid) {
        this.errors.mobile = ""
        this.full_mobile = phoneObject
        // this.mobile_code = country.countryCallingCode.replace('+', '');
      } else if (this.mobile && !phoneObject.valid) {
        this.errors.mobile = "Enter valid Mobile number"
        this.full_mobile = null
        this.mobile_code = null;
      }

    },
    onPhoneCountryChanged(country, phoneObject) {
      if(phoneObject.nationalNumber){
        this.phone=phoneObject.nationalNumber
      }
      if (phoneObject.valid) {
        this.errors.phone = ""
        this.full_phone = phoneObject
        // this.phone_code = country.countryCallingCode.replace('+', '');
      } else if (this.phone && !phoneObject.valid) {
        this.full_phone = null
        this.phone_code = null;
        this.errors.phone = "Enter valid Phone number"
      }

    },
    onEntityPhoneCountryChanged(country, phoneObject) {
      if(phoneObject.nationalNumber){
        this.entity_phone=phoneObject.nationalNumber
      }
      if (phoneObject.valid) {
        this.errors.entity_phone = ""
        this.full_entity_phone = phoneObject
        // this.phone_code = country.countryCallingCode.replace('+', '');
      } else if (this.entity_phone && !phoneObject.valid) {
        this.full_entity_phone = null
        this.phone_code = null;
        this.errors.entity_phone = "Enter valid Phone number"
      }
    },
    categoryTabValidation() {
      let flag = true;
      let postData = {
        encrypted_registration_id: this.registration_data?.encrypted_registration_id,
        email: this.registration_data.email,
        event: this.$route.params.event_id
      };

      if (this.internal_notes === null && this.getFormFields?.internal_notes?.required) {
        flag = false;
        this.errors.internal_notes = "Internal notes field required.";
      } else {
        postData.internal_notes = this.internal_notes;
        this.errors.internal_notes = null;
      }

      if (this.authority_number === null && this.getFormFields?.authority_number?.required) {
        flag = false;
        this.errors.authority_number = "Authority number field required.";
      }
      else {
        postData.authority_number = this.authority_number;
        this.errors.authority_number = null;
      }
      if (this.role === null && this.getFormFields?.role?.required) {
        flag = false;
        this.errors.role = "Role field required.";
      } else {
        this.errors.role = null;
      }

      if (this.zip_code === null && this.getFormFields?.zip_code?.required) {
        flag = false;
        this.errors.zip_code = "Zip code field required.";
      }

      else {
        postData.zip_code = this.zip_code;
        this.errors.zip_code = null;
      }

      if (this.entity_country_id === null && this.getFormFields?.entity_country_id?.required) {
        flag = false;
        this.errors.entity_country_id = "Entity country field required.";
      } else {
        postData.entity_country_id = this.entity_country_id;
        this.errors.entity_country_id = null;
      }

      if (this.entity_state_id === null && this.getFormFields?.entity_state_id?.required) {
        flag = false;
        this.errors.entity_state_id = "Entity state field required.";
      } else {
        postData.entity_state_id = Number(this.entity_state_id);
        this.errors.entity_state_id = null;
      }
      if (this.entity_city_id === null && this.getFormFields?.entity_city_id?.required) {
        flag = false;
        this.errors.entity_city_id = "Entity city field required.";
      } else {
        postData.entity_city_id = this.entity_city_id;
        this.errors.entity_city_id = null;
      }

      if (this.entity_email === null && this.getFormFields?.entity_email?.required) {
        flag = false;
        this.errors.entity_email = "Entity email field required.";
      } else if (this.entity_email != null && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.entity_email)) {
        flag = false;
        this.errors.entity_email = "Invalid Entity email." + this.entity_email;
        this.entity_email = null;
      }
      else {
        postData.entity_email = this.entity_email;
        this.errors.entity_email = null;
      }

      if (!this.entity_phone && this.getFormFields?.entity_phone?.required) {
        flag = false;
        this.errors.entity_phone = "Entity phone field required.";
      } else if (!this.full_entity_phone?.valid && this.getFormFields?.entity_phone?.required) {
        flag = false;
        this.errors.entity_phone = "Enter valid Phone number.";
      }
      else {
        postData.entity_phone = this.entity_phone;
        this.errors.entity_phone = null;
      }

      if (this.entity_fax === null && this.getFormFields?.entity_fax?.required) {
        flag = false;
        this.errors.entity_fax = "Entity fax field required.";
      } else if (this.entity_fax != null && !parseInt(this.entity_fax)) {
        flag = false;
        this.errors.entity_fax = "Invalid Zip code. " + this.entity_fax
        this.entity_fax = null;
      }
      else {
        postData.entity_fax = this.entity_fax;
        this.errors.entity_fax = null;
      }

      if (this.entity_p_o_box === null && this.getFormFields?.entity_p_o_box?.required) {
        flag = false;
        this.errors.entity_p_o_box = "Entity P.O box field required.";
      } else if (this.entity_p_o_box != null && !parseInt(this.entity_p_o_box)) {
        flag = false;
        this.errors.entity_p_o_box = "Entity P.O box " + this.entity_p_o_box
        this.entity_p_o_box = null;
      }
      else {
        postData.entity_p_o_box = this.entity_p_o_box;
        this.errors.entity_p_o_box = null;
      }

      if (this.entity_type_id === null && this.getFormFields?.entity_type_id?.required) {
        flag = false;
        this.errors.entity_type_id = "Entity type id field required.";
      } else {
        postData.entity_type_id = Number(this.entity_type_id);
        this.errors.entity_type_id = null;
      }
      if (this.salutation_value === null && this.getFormFields?.salutation_id?.required) {
        flag = false;
        this.errors.salutation_value = "Title field required.";
      } else {
        postData.salutation_id = Number(this.salutation_value);
        this.errors.salutation_value = null;
      }
      if (this.getFormFields.first_name?.required && !this.first_name) {
        flag = false;
        this.errors.first_name = "First name field required.";
      } else {
        postData.first_name = this.first_name;
        this.errors.first_name = null;
      }
      if (this.getFormFields?.middle_name?.required && !this.middle_name) {
        flag = false;
        this.errors.middle_name = "Middle name field required.";
      } else {
        postData.middle_name = this.middle_name;
        this.errors.middle_name = null;
      }
      if (this.getFormFields?.marketing_source?.required && this.marketing_source === null) {
        flag = false;
        this.errors.marketing_source = "Marketing source field required.";
      } else {
        postData.marketing_source = this.marketing_source;
        this.errors.marketing_source = null;
      }
      if (!this.last_name && this.getFormFields.last_name?.required) {
        flag = false;
        this.errors.last_name = "Last name field required.";
      } else {
        postData.last_name = this.last_name;
        this.errors.last_name = null;
      }
      if (!this.phone && this.getFormFields?.phone?.required) {
        flag = false;
        this.errors.phone = "Phone field required.";
      } else if (!this.full_phone?.valid && this.getFormFields?.phone?.required) {
        flag = false;
        this.errors.phone = "Enter valid Phone number.";
      }
      else {
        postData.phone = this.phone;
        this.errors.phone = null;
      }
      if (this.entity_address === null && this.getFormFields?.entity_address?.required) {
        flag = false;
        this.errors.entity_address = "Entity address field required.";
      } else {
        postData.entity_address = this.entity_address;
        this.errors.entity_address = null;
      }
      if (!this.mobile && this.getFormFields?.mobile?.required) {
        flag = false;
        this.errors.mobile = "Mobile field required.";
      } else if (!this.full_mobile?.valid && this.getFormFields?.mobile?.required) {
        flag = false;
        this.errors.mobile = "Enter valid Mobile number.";
      }
      else {
        postData.mobile = this.mobile;
        this.errors.mobile = null;
      }
      if (this.age_group === null && this.getFormFields?.age_group_id?.required) {
        flag = false;
        this.errors.age_group = "Age group field required.";
      } else {
        postData.age_group = Number(this.age_group);
        this.errors.age_group = null;
      }
      if (this.selected_nationality === null && this.getFormFields?.nationality_id?.required) {
        flag = false;
        this.errors.selected_nationality = "Nationality field required.";
      } else {
        postData.nationality_id = Number(this.selected_nationality);
        this.errors.selected_nationality = null;
      }
      if (this.is_buyer === null && this.getFormFields?.are_you_a_buyer?.required) {
        flag = false;
        this.errors.is_buyer = "Buyer field required.";
      } else {
        postData.are_you_a_buyer = this.is_buyer;
        this.errors.is_buyer = null;
      }
      if (this.address === null && this.getFormFields?.address?.required) {
        flag = false;
        this.errors.address = "Address field required.";
      } else {
        postData.address = this.address;
        this.errors.address = null;
      }
      if (this.selected_country === null && this.getFormFields?.country_id?.required) {
        flag = false;
        this.errors.selected_country = "Country field required.";
      } else {
        postData.country_id = Number(this.selected_country);
        this.errors.selected_country = null;
      }
      if (this.selected_state === null && this.getFormFields?.state_id?.required) {
        flag = false;
        this.errors.selected_state = "State field required.";
      } else {
        postData.state_id = Number(this.selected_state);
        this.errors.selected_state = null;
      }
      if (this.selected_city === null && this.getFormFields?.city_id?.required) {
        flag = false;
        this.errors.selected_city = "City field required.";
      } else {
        postData.city_id = Number(this.selected_city);
        this.errors.selected_city = null;
      }
      if (this.zip_postal_code === null && this.getFormFields?.p_o_box?.required) {
        flag = false;
        this.errors.zip_postal_code = "Zip postal code field required.";
      } 

      else {
        postData.p_o_box = this.zip_postal_code;
        this.errors.zip_postal_code = null;
      }
      if (this.selected_industry === null && this.getFormFields?.industry_id?.required) {
        flag = false;
        this.errors.selected_industry = "Industry field required.";
      } else {
        postData.industry_id = this.selected_industry;
        this.errors.selected_industry = null;
      }
      if (this.selected_industry_job_function === null && this.getFormFields?.industry_job_function_id?.required) {
        flag = false;
        this.errors.selected_industry_job_function = "Industry job function field required.";
      } else {
        postData.industry_job_function_id = Number(this.selected_industry_job_function);
        this.errors.selected_industry_job_function = null;
      }
      if (this.selected_jobTitle === null && this.getFormFields?.job_title_id?.required) {
        flag = false;
        this.errors.selected_jobTitle = "Job title field required.";
      } else {
        postData.job_title_id = Number(this.selected_jobTitle);
        this.errors.selected_jobTitle = null;
      }
      if (!this.other_title && this.selected_jobTitle == 23) {
        flag = false;
        this.errors.other_title = "Other field required.";
        this.otherTitleKey++
      } else {
        this.errors.other_title = null;
        this.otherTitleKey++
      }
      if (this.entity_name === null && this.getFormFields?.entity_name?.required) {
        flag = false;
        this.errors.entity_name = "Entity name field required.";
      } else {
        postData.entity_name = this.entity_name;
        this.errors.entity_name = null;
      }
      if (this.entity_zip_code === null && this.getFormFields?.entity_zip_code?.required) {
        flag = false;
        this.errors.entity_zip_code = "Entity zip code field required.";
      }

      else {
        postData.entity_zip_code = this.entity_zip_code;
        this.errors.entity_zip_code = null;
      }
      if (this.selected_companyIndustry === null && this.getFormFields?.entity_industry_id?.required) {
        flag = false;
        this.errors.selected_companyIndustry = "Entity industry field required.";
      } else {
        postData.entity_industry_id = this.selected_companyIndustry;
        this.errors.selected_companyIndustry = null;
      }
      if (this.selected_seniorityLevel === null && this.getFormFields?.seniority_level_id?.required) {
        flag = false;
        this.errors.selected_seniorityLevel = "Seniority level field required.";
      } else {
        postData.seniority_level_id = this.selected_seniorityLevel;
        this.errors.selected_seniorityLevel = null;
      }

      if (this.optionalFieldOne === null && this.getFormFields?.optionalFieldOne?.required) {
        flag = false;
        this.errors.optionalFieldOne = "Optional field one field required.";
      } else {
        postData.optionalFieldOne = this.optionalFieldOne;
        this.errors.optionalFieldOne = null;
      }


      if (this.optionalFieldTwo === null && this.getFormFields?.optionalFieldTwo?.required) {
        flag = false;
        this.errors.optionalFieldTwo = "Optional field two field required.";
      } else {
        postData.optionalFieldTwo = this.optionalFieldTwo;
        this.errors.optionalFieldTwo = null;
      }

      if (this.optionalFieldThree === null && this.getFormFields?.optionalFieldThree?.required) {
        flag = false;
        this.errors.optionalFieldThree = "Optional field three field required.";
      } else {
        postData.optionalFieldThree = this.optionalFieldThree;
        this.errors.optionalFieldThree = null;
      }

      if (this.gender === null && this.getFormFields?.gender?.required) {
        flag = false;
        this.errors.gender = "Gender field required.";
      } else {
        postData.gender = this.gender;
        this.errors.gender = null;
      }

      if (this.getFormFields?.entity_business_activity_id?.required && this.entity_business_activity_id === null) {
        flag = false;
        this.errors.entity_business_activity_id = "Entity business activity field required.";
      } else {
        postData.entity_business_activity_id = this.entity_business_activity_id;
        this.errors.entity_business_activity_id = null;
      }
        if(this.entity_website){
          if(this.entity_website=='www.'){
            flag = false;
            this.errors.entity_website = "Please enter a valid domain";
            this.entity_website='';
          }
          this.entity_website = this.entity_website.replace(/^(https?:\/\/)?(www\.)?/, '');
          if(!this.entity_website){
            flag = false;
            this.errors.entity_website = "Please enter a valid domain";
            this.entity_website='';
          }
        }

        if (this.entity_website && (this.entity_website.indexOf('.') === -1 || this.entity_website.endsWith('.') || this.entity_website.startsWith('.'))) {
            flag = false;
            this.errors.entity_website = 'Please enter a valid domain with a period (.com etc)';
            this.entity_website = null;
          }

          if (this.entity_website && /[`!@$^*()\\|,<>~]/.test(this.entity_website)) {
          flag = false;
          this.errors.entity_website = 'No special characters are allowed';
          this.entity_website = null;
        }

      if (this.entity_website==null && this.getFormFields?.entity_website?.required) {
        flag = false;
        this.errors.entity_website = "Entity website field required.";
      }
      
      if (this.entity_website && !/^https?:\/\//.test(this.entity_website)) {
          this.entity_website = 'https://' + this.entity_website;
      }

     
      if (this.interested_product_categories.length == 0 && this.getFormFields?.interested_product_categories?.required) {
        flag = false;
        this.errors.interested_product_categories = "Areas of interest field required.";
      }


      if (flag === true) {
        Object.keys(this.errors).reduce((acc, key) => {
          acc[key] = null;
          return acc;
        }, {});
      }
      return { flag: flag, data: postData };
    },
    tabIndexingHandler: function () {

      this.exception_message = ''
      let btn2 = document.getElementById('tab2___BV_tab_button__');
      btn2 = btn2.parentNode;
      btn2.scrollIntoView({
        block: 'center',
        // inline: 'centre'
      });
      const validation_error = this.categoryTabValidation();
      const post_data = validation_error.data;

          if (this.mobile) {
            post_data['mobile'] = this.full_mobile?.number ? this.full_mobile?.number.replace("+", "") : this.mobile.replace("+", "");
          }else{
            post_data['mobile']='';
          }
          if (this.phone) {
            post_data['phone'] = this.full_phone?.number ? this.full_phone?.number.replace("+", "") : this.phone.replace("+", "");
          }else{
            post_data['phone']='';
          }
          if (this.entity_phone) {
            post_data['entity_phone'] = this.full_entity_phone?.number ? this.full_entity_phone?.number.replace("+", "") : this.entity_phone.replace("+", "");
          }else{
            post_data['entity_phone']='';
          }
      // }
      post_data['other_title'] = this.other_title;
      post_data['interested_product_categories'] = this.interested_product_categories;
      if (validation_error.flag === false) {
        return;
      } else {
        this.$apollo.mutate({
          mutation: gql`
            mutation update_registration(
                       $encrypted_registration_id: String!,
                       $email: String!,
                       $other_title:String
                       $salutation_id: Int,
                       $age_group: Int,
                       $first_name: String,
                       $middle_name: String,
                       $last_name: String,
                       $nationality_id: Int,
                       $are_you_a_buyer: Boolean,
                       $phone: String,
                       $mobile: String,
                       $entity_name: String,
                       $address: String,
                       $p_o_box: String,
                       $country_id: Int,
                       $state_id: Int,
                       $city_id: Int,
                       $industry_id: String,
                       $industry_job_function_id: Int,
                       $job_title_id: Int,
                       $seniority_level_id: String,
                       $entity_industry_id: String,
                       $entity_business_activity_id: String,
                       $event: String,
                       $interested_product_categories: [Int],
                       $gender: String,
                       $authority_number: String,
                       $step: Int,
                       $marketing_source: String,
                       $other_source: String,
                       $zip_code: String
                       $entity_address: String
                       $entity_type_id: Int
                       $entity_country_id: Int
                       $entity_state_id: Int
                       $entity_city_id: Int
                       $entity_zip_code : String
                       $entity_website: String
                       $entity_email : String
                       $entity_p_o_box: String
                       $entity_phone: String
                       $entity_fax: String
                       $internal_notes:String
                       $optionalFieldThree:String
                       $optionalFieldTwo:String
                       $optionalFieldOne:Boolean
                       $contact_id:Int
                       $role: String
            ){
                update_registration(
                       encrypted_registration_id: $encrypted_registration_id,
                       email: $email,
                       salutation_id: $salutation_id,
                       age_group: $age_group,
                       first_name: $first_name,
                       middle_name: $middle_name,
                       last_name: $last_name,
                       nationality_id: $nationality_id,
                       are_you_a_buyer: $are_you_a_buyer,
                       phone: $phone,
                       mobile: $mobile,
                       entity_name: $entity_name,
                       address: $address,
                       p_o_box: $p_o_box,
                       country_id: $country_id,
                       state_id: $state_id,
                       city_id: $city_id,
                       industry_id: $industry_id,
                       industry_job_function_id: $industry_job_function_id,
                       job_title_id: $job_title_id,
                       seniority_level_id: $seniority_level_id,
                       entity_industry_id: $entity_industry_id,
                       entity_business_activity_id: $entity_business_activity_id,
                       event: $event,
                       interested_product_categories: $interested_product_categories,
                       gender: $gender,
                       authority_number: $authority_number
                       step: $step
                       other_source: $other_source
                       marketing_source: $marketing_source
                       zip_code: $zip_code
                       entity_address: $entity_address
                       entity_type_id: $entity_type_id
                       entity_country_id: $entity_country_id
                       entity_state_id: $entity_state_id
                       entity_city_id: $entity_city_id
                       entity_zip_code: $entity_zip_code
                       entity_website: $entity_website
                       entity_email: $entity_email
                       entity_p_o_box: $entity_p_o_box
                       entity_phone: $entity_phone
                       entity_fax: $entity_fax
                       internal_notes:$internal_notes
                       optionalFieldThree:$optionalFieldThree
                       optionalFieldTwo:$optionalFieldTwo
                       optionalFieldOne:$optionalFieldOne
                       other_title:$other_title
                       contact_id:$contact_id
                       role: $role
                )
            }
        `,
          variables: {
            encrypted_registration_id: post_data.encrypted_registration_id,
            email: post_data.email,
            role: this.role,
            contact_id:this.contact_id,
            event: post_data.event,
            salutation_id: post_data.salutation_id ? Number(post_data.salutation_id) : null,
            age_group: post_data.age_group ? Number(post_data.age_group) : null,
            first_name: post_data.first_name,
            last_name: post_data.last_name,
            nationality_id: post_data.nationality_id ? Number(post_data.nationality_id) : null,
            are_you_a_buyer: post_data.are_you_a_buyer,
            phone: post_data.phone,
            mobile: post_data.mobile,
            entity_name: post_data.entity_name,
            address: post_data.address,
            p_o_box: post_data.p_o_box,
            country_id: post_data.country_id ? post_data.country_id : null,
            state_id: this.selected_state ? Number(this.selected_state) : null,
            city_id: this.selected_city ? Number(this.selected_city) : null,
            industry_id: post_data.industry_id?.toString(),
            industry_job_function_id: post_data.industry_job_function_id ? Number(post_data.industry_job_function_id) : null,
            job_title_id: post_data.job_title_id ? Number(post_data.job_title_id) : null,
            seniority_level_id: post_data.seniority_level_id?.toString(),
            entity_industry_id: post_data.entity_industry_id?.toString(),
            entity_business_activity_id: post_data.entity_business_activity_id?.toString(),
            interested_product_categories: this.interested_product_categories,
            gender: post_data.gender,
            authority_number: post_data.authority_number,
            step: 1,
            middle_name: post_data.middle_name,
            marketing_source: post_data.marketing_source,
            other_source: this.other_source,
            zip_code: post_data.zip_code,
            entity_address: post_data.entity_address,
            entity_type_id: post_data.entity_type_id ? Number(post_data.entity_type_id) : null,
            entity_country_id: this.entity_country_id ? Number(this.entity_country_id) : null,
            entity_state_id: this.entity_state_id ? Number(this.entity_state_id) : null,
            entity_city_id: this.entity_city_id ? Number(this.entity_city_id) : null,
            entity_zip_code: post_data.entity_zip_code,
            entity_website: this.entity_website,
            entity_email: post_data.entity_email,
            entity_p_o_box: post_data.entity_p_o_box,
            entity_phone: post_data.entity_phone,
            entity_fax: post_data.entity_fax,
            internal_notes: post_data.internal_notes,
            optionalFieldThree: post_data.optionalFieldThree,
            optionalFieldTwo: post_data.optionalFieldTwo,
            optionalFieldOne: post_data.optionalFieldOne,
            other_title: post_data.other_title
          }
        }).then((data) => {
          const res = JSON.parse(data.data.update_registration)
          if (res.code === 200) {
            this.errors.entity_website='';
            this.dynamicKey++;
            this.$scrollToTop();
            return this.$emit("getIndex", this.tabIndexing = 1);
          }
        }).catch(() => {
          this.exception_message = 'Something went wrong.<br> Please contact ' + this.editionManagerName + ' on ' + this.editionManagerPhone + ' for assistance.'
          this.$scrollToTop()
        })
      }

    },
    setValidationFieldNull(original_value, field_value) {
      if (field_value !== null) {
        this.errors[original_value] = null;
      }
    },
    getStatesData() {
      this.$apollo.query({
        query: gql`
            query get_states($status: Int!, $country_id: Int!){
                get_states(status: $status, country_id: $country_id){
                    state_id,
                    state
                },

            }`,
        variables: {
          status: 1,
          country_id: Number(this.selected_country)
        },
      }).then((data) => {
        this.states_data = data.data.get_states;
      }).catch((error) => {
        console.error(error);
      });
    },
    getEntityStatesData() {
      this.$apollo.query({
        query: gql`
            query get_states($status: Int!, $country_id: Int!){
                get_states(status: $status, country_id: $country_id){
                    state_id,
                    state
                },

            }`,
        variables: {
          status: 1,
          country_id: this.entity_country_id
        },
      }).then((data) => {
        this.get_entity_states = data.data.get_states;
      }).catch((error) => {
        console.error(error);
      });
    },
    getEntityCitiesData() {
      console.log("entity city id::::", this.entity_state_id);
      const selected_state = Number(this.entity_state_id);
      this.$apollo.query({
        query: gql`
            query get_cities($status: Int!, $state_id: Int!){
                get_cities(status: $status, state_id: $state_id){
                    city_id,
                    name
                },

            }`,
        variables: {
          status: 1,
          state_id: selected_state
        },
      }).then((data) => {
        this.get_entity_cities = data.data.get_cities;
      }).catch((error) => {
        console.error(error);
      });
    },
    getCitiesData() {
      const selected_state = Number(this.selected_state);
      this.$apollo.query({
        query: gql`
            query get_cities($status: Int!, $state_id: Int!){
                get_cities(status: $status, state_id: $state_id){
                    city_id,
                    name
                },

            }`,
        variables: {
          status: 1,
          state_id: selected_state
        },
      }).then((data) => {
        this.cities_data = data.data.get_cities;
      }).catch((error) => {
        console.error(error);
      });
    },
    getIndustryJobFunctionsData() {
      const industry_id = this.selected_industry
      this.$apollo.query({
        query: gql`
            query get_industry_job_functions($industry_id: Int!){
                get_industry_job_functions(industry_id: $industry_id){
                    job_function_id,
                    job_function
                },

            }`,
        variables: {
          industry_id: industry_id
        },
      }).then((data) => {
        this.industry_job_functions_data = data.data.get_industry_job_functions;
      }).catch((error) => {
        console.log(error);
      });
    },
    getEditionIndustriesJobTitles() {
      const industry_id = this.selected_industry
      this.$apollo.query({
        query: gql`
            query get_industry_job_titles($industry_id: Int!){
                get_industry_job_titles(industry_id: $industry_id){
                    job_title_id,
                    name
                },

            }`,
        variables: {
          industry_id: industry_id
        },
      }).then((data) => {
        this.industry_job_titles_data = data.data.get_industry_job_titles;
      }).catch((error) => {
        console.error(error);
      });
    },
    getCompanyActivities () {
      const industry_id = this.selected_companyIndustry
      this.$apollo.query({
        query: gql`
      query get_business_activities($industry_id: Int!, $orderBy: [OrderByClause!]){
        get_business_activities(industry_id: $industry_id, orderBy: $orderBy) {
          industry_business_activity_id,
          name
        }
      }`,
        variables: {
          industry_id: industry_id,
          orderBy: [
            {
              column: 'name',
              order: 'ASC'
            }
          ]
        },
      }).then((data) => {
        this.company_activities = data.data.get_business_activities
      }).catch((error) => {
        console.error(error)
      })
    },
    getTitles(salutations) {
      let $vm = this;
      let salutaion_placeholder = $vm.$store.state.edition_details.fields?.salutation_id?.placeholder;
      salutations.forEach((item) => {
        this.titles[0].text = salutaion_placeholder
        this.titles[0].selected = true
        this.titles.push({
          value: item.salutation_id,
          text: item.salutation
        }
        );
      });
    }
  },
  watch: {
    form_fields() {
      this.form_fields = this.$store.getters.getFormFields;
    },
    getRegistrationOrContact(){
      this.setPreSelectedValue();
    },
  },
  computed: {
    editionIndustries() {
      return this.$store.getters.getEditionIndustries
    },
    getSeniorityLevels() {
      return this.$store.getters.getSeniorityLevels;
    },
    getCategoriesData() {
      return this.$store.getters.getCategories;
    },
    getFormFields() {
      return this.$store.getters.getFormFields
    },
    getProductCategoryData() {
      return this.$store.getters.getProductCategory;
    },
    getRegistrationOrContact() {
      return this.$store.getters.getRegistrationOrContact;

    },
    editionManagerName() {
      return this.$store.state.edition_details?.edition_data?.edition_manager_name ?? '';
    },
    editionManagerPhone() {
      return this.$store.state.edition_details?.edition_data?.edition_manager_phone ?? '';
    }

  },
}
</script>

<style lang="scss">
.country-selector__input {
  border: 1px solid #ccc !important;
}

.country-selector__label,
.input-tel__label {
  top: 0 !important;
  line-height: 2 !important;
  color: #495057 !important;
}

.input-tel.is-valid.has-value {
  input {
    border-color: #ced4da !important;
  }
}

.country-selector__list {
  z-index: 999 !important;
}

.country-selector.is-focused {
  z-index: 9999;
}

.bg-secondary-color {
  background-color: rgb(var(--bs-secondary)) !important;
}
.bg-priamry-color {
  background-color: rgba(var(--bs-primary),0.8) !important;
}

.iconSetting {
  font-size: 15px !important;
  color: black !important;
}

.vue-tel-input {
  &:focus-within {
    border-color: rgba(var(--bs-primary),0.5) !important;
        box-shadow: none !important;
  }
  .vti{
    &__input {
      &:focus {
        border-color: rgba(var(--bs-primary),0.5) !important;
        box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary),.25) !important;
      }
    }
    &__search_box {
      font-size: 14px;
      border: 0;
      padding-block: 4px;
      outline: none;
      &:focus {
        outline: none;
        border: 0 !important;
        box-shadow: none !important;
      }
    }
    &__flag-wrapper {
      margin-right: 7px;
 
    }
    &__dropdown {
      &-list {
        min-width: max-content;
        z-index: 2;
      }
      &-item {
        padding-inline: 7px;
        font-size: 14px;
 
        strong {
          font-weight: normal;
          // display: none;
        }
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
