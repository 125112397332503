<template>
  <div class="container-fluid feedbackpopup">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class="feedback-body">
            <div class="_head">
              <h4>
                Happiness Meter <br>
                <span>Tell Us How We are Doing!</span></h4>
              <p>
                Your registration experience matters to us. We'd love to hear how it went on our Happiness Meter. It's quick, easy, and helps us build a smoother journey for everyone. Click the smiley and let us know!
              </p>
              <!-- <button class="closeBtn" @click="$emit('hideFeedbackArea')">
                X
              </button> -->
            </div>
            <div class="_body">
              <ul class="reactions">
                <li
                  v-for="(item, index) in reactions"
                  :key="index"
                  @click="toggleActive(index)"
                  :class="{ active: item.active }"
                >
                  <div v-html="item.icon" class="icon"></div>
                  <span>
                    {{ item.name }}
                  </span>
                </li>
              </ul>
              <span v-if="errorReaction" style="color: red"
                >Reaction is required</span
              >

              <div class="_form">
                <label for="feedback"
                  >Do you have any thoughts you'd like to share?</label
                >
                <textarea required v-model="thoughts"></textarea>
                <button :disabled="isLoading" class="btn btn-primary btn-lg" @click="saveFeedback">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const BadIcon = `<svg xmlns=http://www.w3.org/2000/svg width=27 height=27 viewBox="0 0 27 27"><path id=Sad d=M15.5,29A13.5,13.5,0,1,1,29,15.5,13.515,13.515,0,0,1,15.5,29Zm0-25.312A11.813,11.813,0,1,0,27.313,15.5,11.826,11.826,0,0,0,15.5,3.688Zm6.284,17.63a.844.844,0,0,0,.377-1.132A7.508,7.508,0,0,0,15.5,16.344a7.509,7.509,0,0,0-6.661,3.841.844.844,0,0,0,1.507.759A5.825,5.825,0,0,1,15.5,18.031a5.823,5.823,0,0,1,5.151,2.909.845.845,0,0,0,1.132.377Zm-2.065-7.5a1.688,1.688,0,1,1,1.688-1.687A1.689,1.689,0,0,1,19.719,13.813Zm-8.437,0a1.688,1.688,0,1,1,1.687-1.687A1.689,1.689,0,0,1,11.281,13.813Z transform="translate(-2 -2)" fill="" class="svg-color" opacity=0.9 /></svg>`;

const GoodIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
  <path id="Neutral" d="M15.5,29A13.5,13.5,0,1,1,29,15.5,13.515,13.515,0,0,1,15.5,29Zm0-25.312A11.813,11.813,0,1,0,27.313,15.5,11.826,11.826,0,0,0,15.5,3.688Zm4.219,10.125a1.688,1.688,0,1,1,1.688-1.687A1.689,1.689,0,0,1,19.719,13.813Zm-8.437,0a1.688,1.688,0,1,1,1.688-1.687A1.689,1.689,0,0,1,11.281,13.813Zm10.125,5.906a.844.844,0,0,0-.844-.844H10.438a.844.844,0,0,0,0,1.688H20.563A.844.844,0,0,0,21.406,19.719Z" transform="translate(-2 -2)" fill="" class="svg-color"/>
</svg>`;

const AmazingIcon = `<svg id=happy xmlns=http://www.w3.org/2000/svg width=27 height=27 viewBox="0 0 27 27">
<g id=Group_2511 data-name="Group 2511">
<g id=Group_2510 data-name="Group 2510">
<path id=Path_4741 data-name="Path 4741" d=M13.5,0A13.5,13.5,0,1,0,27,13.5,13.5,13.5,0,0,0,13.5,0Zm0,25.313A11.813,11.813,0,1,1,25.313,13.5,11.812,11.812,0,0,1,13.5,25.313Z fill="" class="svg-color" />
</g>
</g>
<g id=Group_2513 data-name="Group 2513" transform="translate(7.23 7.173)">
<g id=Group_2512 data-name="Group 2512" transform="translate(0 0)">
<circle id=Ellipse_144 data-name="Ellipse 144" cx=1.808 cy=1.808 r=1.808 fill="" class="svg-color" />
</g>
</g>
<g id=Group_2515 data-name="Group 2515" transform="translate(16.27 7.173)">
<g id=Group_2514 data-name="Group 2514" transform="translate(0 0)">
<ellipse id=Ellipse_145 data-name="Ellipse 145" cx=1.356 cy=1.808 rx=1.356 ry=1.808 fill="" class="svg-color" />
</g>
</g>
<g id=Group_2517 data-name="Group 2517" transform="translate(5.906 13.5)">
<g id=Group_2516 data-name="Group 2516">
<path id=Path_4742 data-name="Path 4742" d=M125.5,256a5.906,5.906,0,0,1-11.812,0H112a7.594,7.594,0,0,0,15.188,0Z transform="translate(-112 -256)" fill="" class="svg-color" />
</g>
</g>
</svg>`;



import gql from "graphql-tag";

export default {
  name: "NewDesign",
  components: {},
  data() {
    return {
      BadIcon: BadIcon,
      GoodIcon: GoodIcon,
      AmazingIcon: AmazingIcon,
      reactionIndex: null,
      errorReaction: false,
      thoughts: "",
      isLoading: false,
      reactions: [
        {
          name: "Happy",
          icon: AmazingIcon,
          active: false,
        },
        {
          name: "Neutral",
          icon: GoodIcon,
          active: false,
        },
        {
          name: "Not Happy",
          icon: BadIcon,
          active: false,
        },
      ],
    };
  },
  computed: {
    getRegistrationData() {
      return this.$store.getters.getRegistrationResponse;
    },
  },
  methods: {
    toggleActive(index) {
      // Deactivate all reactions
      this.reactions.forEach((reaction, i) => {
        this.$set(this.reactions, i, {
          ...reaction,
          active: i === index && !reaction.active,
        });
      });
      this.reactionIndex = index + 1;
    },
    saveFeedback() {
      this.errorReaction = false;
      if (this.reactionIndex == null) {
        this.errorReaction = true;
        return
      }
      this.isLoading = true
      let appendedThoughts = this.thoughts
      appendedThoughts += " || \n " + navigator.userAgent + " || \n " + window.location.origin + window.location.pathname

      this.$apollo
        .mutate({
          mutation: gql`
            mutation create_app_support(
              $registration_id: Int
              $event_id: Int
              $edition_id: Int
              $email: String
              $message: String
              $feedback: Int
              $created_by: Int
              $type: String
              $status: Int
              $from: String
            ) {
              create_app_support(
                registration_id: $registration_id
                event_id: $event_id
                edition_id: $edition_id
                email: $email
                message: $message
                feedback: $feedback
                created_by: $created_by
                type: $type
                status: $status
                from: $from
              ) {
                registration_id
                event_id
                edition_id
                email
                message
              }
            }
          `,
          variables: {
            registration_id: this.getRegistrationData?.contact.registration_id,
            event_id: this.$store.state.edition_details?.url_details?.event_id,
            edition_id:
              this.$store.state.edition_details?.url_details?.edition_id,
            email: this.getRegistrationData?.contact.email,
            message: appendedThoughts,
            feedback: this.reactionIndex,
            created_by: 4003,
            type: "Feedback",
            status: 1,
            from: "Online V2",
          },
        })
        .then((data) => {
          console.log("data >>> ", data);
          this.$emit("hideFeedbackArea")
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error);
        });
    },
  },
  // mounted() {
  //   this.getUrlsDetail();
  // },
};
</script>

<style lang="scss">
.feedbackpopup {
  .container {
    margin-inline: auto;
  }
  .feedback-body {
    border-radius: 10px;
    overflow: hidden;
    ._head {
      background-color: rgba(var(--bs-primary), 1);
      padding: 20px 20px 20px 25px;
      position: relative;
      h4 {
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 0px;
        color: #f5fafe;
        opacity: 1;
        font-weight: 500;
        margin-bottom: 0px;
        svg {
            height: 15px;
        }
        span {
            font-size: 18px;
            font-weight: normal;
            display: block;
            margin-top: 5px;
        }
      }
      p {
        font-size: 14px;
        letter-spacing: 0px;
        color: #f5fafe;
        opacity: 0.8;
        margin-bottom: 0;
      }
      .closeBtn {
        position: absolute;
        top: 20px;
        right: 26px;
        height: 42px;
        width: 42px;
        font-size: 24px;
        color: #fff;
        border: 0;
        background-color: rgba(0, 0, 0, 0.2);
        font-weight: normal;
      }
    }
    ._body {
      background-color: #f5fafe;
      padding: 25px;

      .reactions {
        margin: 0 0 20px;
        padding: 0;
        display: flex;
        justify-content: center;
        gap: 15px;

        li {
          list-style: none;
          text-align: center;
          border: solid 1px rgba(var(--bs-primary), 0.2);
          padding: 13px;
          border-radius: 10px;
          width: 120px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 11px;
          cursor: pointer;
          transition: all 0.5s;
          .icon {
            svg {
              transition: all 0.5s;
              height: 27px;
              .svg-color {
                fill: #596a88 !important;
                transition: all 0.5s;
              }
            }
          }
          &:hover,
          &.active {
            box-shadow: -2px 7px 11px rgba(var(--bs-primary), 0.1);
            font-weight: bold;
            color: rgba(var(--bs-primary));
            .icon {
              svg {
                transform: scale(1.1);
                .svg-color {
                  fill: rgba(var(--bs-primary), 1) !important;
                }
              }
            }
          }
        }
      }

      ._form {
        label {
          font-size: 13px;
          font-weight: bold;
          color: #272727;
          margin-bottom: 7.5px;
        }
        textarea {
          width: 100%;
          height: 98px;
          resize: none;
          border: solid 1px rgba(var(--bs-primary), 0.2);
          outline: none;
          font-size: 14px;
          color: #272727;
          border-radius: 10px;
          padding: 15px;
        }
        button {
          margin-left: auto;
          display: block;
          background-color: rgba(var(--bs-primary), 1);
          padding: 8px 20px;
          margin-top: 10px;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .feedback-body {
      ._head {
        padding: 15px 15px 4px 15px;
        p {
          br {
            display: none;
          }
        }
      }
      ._body {
        padding-inline: 15px;
        .reactions {
          li {
            width: 30%;
          }
        }
      }
    }
  }
}
</style>
