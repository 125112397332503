<template>
  <div class="email-varification-block container-fluid">
    <div class="container">
      <div class="white-bg pb-4">
    <div class="text-center mb-4 mt-4" v-if="getEdition?.error">
      <p style="color: #f70a0a;font-size: 23px;" v-html="getEdition?.error"></p>
    </div>
    <AddSlider registration_tab="Email Varification - Manual" v-if="!getEdition?.error"/>
    <div class="alert alert-danger text-center" role="alert" v-if="this.$store.getters.getAlready_submited">
                <p style="margin: 0;">{{ this.$store.getters.getAlready_submited }}</p>
      </div>
    <div class="text-center mb-4 mt-4" v-if="!getEdition?.error">
      <h2 class="text-center text-primary text-uppercase">Register Now</h2>
      <p v-if="!success_message">Enter your email address to continue</p>
    </div>
    <div class="email-varification-block__form" v-if="!getEdition?.error">
      <div v-if="success_message" class="justify-content-center">
        <p class="text-success" v-html="success_message"></p>
      </div>
      <div v-if="exception_message" class="justify-content-center">
        <p class="text-danger" v-html="exception_message"></p>
      </div>
      <div class="input__block mb-3" v-if="!success_message">
        <label for="email" class="mb-2">Email Address <i class="fa-solid fa-star-of-life"></i></label>
        <b-form-input id="email" v-model="email" size="lg" placeholder="name@domain.com" />
        <span v-if="(errors.email && !email) || (!isEmailValid)" class="text-danger">{{ errors.email }}</span>
      </div>
      <div v-if="getEdition?.url_details?.specific_information">
        <p v-html="getEdition.url_details.specific_information"></p>
      </div>
      <div class="input__block mb-2" v-if="!success_message">
        <b-form-checkbox class="[ d-flex gap-2 ] checkbox-alignment" size="lg" id="checkbox-1" v-model="tnc_status" name="checkbox-1">
          <p>Read Full <a :href="getEdition?.edition_data?.terms_url" target="_blank">Terms &amp; Conditions</a>, click
            continue if in agreement</p>
        </b-form-checkbox>
        <span v-if="errors.tnc_status !== null" class="text-danger">{{
          errors.tnc_status
        }}</span>
      </div>
      <vue-recaptcha v-if="getEdition?.url_details?.google_re_captcha == 1 && !success_message" ref="recaptcha"
        :sitekey="recaptcha_key" :loadRecaptchaScript="true" @verify="onVerify"
        @expired="reCaptchaExpired"></vue-recaptcha>
      <div class="text-center mt-4" v-if="!success_message">
        <b-button v-if="disabled" variant="primary">
          <i class="fa fa-refresh fa-spin"></i>Loading
        </b-button>
        <b-button v-else variant="primary"
          :disabled="disabled || (getEdition?.url_details?.google_re_captcha == 1 && !captchaVerified)" size="lg"
          @click="sendEmailVerification">Continue</b-button>

      </div>
    </div>
    <PartnersAndSoponsersLogos v-if="!getEdition?.error"/>
  </div>
</div>
</div>
</template>

<script>
import gql from "graphql-tag";
import AddSlider from '../components/AddSlider.vue';
import PartnersAndSoponsersLogos from '../components/PartnersAndSoponsersLogos.vue';
import VueRecaptcha from "vue-recaptcha";
import { trackPageView } from '@/utils/analytics';
export default {
  name: 'LoginView',
  beforeCreate() {
    let request_data = {
      type: this.$route?.query?.type ?? this.$route?.params?.type,
      event: this.$route?.query.event ?? this.$route?.params?.event_id
    }
    console.log(request_data)
    this.$store.dispatch("getEditionData", request_data)
  },
  // props: [ verified ],
  components: { VueRecaptcha, PartnersAndSoponsersLogos, AddSlider },
  data() {
    return {
      recaptcha_key:process.env.VUE_APP_Site_key,
      isEmailValid: true,
      email: null,
      tnc_status: false,
      event: this.$route.query.event,
      type: this.$route.query.type,
      disabled: false,
      errors: {
        email: null,
        tnc_status: null,
      },
      waiting_list: 0,
      success_message: null,
      exception_message: null,
      captchaVerified: false,
      editionDetails: {},
    };
  },
  mounted() {
    this.getEditionData();
    trackPageView(this.$gtag, this.$route,"Step 1 - Online Registration (Manual)");
  },
  computed: {
    getEdition() {
      return this.$store.getters.getEditionData
    },
    editionManagerName() {
      return this.$store.state.edition_details?.edition_data?.edition_manager_name ?? '';
    },
    editionManagerPhone() {
      return this.$store.state.edition_details?.edition_data?.edition_manager_phone ?? '';
    }
  },
  methods: {
    onVerify(token) {
      // Handle reCAPTCHA verification
      console.log(token);
      this.captchaVerified = true
       fetch("https://www.google.com/recaptcha/api/siteverify", {
           method: 'POST',
           headers: {
             'Content-Type': 'application/x-www-form-urlencoded'
           },
           body: `secret=online-registration-v2&response=${token}`
           // secret: "YOUR_RECAPTCHA_SECRET_KEY",
           // response: "RECAPTCHA_TOKEN",
         })
         .then((response) => {
           if (response.data.success) {
      //       // User successfully completed the reCAPTCHA challenge
           } else {
             // User failed to complete the reCAPTCHA challenge
           }
         })
         .catch((error) => {
          console.error(error)
         });
    },
    reCaptchaExpired(token) {
      // Handle the expired token here
      console.log(token);
      this.captchaVerified = false
    },

    getEditionData() {
      this.exception_message='';
      var manual= this.$route.query.manual ?? this.$route.params.manual;
     var type= this.$route.query.type ?? this.$route.params.type;
      var event= this.$route.params.event_id ?? this.$route.query.event;
      this.$apollo.query({
        query: gql`
            query edition_details($event: String!, $type: String!){
                edition_details(event: $event, type: $type)
            }`,
        variables: {
          event: event,
          type: type
        }
      }).then((data) => {
        const edition_details = JSON.parse(data.data.edition_details);
        if(!edition_details?.url_details?.new_design){
          window.location.href = process.env.VUE_APP_old_online_registration+"event?event="+event+'&type='+type;
          return false;
        }
        if(!manual){
        if (edition_details?.url_details?.is_linkedin || edition_details?.url_details?.login_with_twitter || edition_details?.url_details?.login_with_facebook){
          this.$router.push({
            name: "social-media-login",
            params: { event: event, type: type }
          });
          // this.$router.push('/linkedin/'+event+'/'+type);
        }
      }
        })
        .catch(() => {
          this.exception_message = 'Something went wrong.<br> Please contact ' + this.editionManagerName + ' on ' + this.editionManagerPhone + ' for assistance.'
        });
    },
    sendEmailVerification() {
      this.exception_message = null;
      let email = this.email;
      let tnc_status = this.tnc_status;
      if (email === null || email === "") {
        this.errors.email = "Email field required.";
        return;
      }
      if (tnc_status === false) {
        this.errors.tnc_status =
          "Please check the Terms & Conditions checkbox.";
          return;
      }
      if (email) {
        this.errors.email = null;
      }
      if (this.tnc_status) {
        this.errors.tnc_status = null;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isEmailValid = emailRegex.test(this.email);
      if (!this.isEmailValid && email) {
        this.errors.email = "Invalid email please enter valid email.";
        return;

      }
      if (email && tnc_status) {
        const request_data = {
          email: this.email,
          type: this.$route.query.type ?? this.$route.params.type,
          event: this.$route.params.event_id ?? this.$route.query.event,
          waiting_list: this.waiting_list,
        };
        console.log(request_data);
        this.disabled = true;
        this.$apollo
          .mutate({
            mutation: gql`
              mutation send_email_verification(
                $email: String!
                $type: String!
                $event: String!
                $waiting_list: Int!
              ) {
                send_email_verification(
                  email: $email
                  type: $type
                  event: $event
                  waiting_list: $waiting_list
                )
              }
            `,
            variables: request_data,
          })
          .then((data) => {
            this.disabled = false;
            const res = JSON.parse(data.data.send_email_verification);
            if (res.code && res.code === 500) {
              this.exception_message = res.message;
              return;
            }
            this.email = null;
            this.tnc_status = false;
            if (
              res.verification_via_code === 0 &&
              res.verification_via_link === 0
            ) {
              this.$router.push({
                name: "registration-form",
                params: { event_id: request_data.event, token: res.token },
                query: { type: request_data.type },
              });
            } else if (
              res.verification_via_code === 0 &&
              res.verification_via_link === 1
            ) {
              this.success_message = res.message;
            } else {
              this.$router.push({
                name: "verify-code",
                query: { event: request_data.event, type: request_data.type, email: res.email },
              });
            }
          })
          .catch(() => {
            this.disabled = false
            this.exception_message = 'Something went wrong.<br> Please contact ' + this.editionManagerName + ' on ' + this.editionManagerPhone + ' for assistance.'
          });
      }
    },
    // checkEmptyEmail(){
    //   let email = this.email;
    //   if (email === null || email === ""){
    //     this.errors.email = "Email field required.";
    //   }else{
    //     this.errors.email = null;
    //   }
    // }
  },
  watch: {
    waiting_list(value) {
      this.waiting_list = value;
    }
  }
}
</script>

<style lang="scss">
.email-varification-block {
  position: relative;
  padding-block: var(--block-padding-y) calc(var(--block-padding-y) * 1.5);
  padding-inline: var(--block-padding-x);
  display: flow-root;

  &__form {
    border-radius: calc(var(--base-border-radius) * 1.25);
    background-color: rgba(var(--bs-primary), 0.08);
    border: 1px solid rgba(var(--bs-primary), 0.3);
    margin-inline: auto;
    max-width: 600px;
    padding-block: calc(0.75rem + 1vw);
    padding-inline: calc(1.5rem + 1vw);
  }
}
.white-bg {
  background-color: #fff;
  display: flow-root;
}

.email-varification-block {
  .input__block {
    i {
      font-size: 50%;
      color: var(--bs-danger);
      margin-top: -0.4rem;
      position: relative;
      top: -5px;
    }
  }
}
.checkbox-alignment {
  .custom-control-label {
    &:after,
    &:before {
      top: 0.1125rem !important;
    }
  }
}
</style>
