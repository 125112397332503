import VerificationBlock from "@/pages/VerificationBlockView.vue";
import RegistrationFormView from "@/pages/RegistrationFormView.vue";
import CodeVerificationView from "@/pages/CodeVerificationView.vue";
import RegistrationConfirmationView from "@/pages/RegistrationConfirmationView.vue";
import RegistrationCancel from "@/pages/RegistrationCancel.vue";
import RegistrationFailed from "@/pages/RegistrationFailed.vue";
import RegistraWithLinkedIn from "@/pages/RegistraWithLinkedIn.vue";
// import RegistrationConfirmationView from "@/pages/RegistrationConfirmationView.vue";

import RegistrationConfirmationTestView from "@/pages/RegistrationConfirmationTestView.vue";
import ErrorPage from "@/pages/ErrorPage.vue";

const routes = [
    { path : '/:event_id/:type', name : 'index', component : VerificationBlock },
    { path : '/linkedin/:event_id/:type', name : 'New Design', component : RegistraWithLinkedIn },
    { path : '/social-media-login/:event_id/:type', name : 'social-media-login', component : RegistraWithLinkedIn },
    { path : '/invoice', name : 'invoice', component : RegistrationConfirmationView },
    { path : '/registration/form/:event_id/:token', name : 'registration-form', component : RegistrationFormView },
    { path: '/verify', name: 'verify-code', component: CodeVerificationView },
    { path: '/payment/receipt/:event_id/:type/:registration_id', name: 'registration-confirmation', component: RegistrationConfirmationView },
    { path: '/registration-cancel/:event_id/:type/:registration_id', name: 'registration-cancel', component: RegistrationCancel },
    { path: '/registration-failed/:event_id/:type/:registration_id/:currency', name: 'registration-failed', component: RegistrationFailed },
    
    { path: '/registration-confirmation-test/:event_id', name: 'registration-confirmation-test', component: RegistrationConfirmationTestView },
    { path: '/:catchAll(.*)', name: '404', component: ErrorPage }

];

export default routes;