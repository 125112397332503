import Vue from "vue";
import Vuex from "vuex";
import gql from "graphql-tag";
import VueApollo from 'vue-apollo'
import apolloClient from "@/plugins/apollo-client";
// import { hexToRgba } from "vue-phone-number-input/dist/vue-phone-number-input.common";



Vue.use(Vuex);
// Vue.use(VueApollo)

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});

const store = new Vuex.Store({
    state: {
        already_submited:'',
        edition_details: {},
        test_field: null,
        category_items_options_pricing: {},
        category_option_request_data: {},
        registration_response: {},
        items: {},
        category: {},
        itemsAndOptionsPrices: 0,
        registrationOrContact: null,
        vat: 0.00,
        sub_total: 0.00,
        vat_amount: 0.00,
        actual_price: 0,
        discount: 0,
        currency: 'AED',
        partners_sponsors_state: [],
        tooltip_message: 'The prices above are inclusive of 0.00% Value added tax; AED 0.00',
        shouldNavigateToNotFound: false
    },
    getters: {
        getCurrency(state) {
            return state.currency
        },
        getAlready_submited(state) {
            return state.already_submited
        },
        get_partners_sponsors_state(state) {
            return state.partners_sponsors_state
        },
        getDiscount(state) {
            return state.discount
        },
        getActualPrice(state) {
            return state.actual_price
        },
        getTooltipMessage(state) {
            return state.tooltip_message
        },
        getSubTotal(state) {
            return state.sub_total
        },
        getVatAmount(state) {
            return state.vat_amount
        },
        getVat(state) {
            return state.vat
        },
        getItemAndOptionPrice(state) {
            return state.itemsAndOptionsPrices
        },
        getEditionIndustries(state) {
            return state.edition_details.edition_data?.edition_industries
        },
        getSeniorityLevels(state) {
            return state.edition_details?.seniority_levels
        },
        getCategories(state) {
            return state.edition_details?.url_details?.categories;
        },
        getFormFields(state) {
            return state.edition_details?.fields;
        },
        getEditionData(state) {
            return state.edition_details;
        },
        getCategoryItemsOptionsPricingData(state) {
            return state.category_items_options_pricing;
        },
        getPaymentMethods(state) {
            return state.edition_details.payment_methods;
        },
        getCategoryOptionsRequestData(state) {
            return state.category_option_request_data;
        },
        getPromocode(state) {
            return state.edition_details.url_details?.promotion?.promotion_code;
        },
        getRegistrationResponse(state) {
            return state.registration_response;
        },
        getRegistrationItems(state) {
            return state.items;
        },
        getCategory(state) {
            return state.category;
        },
        getProductCategory(state) {
            return state.edition_details?.product_categories;
        },
        getRegistrationOrContact(state) {
            return state.registrationOrContact;
        },
    },
    actions: {
        async getEditionData ({ commit }, payload) {
            const variables = {
                event: payload.event,
                type: payload.type,
            }
            try {
                const data = await apolloProvider.defaultClient.query({
                    query: gql`
                        query edition_details($event: String!, $type: String!){
                            edition_details(event: $event, type: $type)
                        }`,
                    variables,
                })

                const edition_details = JSON.parse(data.data.edition_details);
                if (edition_details.edition_data.currency.name) {
                    commit("setCurrency", edition_details?.edition_data?.currency?.name)
                } else {
                    commit("setCurrency", this.currency)
                }

                if (edition_details?.edition_data?.color) {
                    const hex = edition_details.edition_data.color.hex_color_code;
                    var r = parseInt(hex.length == 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
                    var g = parseInt(hex.length == 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
                    var b = parseInt(hex.length == 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
                    const rgb_value = r + ', ' + g + ', ' + b;
                    // document.documentElement.style.setProperty("--bs-primary", `#${edition_details.edition_data.color.hex_color_code}`);
                    document.documentElement.style.setProperty("--bs-primary", rgb_value);
                    // document.documentElement.style.setProperty("--base-primary", `#${edition_details.edition_data.color.hex_color_code}`);

                }
                if (edition_details?.edition_data?.secondary_color) {
                    const secondary_hex = edition_details.edition_data.secondary_color.hex_color_code;
                    console.log("secodary value::::", secondary_hex);
                    var rb = parseInt(secondary_hex.length == 3 ? secondary_hex.slice(0, 1).repeat(2) : secondary_hex.slice(0, 2), 16);
                    var gb = parseInt(secondary_hex.length == 3 ? secondary_hex.slice(1, 2).repeat(2) : secondary_hex.slice(2, 4), 16);
                    var br = parseInt(secondary_hex.length == 3 ? secondary_hex.slice(2, 3).repeat(2) : secondary_hex.slice(4, 6), 16);
                    const s_rgb_value = rb + ', ' + gb + ', ' + br;

                    document.documentElement.style.setProperty("--bs-secondary", s_rgb_value);
                }
                commit("setEditionDetails", edition_details);
            } catch (error) {
                const graphQLError = error?.graphQLErrors?.[0];
                const category = graphQLError?.extensions?.category;

                if (category === 'InvalidEditionOrEventID') {
                    commit('setShouldNavigateToNotFound', true)
                }
            }
        },
        getCategoryItemsOptionsPricing({ commit }, payload) {
            commit("setCategoryItemsOptionsPricing", payload);
        },
        setCategoryOptionsRequestData({ commit }, payload) {
            commit('setCategoryOptionsRequestData', payload);
        },
        getRegistrationResponse({ commit }, payload) {
            commit("setRegistrationResponse", payload);
        },
        getRegistrationItems({ commit }, payload) {
            commit('setRegistrationItems', payload)
        },
        getCategoryData({ commit }, payload) {
            commit("setCategoryData", payload)
        }
    },
    mutations: {
        setAlready_submited(state, payload) {
            state.already_submited = payload;
        },
        setCurrency(state, payload) {
            state.currency = payload;
        },
        setDiscount(state, payload) {
            state.discount = payload;
        },
        setActualPrice(state, payload) {
            state.actual_price = payload;
        },

        setTooltipMessage(state, payload) {
            state.tooltip_message = payload;
        },
        setSubTotal(state, payload) {
            state.sub_total = payload;
        },
        setVatAmount(state, payload) {
            state.vat_amount = payload;
        },
        setVat(state, payload) {
            state.vat = payload;
        },
        setItemAndOptionPrice(state, payload) {
            state.itemsAndOptionsPrices = payload;
        },
        setEditionDetails(state, payload) {
            state.edition_details = payload;
        },
        setTestState(state, payload) {
            state.test_field = payload;
        },
        setCategoryItemsOptionsPricing(state, payload) {
            state.category_items_options_pricing = payload;
        },
        setCategoryOptionsRequestData(state, payload) {
            state.category_option_request_data = payload;
        },
        setRegistrationResponse(state, payload) {
            state.registration_response = payload;
        },
        setRegistrationItems(state, payload) {
            state.items = payload;
        },
        setCategoryData(state, payload) {
            state.category = payload;
        },
        setRegistrationOrContact(state, payload) {
            state.registrationOrContact = payload;
        },
        set_partners_sponsors_state(state, payload) {
            state.partners_sponsors_state = payload;
        },
        setShouldNavigateToNotFound(state, value) {
            state.shouldNavigateToNotFound = value;
        }
    }
});

export default store;