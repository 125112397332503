<template>
  <header class="header" :style="`background-image: url(${getLogo})`">
    <div class="container">
      <div class="header-container">
        <div class="header-container__heading">
          <h3 class="mb-4 text-uppercase">Online Registration Form</h3>
        </div>
        <div class="header-container__banner">
          <picture>
            <source media="(max-width:650px)" :srcset="getLogo">
            <img class="w-100" :src="getLogo" alt="Header Banner" />
          </picture>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// import bannerMobile from '@/../public/images/mobile-banner.png'
// import bannerDekstop from '@/../public/images/dummy-banner.png'

export default {
  name: "MainHeader",
  async beforeCreate() {
    // let event = this.$route.query.event ?? this.$route.params.event_id;
    // let type = this.$route.query.type ?? this.$route.params.type;
    // await this.$store.dispatch("getEditionData", {
    //   event: event,
    //   type: type
    // });
    document.title = this.$store.state?.edition_details?.edition_data?.name || 'Online Registration';
    let ogImageTag = document.querySelector('meta[property="og:image"]');
    let ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (!ogImageTag) {
      ogImageTag = document.createElement('meta');
      ogImageTag.setAttribute('property', 'og:image');
      document.head.appendChild(ogImageTag);
    }
    if (!ogDescriptionTag) {
      ogDescriptionTag = document.createElement('meta');
      ogDescriptionTag.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescriptionTag);
    }
    if (this.$store?.state?.edition_details?.url_details?.social_media_description) {
      var social_media_description = new DOMParser().parseFromString(this.$store?.state?.edition_details?.url_details?.social_media_description, 'text/html');
      ogDescriptionTag.setAttribute('content', social_media_description.body.textContent || "");
    }

    if (this.$store?.state?.edition_details?.url_details?.social_media_images) {
      var social_media_images = this.$store?.state?.edition_details?.url_details.social_media_images.split(",");
      if (social_media_images.length > 0) {
        var ads = new URLSearchParams(window.location.search).get('ads');
        if (!ads) {
          ads = 0;
        }
        ogImageTag.setAttribute('content', process.env.VUE_APP_Image_url + social_media_images[ads] || '');
      }
    }
  },
  data() {
    return {
      bannerDekstop: null,
      bannerMobile: null,
    }
  },
  computed: {
    getLogo() {
      return process.env.VUE_APP_Image_url + this.$store.state.edition_details?.edition_data?.logo;
    },
    // this.bannerDekstop = this.$store.state.edition_details?.edition_data?.logo;
    // this.bannerMobile = this.$store.state.edition_details?.edition_data?.logo;
  },
  watch: {
    '$store.state.shouldNavigateToNotFound' (newValue) {
      if (newValue) {
        this.$router.push({ name: '404' })
      }
    }
  }
}
</script>

<style lang="scss">
.header {
  --header-padding-y: calc(1rem + 1vw);
  position: relative;
  background-color: rgb(var(--bs-primary));
  color: var(--bs-white);
  background-blend-mode: multiply;
  background-attachment: fixed;
  background-size: cover;
  isolation: isolate;
  text-align: center;

  padding-block-start: var(--header-padding-y);

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(var(--bs-dark-rgb), 0.35);
    z-index: -1;
    backdrop-filter: blur(10px);
  }

  &-container {
    position: relative;

    &__heading {
      letter-spacing: 0.25rem;

      @media (max-width: 767.85px) {
        letter-spacing: 0;

        h3 {
          text-transform: capitalize !important;
        }
      }
    }

    &__banner {
      position: relative;
      padding-block-start: 5px;
      padding-inline: 5px;
      background-color: var(--bs-white);
      border-radius: calc(var(--base-border-radius) * 0) calc(var(--base-border-radius) * 0) 0 0;

      img {
        border-radius: inherit;
        // max-height: 300px;
        object-fit: contain;
      }
    }
  }
}
</style>