<template>
  <div class="email-varification-block">
    <div class="text-center mb-4">
      <h2 class="text-center text-primary text-uppercase">Code Verification</h2>
    </div>
    <div class="email-varification-block__form">
      <div v-if="success_message" class="justify-content-center">
        <p class="text-success" v-html="success_message"></p>
      </div>
      <div class="input__block mb-3">
        <label for="email" class="mb-2">Email Address <i class="fa-solid fa-star-of-life"></i></label>
        <b-form-input id="email" readonly="" v-model="email" size="lg" placeholder="name@domain.com" />
        <span v-if="errors.email" class="text-danger">{{ errors.email }}</span>
      </div>
      <div class="input__block mb-3">
        <label for="email" class="mb-2">Verification code</label>
        <b-form-input id="email" v-model="code" size="lg" placeholder="Enter your verification code" />
        <span v-if="errors.code" class="text-danger">{{ errors.code }}</span>
        <span v-if="error_message" class="text-danger">{{ error_message }}</span>
      </div>
      <div class="text-center mt-4">
        <b-button variant="primary" :disabled="disabled" @click="verificationCode" size="lg">Continue</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { trackPageView } from '@/utils/analytics'

export default {
  name: 'CodeVerificationView',
  data() {
    return {
      email: this.$route.query.email,
      event : this.$route.query.event,
      type : this.$route.query.type,
      code : null,
      disabled: false,
      errors: {
        email: null,
        code : null
      },
      success_message : null,
      error_message : null,
    }
  },
  mounted() {
    trackPageView(this.$gtag, this.$route,"Step 1 - Registration Verification");
    if(Object.keys(this.$store.state.edition_details).length === 0){
      this.$router.push({
        name: 'index',
        query: {event: this.event,type: this.type}
      })
    }
  },
  methods:{
    verificationCode(){
      let email = this.email;
      let code  = this.code;
      if (email === null || email === ""){
        this.errors.email = 'Email field required.'
      }
      if (code === null || code === ""){
        this.errors.code = "Verification code field required."
      }
      if (email && code){
        console.log(email,code,this.type);
        this.$apollo.mutate({
          mutation: gql`
                mutation verify_email_code($email: String!, $type: String!, $code: String!, $event: String!){
                    verify_email_code(email: $email, type: $type, code: $code, event: $event)
                }
            `,
          variables:{
            email: this.email,
            type: this.type,
            code: this.code,
            event: this.event
          }
        }).then((data) => {
          let res = JSON.parse(data.data.verify_email_code);
          if (res.error){
            this.error_message = res.error;
          }else {
            this.error_message = null;
            this.$router.push({
              name: 'registration-form',
              params: {event_id: this.event, token: res.token},
              query: {type: this.type}
            });
          }
          console.log(res);
        }).catch((error) => {
          console.log(error);
        });
      }
    }
  }
}
</script>

<style lang="scss">
.email-varification-block {
  margin-top: 10px;
  .input__block {
      i {
        font-size: 50%;
        color: var(--bs-danger);
        margin-top: -0.4rem;
        position: relative;
        top: -5px;
      }
    }
  position: relative;
  padding-block: var(--block-padding-y) calc(var(--block-padding-y) * 1.5);
  padding-inline: var(--block-padding-x);
  display: flow-root;
  &__form {
    border-radius: calc(var(--base-border-radius) * 1.25);
    background-color: rgba(var(--bs-primary), 0.08);
    border: 1px solid rgba(var(--bs-primary), 0.3);
    margin-inline: auto;
    max-width: 600px;
    padding-block: calc(0.75rem + 1vw);
    padding-inline: calc(1.5rem + 1vw);
  }
}
</style>