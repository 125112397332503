<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__container" v-if="getEditionInfo?.edition_data?.footer_information">
        <strong>Note: </strong><br>
        <p v-html="getEditionInfo.edition_data.footer_information"></p>
      </div>
      <div class="footer__container" v-else>
        <strong>Contact: </strong> {{getEditionInfo?.edition_data?.edition_manager_name}} on {{getEditionInfo?.edition_data?.edition_manager_phone}} or
        <a :href="'mailto:'+ getEditionInfo?.edition_data?.edition_manager_email">{{getEditionInfo?.edition_data?.edition_manager_email}}</a>
        <hr>
        <p style="padding: 0 10px 10px; font-size: 11px;font-family: Arial, sans-serif;  line-height: 20px; text-align:center;">

          INDEX® Conferences & Exhibitions Organisation LLC. P.O.Box: 13636, Dubai, UAE. INDEX Holding
          Headquarters, Road D-62, Opposite to Nad Al Hamar Tel : +971 4 5208888 Fax : +971 4 3384193
          Web : <a :href="getEditionInfo?.edition_data?.event?.website"> {{getEditionInfo?.edition_data?.event?.website}}</a> E-mail : <a
            :href="'mailto:'+ getEditionInfo?.edition_data?.edition_manager_email">{{getEditionInfo?.edition_data?.edition_manager_email}} </a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      footer_information: null
    }
  },
  computed: {
    getFooterInfo() {
      return this.$store.state.edition_details?.edition_data?.footer_information;
    },
    getEditionInfo(){
      return this.$store.getters.getEditionData
    }
  }
}
</script>

<style lang="scss">
.footer {
  width: 100%;
  position: relative;
  padding: calc(1rem + 1vw);
  @media (max-width: 767.85px) {
    padding: calc(1rem + 1vw) 0;
  }

  &__container {
    background-color: rgba(var(--bs-dark-rgb), 0.05);
    padding-block: calc(1rem + 0.5vw);
    padding-inline: calc(1rem + 1vw);

    p:last-child {
      margin-bottom: 0;
    }
  }
}
</style>