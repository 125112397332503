<template>
  <div class="payment-details-table header-borderless footer-borderless row-gap--2">
    <div class="payment-details-table__header" v-if="tableData?.items?.length > 0">
      <div class="payment-details-table__row" v-for="tHead in tableData.head" :key="tHead.id">
        <div class="payment-details-table__cell indexing">{{tHead.id}}</div>
        <div class="payment-details-table__cell">{{tHead.item}}</div>
        <div class="payment-details-table__cell pricing fw-bold">Price</div>
      </div>
    </div>
    <div class="payment-details-table__body">
      <div class="payment-details-table__row" v-for="(item, itemIndex) in tableData.items" :key="itemIndex">
        <div class="payment-details-table__cell indexing">{{ item.item_id }}</div>
        <div class="payment-details-table__cell">{{ item.item.name }}</div>
        <div class="payment-details-table__cell pricing fw-bold">{{ currency }} {{ item.price }}</div>
      </div>
    </div>
    <div class="payment-details-table__header" v-if="tableData?.options?.length > 0">
      <div class="payment-details-table__row">
        <div class="payment-details-table__cell indexing">{{tableData.option_head.id}}</div>
        <div class="payment-details-table__cell">{{tableData.option_head.item}}</div>
        <div class="payment-details-table__cell pricing fw-bold">Price</div>
      </div>
    </div>
    <div class="payment-details-table__body">
      <div class="payment-details-table__row" v-for="(item, itemIndex) in tableData.options" :key="itemIndex">
        <div class="payment-details-table__cell indexing">{{ item.id }}</div>
        <div class="payment-details-table__cell">{{ item.name }}</div>
        <div class="payment-details-table__cell pricing fw-bold">{{ currency }} {{ item.price }}</div>
      </div>
    </div>
    <div class="payment-details-table__footer">
      <div class="payment-details-table__row">
        <div class="payment-details-table__cell">
          <ul class="short-info">
            <li>
              <span class="__label [ d-flex align-items-center gap-2 ]">
                Sub Total
              </span>
              <span class="__value fw-bold">{{currency}} {{this.$store.getters.getSubTotal}}</span>
            </li>
            <li>
              <span class="__label [ d-flex align-items-center gap-2 ]">
                VAT
                <span class="custom-tooltip aligned--bottom">
                  <i class="fa-solid fa-info-circle custom-tooltip__symbol"></i>
                  <span class="custom-tooltip__content">
                    {{this.$store.getters.getTooltipMessage}}
                  </span>
                </span>
              </span>
              <span class="__value fw-bold">{{currency}} {{this.$store.getters.getVatAmount}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentDetailsTable",
  props: ["tableData","shortSummary","currency","category_items_options_pricing"],
  // props:
  //     {
  //   totol_payable: [String],
  //   currency: Number,
  //   shortSummary: {
  //     type: [Array, Object],
  //     default: () => {
  //       return {
  //         sub_total: {
  //           label: 'Sub Total',
  //           currency: 'AED',
  //           amount: 660.00
  //         },
  //       }
  //     },
  //   },
  //   tableData: {
  //     type: [Object, Array],
  //     default: () => {
  //       return {
  //         head: [
  //           { id: '#', item: 'Discription', price: 'list Name' },
  //         ],
  //         body: [
  //           { id: 'xyz121', item: 'Dental Hygienist Early Bird', amount: { price: 693.00, currency: 'AED' } },
  //           { id: 'xyz122', item: 'Conference', amount: { price: 10.50, currency: 'AED' } },
  //           { id: 'xyz123', item: 'Exhibition', amount: { price: 0.00, currency: 'AED' } },
  //         ]
  //       }
  //     }
  //   }
  // },
  data() {
    return {}
  },
  mounted() {
  },
  methods:{
    get_category_pricing(pricing){
      console.log("categoryPricing table:",pricing);
    }
  },
  watch: {
    category_items_options_pricing(value){
      console.log("category pricing:",value);
    }
  },
}
</script>

<style lang="scss">
  .payment-details-table {
    --table-indexing-max-width: 40px;
    --table-price-max-width: 100px;

    --table-padding-y: 0.6rem;
    --table-padding-x: 0.85rem;
    --table-row-border-radius: 0.35rem;
    
    --table-row-border-width: 1px;
    --table-row-border-style: solid;
    --table-row-border-color: var(--bs-gray-400);
    --table-row-bg-color: var(--bs-white);
    --table-row-color: var(--bs-dark);
    
    --table-row-hover-border-color: rgba(var(--bs-primary),1);
    --table-row-hover-bg-color: rgba(var(--bs-primary),1);
    --table-row-hover-color: var(--bs-white);
    
    --table-row-gap: 0;

    position: relative;
    gap: var(--table-row-gap);
    
    &__row {
      position: relative;
      display: flex;
      border-radius: var(--table-row-border-radius);
      background-color: var(--table-row-bg-color);
      color: var(--table-row-color);
      border: var(--table-row-border-width) var(--table-row-border-style) var(--table-row-border-color);
      padding-block: var(--table-padding-y);
      padding-inline: var(--table-padding-x);
      transition: var(--transition);
    }
    &:is(.borderless) { --table-row-border-width: 0; }
    &:is(.header--light, .head--light, .header-light, .header-light) {
      .payment-details-table__header {
        --table-row-border-color: var(--bs-gray-200);
        --table-row-bg-color: var(--bs-gray-200);
        --table-row-color: var(--bs-dark);
      }
    }
    &:is(.header--dark, .head--dark, .header-dark, .header-dark) {
      .payment-details-table__header {
        --table-row-border-color: var(--bs-dark);
        --table-row-bg-color: var(--bs-dark);
        --table-row-color: var(--bs-white);
      }
    }
    &:is(.header--primary, .head--primary, .header-primary, .header-primary) {
      .payment-details-table__header {
        --table-row-border-color: rgba(var(--bs-primary),1);
        --table-row-bg-color: rgba(var(--bs-primary),1);
        --table-row-color: var(--bs-white);
      }
    }
    &:is(.header-borderless, .head-borderless) {
      .payment-details-table__header { --table-row-border-width: 0; }
    }
    &:is(.footer-borderless, .footer-borderless) {
      .payment-details-table__footer { --table-row-border-width: 0; }
    }
    &:is(.hover) {
      :not(.payment-details-table__header, .payment-details-table__footer) {
        .payment-details-table__row:hover {
          border-color: var(--table-row-hover-border-color);
          background-color: var(--table-row-hover-bg-color);
          color: var(--table-row-hover-color);
        }
      }
      
    }

    $gapsIndexing: (1, 2, 3, 4, 5);
    $gaps: (0.25rem, 0.5rem, 0.75rem, 1rem, 1.5rem);
    @for $i from 1 to length($gapsIndexing) + 1 {
      &:is(
        .row-gap--#{nth($gapsIndexing, $i)}, 
        .row-gap-#{nth($gapsIndexing, $i)}, 
        .gap--#{nth($gapsIndexing, $i)}, 
        .gap-#{nth($gapsIndexing, $i)}
      ){ --table-row-gap: #{nth($gaps, $i)}; } 
    }

    &__cell {
      position: relative;
      width: 100%;
      &:is(.indexing, .index) {
        max-width: var(--table-indexing-max-width);
        flex-shrink: 0;
      }
      &:is(.pricing, .price) {
        max-width: var(--table-price-max-width);
        flex-shrink: 0;
      }
    }
   
    &__header {
      position: relative;
      font-weight: 700;
      margin-bottom: var(--table-row-gap);
    }
    &__body {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: var(--table-row-gap);
    }
    &__footer {
      margin-top: var(--table-row-gap);
      position: relative;
    }
  }

  ul.short-info {
    position: relative;
    margin: 0;
    margin-bottom: calc(0px - var(--table-padding-y));
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
    li {
      display: flex;
      gap: 0.75rem;
      .__label {
       min-width: max-content;
       text-align: left; 
       display: inline-block;
      }
      .__value {
        min-width: calc(var(--table-price-max-width) + 15px);
        &::before { content: ':'; display: inline-block; margin-right: 0.5rem;}
      }
    }
  }
</style>