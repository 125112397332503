<template>
  <div>
    <div class="category-short-details mt-4">
      <div class="category-short-details__content">
        <p class="category-short-details__text">
          <span v-if="category_data.instruction_description">{{
            category_data.instruction_description
          }}</span>
          <span
            v-if="category_data.instruction_description"
            class="continue-reading"
            v-b-modal.categoryModal
            >Continue Reading</span
          >
        </p>
        <div class="mb-4" v-if="category_data.needs_password">
          <div class="input-block">
            <label for="password" class="mb-1"
              >Password <i class="fa-solid fa-star-of-life"></i>
            </label>
            <b-form-input
              type="password"
              @blur="checkCategoryPassword"
              v-model="category_password"
              placeholder="Please enter category password"
            />
            <!--            <b-form-input placeholder="Please enter category password" v-model="password" />-->
            <div
              v-if="category_password_error_message || errors.category_password"
              class="justify-content-center"
            >
              <p class="text-danger">
                {{
                  category_password_error_message ?? errors.category_password
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="mb-4" v-if="category_data.membership_id_required">
          <div class="input-block">
            <label for="membership" class="mb-1"
              >Membership <i class="fa-solid fa-star-of-life"></i
            ></label>
            <b-form-input
              @blur="checkMembershipCode"
              v-model="membership_code"
              placeholder="Please enter your membership ID"
            />
            <div
              v-if="membership_error_message || errors.membership_code"
              class="justify-content-center"
            >
              <p class="text-danger">
                {{ membership_error_message ?? errors.membership_code }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="uploading-document-container mb-4"
          v-if="category_data.professional_id_required"
        >
          <p class="fst-italic">
            {{ category_data?.professional_id_message }}
          </p>
          <div class="input-block">
            <label for="file-default" class="mb-1"
              >Professional ID / Student ID:<i
                class="fa-solid fa-star-of-life"
              ></i
            ></label>
            <b-form-file
              id="file-default"
              @change="uploadFile"
              name="file"
              accept="image/*"
              :disabled="is_disable_professional_id"
            ></b-form-file>
            <div
              v-if="professional_id_success"
              class="justify-content-center mt-3"
            >
              <p class="text-success">{{ professional_id_success }}</p>
            </div>
            <div
              v-if="validation_professional_id"
              class="justify-content-center mt-3"
            >
              <p class="text-danger">{{ validation_professional_id }}</p>
            </div>
            <div
              v-if="
                errors.professional_id &&
                !professional_id_success &&
                !validation_professional_id
              "
              class="justify-content-center mt-3"
            >
              <p class="text-danger">{{ errors.professional_id }}</p>
            </div>
          </div>
        </div>
      </div>
      <h6 class="" v-if="shouldDisplayHeading">
        Included in <strong>Your Registration:</strong>
      </h6>
      <ul class="selected-pills mb-1 mt-3" :key="dynamicKey">
        <template v-for="(pill, index) in items">
          <li
            :key="`item-${index}`"
            class="selected-pills__pill"
            v-if="pill.pre_selected == 1 && pill.user_selection == 0"
          >
            <i class="fa-solid fa-check"></i> {{ pill.item.name }}
          </li>
          <template v-for="(option, key) in pill?.options">
            <li
              :key="`option-${key}`"
              class="selected-pills__pill"
              v-if="
                option.pre_selected === 1 &&
                option.user_selection === 0 &&
                ((pill.pre_selected === 1 && pill.user_selection === 0) ||
                  pill.check)
              "
            >
              <i class="fa-solid fa-check"></i> {{ option.name }}
            </li>
          </template>
        </template>
      </ul>
      <!-- <ul class="selected-pills mt-3">
        <div class="align-pills">
          <template v-for="pill in sortedOptions(items)">
            <span v-for="(option, key) in pill?.options" :key="key">
              <li class="selected-pills__pill" v-if="(option.pre_selected==1 && option.user_selection==0 && pill.pre_selected==1 && pill.user_selection==0) || (option.pre_selected==1 && option.user_selection==0 && pill.check)">
                <i class="fa-solid fa-check"></i> {{ option.name }}
              </li>
              </span>
          </template>
          </div>
      </ul> -->
      <CategorySectionAndItems
        @items_data="handleItemsData"
        :sectionDataList="items"
        :category_data="category_data"
        @updatedData="updated_section_list = $event"
        :items_options_api_errors="items_options_api_errors"
      />
    </div>

    <!-- ============ [ Modal for categories details ] ============ -->
    <b-modal
      hide-footer
      id="categoryModal"
      size="xl"
      title="Category Description"
    >
      <b-tabs pills vertical class="tab-container">
        <b-tab
          v-for="category in categories"
          :key="category.id"
          :title="category.name"
        >
          <div class="modal-body-tab-content">
            <div>
              <h4 class="text-primary">
                {{ category.name }}
                <!-- {{ category.price }} -->
                <!-- {{ category.currency.name }} -->
              </h4>
              <!--              <div v-html="category.instruction_description"></div>-->
              <div>
                <p>
                  {{ category?.instructions_text }}
                </p>
                <p>
                  {{ category?.instruction_description }}
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <b-button
                variant="primary"
                class=""
                @click="selectCategory(category.registration_category_id)"
              >
                <i class="fa-solid fa-check"></i>
                Select this Category
              </b-button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import CategorySectionAndItems from "./CategorySectionAndItems.vue";
import gql from "graphql-tag";
export default {
  name: "CategoryShortDetails",
  props: {
    // items: {
    //   type: [Object, Array],
    //   default: () => ["Item One", "Item Two", "Item Three"],
    // },
    category_data: {},
    categories: [],
    items: [],
    errors: {},
    api_errors: {},
    encrypted_registration_id: null,
  },
  data() {
    return {
      shouldDisplayHeading: false,
      dynamicKey: 1,
      validation_professional_id: "",
      professional_id_success: "",
      itemStatus: false,
      preselectedStatus: false,
      category_password: null,
      membership_code: null,
      membership_error_message: null,
      category_password_error_message: null,
      selected_category_id: null,
      password: "",
      items_options_api_errors: {},
      membership_id: "",
      selectedFile: null,
      updated_section_list: null,
      is_disable_professional_id: false,
    };
  },
  methods: {
    sortedOptions(options) {
      let opt = [...options];
      if (
        opt.some((option) =>
          Object.prototype.hasOwnProperty.call(option, "order")
        )
      ) {
        return opt.sort((a, b) => (a.order || 0) - (b.order || 0));
      } else {
        // If "order" property is not available, return opt as they are
        return opt;
      }
    },
    itemCheck(status) {
      this.itemStatus = status;
    },
    checkPreserlect() {
      this.preselectedStatus = true;
    },
    handleItemsData(data) {
      this.dynamicKey++;
      this.$emit("items_data", data);
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleUploadError(error_message) {
      this.validation_professional_id = error_message;
      this.$emit("get_file", null);
      this.professional_id_success = false;
    },
    uploadFile(e) {
      let profissional_id = e.target.files[0];
      if (!profissional_id || profissional_id == "undefined") {
        this.handleUploadError("Professional Id Field Required.");
        return false;
      }
      const allowed_extensions = ["jpeg", "jpg", "png"];
      const extension = profissional_id.name.split(".").pop().toLowerCase();
      if (!allowed_extensions.includes(extension)) {
        this.handleUploadError(
          "The Professional ID must be an image (JPEG, PNG, JPG)."
        );
        return false;
      }

      if (profissional_id.size > 2 * 1024 * 1024) {
        this.handleUploadError("The Professional ID should not exceed 2MB.");
        return false;
      }

      this.is_disable_professional_id = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation profissional_id_upload(
              $encrypted_registration_id: String
              $profissional_id: Upload!
            ) {
              profissional_id_upload(
                encrypted_registration_id: $encrypted_registration_id
                profissional_id: $profissional_id
              ) {
                encrypted_registration_id
                status
              }
            }
          `,
          variables: {
            encrypted_registration_id: this.encrypted_registration_id,
            profissional_id: profissional_id,
          },
        })
        .then((data) => {
          this.is_disable_professional_id = false;
          var res = data?.data?.profissional_id_upload;
          if (res.status == 200) {
            this.professional_id_success = "Professional ID Upload";
            this.validation_professional_id = "";
            profissional_id = new File([profissional_id], profissional_id.name);
            this.$emit("get_file", profissional_id);
          }
        })
        .catch((error) => {
          this.is_disable_professional_id = false;
          if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            const validationError = error.graphQLErrors[0];
            if (
              validationError?.extensions &&
              validationError?.extensions?.validation?.profissional_id.length >
                0
            ) {
              this.validation_professional_id =
                "The Professional ID must be an image (JPEG, PNG,JPG) and should not exceed 2MB.";
              this.professional_id_success = "";
              this.$emit("get_file", null);
            }
          }
        });
    },
    selectCategory(event) {
      this.$bvModal.hide("categoryModal");
      this.$emit("category_field_data", event);
    },
    checkCategoryPassword() {
      if (this.category_password == "" || this.category_password == null) {
        this.category_password_error_message =
          "Category password field required";
        return false;
      } else if (this.category_password !== this.category_data.password) {
        this.category_password_error_message = "Password incorrect";
        return false;
      } else {
        this.category_password_error_message = "";
        this.$emit("category_password_field", this.category_password);
        return true;
      }
    },

    checkMembershipCode() {
      let membership_code = this.membership_code;
      this.$emit("membership_field_data", membership_code);
    },
  },
  computed: {},
  watch: {
    items() {
      if (!Array.isArray(this.items)) {
        this.shouldDisplayHeading = false;
        return false;
      }
      let ItemsTypeCheck = this.items.some(
        (pill) =>
          (pill.pre_selected === 1 && pill.user_selection === 0) ||
          (pill.options.some(
            (option) => option.pre_selected === 1 && option.user_selection === 0
          ) &&
            ((pill.pre_selected === 1 && pill.user_selection === 0) ||
              pill.check))
      );
      this.shouldDisplayHeading = ItemsTypeCheck ? true : false;
    },
    api_errors(errors) {
      this.items_options_api_errors = errors;
      console.log(errors);
    },
  },
  components: { CategorySectionAndItems },
};
</script>

<style lang="scss">
.category-short-details {
  position: relative;
  &__content {
    position: relative;
  }
  &__text {
    --block-padding-x: 1rem;
    --continue-btn-width: 150px;
    position: relative;
    padding-inline-start: var(--block-padding-x);
    box-shadow: inset calc(var(--block-padding-x) / 3) 0 0
      rgba(var(--bs-primary), 0.75);
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    span:not(.continue-reading) {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - var(--continue-btn-width) - 0.5vw);
    }
    .continue-reading {
      width: var(--continue-btn-width);
      position: absolute;
      right: 0;
      text-decoration: underline;
      padding-inline-start: 1rem;
      font-style: italic;
      font-weight: 600;
      transition: var(--transition);
      &:hover {
        font-style: normal;
        color: rgba(var(--bs-primary), 1);
        cursor: pointer;
      }
    }
  }
}
.selected-pills {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.55rem;
  &__pill {
    --pill-bg-color: rgba(var(--bs-primary), 1);
    --pill-bg-hover-color: rgba(var(--bs-primary), 1);
    --pill-color: var(--bs-white);
    --pill-hover-color: var(--bs-white);

    cursor: default;
    padding-block: 0.35rem;
    padding-inline: 0.75rem;
    border-radius: 0.25rem;
    background-color: rgba(var(--bs-primary), 1);
    color: var(--pill-color);
    display: flex;
    align-items: center;
    gap: 0.75rem;
    transition: var(--transition);
    border: solid 1px rgba(var(--bs-primary), 1);
    &:hover {
      background-color: #fff;
      color: rgba(var(--bs-primary), 1);
    }
  }
}

.modal {
  &-body {
    position: relative;
    .nav-pills {
      --bs-nav-pills-border-radius: 0.375rem;
      --bs-nav-pills-link-active-color: var(--bs-white);
      --bs-nav-pills-link-active-bg: rgba(var(--bs-primary), 1);
      display: flex;
      gap: 0.5rem;
      .nav-link {
        --bs-nav-link-color: var(--bs-dark);
        --bs-nav-bg-color: var(--bs-gray-200);
        --bs-nav-bg-hover-color: rgba(var(--bs-secondary), 1);
        --bs-nav-link-hover-color: var(--bs-dark);

        background-color: var(--bs-nav-bg-color);
        color: var(--bs-nav-link-color);
        z-index: 0;
        &.active {
          // box-shadow: 0 10px 20px -10px rgba(var(--bs-primary), .5) !important;
          z-index: 2;
        }
        &:hover {
          z-index: 1;
          background-color: rgba(var(--bs-primary), 1);
          color: var(--bs-white);
        }
      }
    }
    .tab-pane {
      position: relative;
      height: 100%;
    }
    &-tab-content {
      border-left: 1px solid var(--bs-gray-300);
      position: relative;
      padding-inline: calc(1rem + 1vw) calc(1rem + 0.25vw);
      padding-block-end: 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.tab-container {
  position: relative;
  @media (max-width: 767.85px) {
    padding: calc(var(--bs-gutter-x) / 2);
    .col-auto {
      padding: 0;
      margin-bottom: var(--bs-gutter-x);
    }
    .tab-content {
      padding: 0;
      .modal-body-tab-content {
        border-left: 0;
        border-top: 1px solid var(--bs-gray-300);
        padding: var(--bs-gutter-x) 0 0;
      }
    }
  }
}
.uploading-document-container {
  position: relative;
  padding: 1rem;
  border: 1px solid var(--bs-gray-400);
  background-color: var(--bs-gray-100);
  border-radius: 0.35rem;
}

#categoryModal {
  .col-auto,
  .tab-content {
    overflow-y: scroll;
    max-height: 500px;
  }
  .col-auto {
    width: 30%;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(var(--bs-primary));
  }
}

@media only screen and (max-width: 992px) {
  #categoryModal {
    .modal-dialog.modal-xl {
      max-width: calc(100% - 100px);
      margin-inline: auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  #categoryModal {
    .col-auto {
      width: 100%;
    }
    .tab-content {
      width: 100%;
      flex-basis: 100%;
    }
  }
  .modal-body-tab-content {
    border-left: 0;
    padding: 10px;
  }
}

@media only screen and (max-width: 600px) {
  #categoryModal {
    .modal-dialog.modal-xl {
      max-width: calc(100% - 30px);
    }
  }
}

.align-pills {
  display: flex;
  gap: 0.55rem;
  flex-wrap: wrap;
}
</style>
