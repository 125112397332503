<template>
  <div class="container registration-conformation-form">
    <div class="row">

      <div class="col-xl-10 offset-xl-1">
        <div class="conformation-container">
            <div class="form-group m-form__group m-form__section m-form__section--first">
            <br>
            <div style="text-align: center;" class="registration-conformation-banner">

              <img :src="`https://testmaestro.index.ae/barcode.php?id=${getRegistrationData?.contact?.registration_id}`" alt="barcode">


                <div style="text-align: center;color: #000;font-size: 16px;"><strong>{{ getRegistrationData?.contact?.registration_id }}</strong>
                <br>
                </div>


                <ul class="list-group">


                <li class="list-group-item heading">

                    <p class="body" style="display: inherit;">
                    <strong>Attendee Information </strong></p>

                </li>

                <li class="list-group-item" v-if="getRegistrationData?.contact?.invoice_number">
                    <p class="header">Reference / Invoice Number : </p>
                    <p class="body">{{ getRegistrationData?.contact?.invoice_number }}</p>
                </li>


                <li class="list-group-item" v-if="getRegistrationData?.contact?.registration_category">
                    <p class="header">Category : </p>
                    <p class="body">
                      {{ getRegistrationData?.contact?.registration_category?.name }}
                    </p>
                </li>


                <li class="list-group-item" v-if="getRegistrationData?.contact?.email">
                    <p class="header">Email Address : </p>
                    <p class="body">{{ getRegistrationData?.contact?.email }}</p>
                </li>


                <li class="list-group-item" v-if="getRegistrationData?.contact?.first_name">
                    <p class="header">First Name : </p>
                    <p class="body">{{ getRegistrationData?.contact?.first_name }}</p>
                </li>


                  <li class="list-group-item" v-if="getRegistrationData?.contact?.last_name">
                    <p class="header">Last Name : </p>
                    <p class="body">{{ getRegistrationData?.contact?.last_name }}</p>
                  </li>

                <li class="list-group-item" v-if="getRegistrationData?.contact?.mobile">
                    <p class="header">Mobile Phone : </p>
                    <p class="body">{{ getRegistrationData?.contact?.mobile }}</p>
                </li>


                <li class="list-group-item" v-if="getRegistrationData?.contact?.nationality">
                    <p class="header">Nationality : </p>
                    <p class="body">{{ getRegistrationData?.contact?.nationality?.name }}</p>
                </li>

                <li class="list-group-item" v-if="getRegistrationData?.contact?.age_group">
                    <p class="header">Age group : </p>
                    <p class="body">{{ getRegistrationData?.contact?.age_group?.name }}</p>
                </li>


                <li class="list-group-item" v-if="getRegistrationData?.contact?.company">
                    <p class="header">Company : </p>
                    <p class="body">{{ getRegistrationData?.contact?.company }}</p>
                </li>


                <li class="list-group-item" v-if="getRegistrationData?.contact?.phone">
                    <p class="header">Work Phone : </p>
                    <p class="body">{{ getRegistrationData?.contact?.phone }}</p>
                </li>

                <li class="list-group-item" v-if="getRegistrationData?.contact?.address">
                    <p class="header">Address : </p>
                    <p class="body">{{ getRegistrationData?.contact?.address }}</p>
                </li>


                <li class="list-group-item" v-if="getRegistrationData?.contact?.country?.country">
                    <p class="header">Country : </p>
                    <p class="body">{{ getRegistrationData?.contact?.country?.country }}</p>
                </li>

                <li class="list-group-item" v-if="getRegistrationData?.contact?.zip_code">
                    <p class="header">Zip/Postal : </p>
                    <p class="body">{{ getRegistrationData?.contact?.zip_code }}</p>
                </li>

                <li class="list-group-item" v-if="getRegistrationData?.contact?.state?.state">
                    <p class="header">State : </p>
                    <p class="body">{{ getRegistrationData?.contact?.state?.state }}</p>
                </li>

                <li class="list-group-item" v-if="getRegistrationData?.contact?.city?.city">
                    <p class="header">City : </p>
                    <p class="body">{{ getRegistrationData?.contact?.city?.city }}</p>
                </li>
                </ul>
                <div class="m-section__content table-settings" style="margin:10px 0;">
                    <li class="list-group-item heading">
                        <p class="body" style="display: inherit;"><strong>Selection </strong></p>
                    </li>
                    <table class="table table-inverse">
                        <thead>
                        <tr>
                            <th class="right">#</th>
                            <th class="left">Selection(Category)</th>
                            <th class="right">Cost</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td scope="row right">1</td>
                            <td class="left">{{ getRegistrationData?.contact?.registration_category?.name }}</td>
                            <td class="right">{{ getRegistrationData?.contact?.currency?.name }} {{ getRegistrationData?.contact?.registration_category.price }}</td>
                        </tr>
<!--                        <tr>-->
<!--                            <td scope="row right">2</td>-->
<!--                            <td class="left">Dinner</td>-->
<!--                            <td class="right">AED 9.60</td>-->
<!--                        </tr>-->

<!--                        <tr>-->
<!--                            <td scope="row right">4</td>-->
<!--                            <td class="left">Pepsi</td>-->
<!--                            <td class="right">AED 9.80</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <th scope=""></th>-->
<!--                            <th class=" left">Total</th>-->
<!--                            <th class="right">AED 133.80</th>-->
<!--                        </tr>-->
                        </tbody>
                      <thead v-if="getRegistrationData?.contact?.registration_items.length">
                        <tr>
                          <th class="right">#</th>
                          <th class="left">Selection(Items)</th>
                          <th class="right">Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr :key="key" v-for="(item,key) in getRegistrationData?.contact?.registration_items">
                        <td scope="row right">{{ ++key}}</td>
                        <td class="left">{{ item.item.name }}</td>
                        <td class="right">{{ getRegistrationData?.contact?.currency?.name }} {{ item.price }}</td>
                      </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th class="right">#</th>
                          <th class="left">Selection(Options)</th>
                          <th class="right">Cost x Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr :key="key" v-for="(item,key) in getRegistrationData?.contact?.registration_options">
                        <td scope="row right">{{ ++key}}</td>
                        <td class="left">{{ item.name }}</td>
                        <td class="right">{{ getRegistrationData?.contact?.currency?.name }} {{ item.price }} x {{ item.quantity }}</td>
                      </tr>
                      </tbody>
                    </table>
                </div>
<!--                <div class="m-section__content table-settings " style="margin:10px 0;">-->
<!--                    <li class="list-group-item heading">-->
<!--                        <p class="body" style="display: inherit;"><strong>Statement </strong></p>-->
<!--                    </li>-->
<!--                    <div class="mobile-scroll">-->
<!--                        <table class="table table-inverse">-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                                <th class="right">#</th>-->
<!--                                <th class="left">Date</th>-->
<!--                                <th class="left">Selection</th>-->
<!--                                <th class="right">Amount</th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->


<!--                            <tr>-->
<!--                                <td scope="row right">1</td>-->
<!--                                <td class="left">2023-02-21 06:58:42</td>-->
<!--                                <td class="left">9</td>-->
<!--                                <td class="right">AED 130.00</td>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <td scope="row right">1</td>-->
<!--                                <td class="left">2023-02-21 06:58:42</td>-->
<!--                                <td class="left">9</td>-->
<!--                                <td class="right">AED 130.00</td>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <th scope="row right"></th>-->
<!--                                <th class=" left">Total</th>-->
<!--                                <th class=" left"></th>-->
<!--                                <th class="right" style="float:right"> Balance: AED 3.80 </th>-->
<!--                            </tr>-->

<!--                            </tbody>-->
<!--                        </table>-->
<!--                    </div>-->
<!--                </div>-->


            </div>
            </div>
        </div>
      </div>


      <div id="viewerContent" style="width: 100%; min-height: 10px;"></div>

      <div class="my-loading" id="loading" style="display:none">Loading…</div>

    </div>
  </div>

</template>

<script>
export default {
  name: "RegistrationConfirmationTestView",
  computed:{
    getRegistrationData(){
      return this.$store.getters.getRegistrationResponse;
    }
  }
}
</script>

<style scoped>

</style>