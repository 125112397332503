<template>
  <div id="app">
    <Header v-if="getRouteName !== '404'" />
    <!--    <section class="form-sec">-->
    <!--      <div class="container">-->
    <!--        <div class="form-sec-container">-->
    <!--          <VerificationBlock v-if="emailVerified" />-->
    <!--          <RegistrationForm v-else />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
    <Preloader v-if="loading" />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";

import Preloader from "./components/PreLoader.vue";
// import RegistrationForm from './components/RegistrationForm.vue';
// import VerificationBlock from './components/tabs-data/VerificationBlock.vue';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  name: "App",
  // components: { Header, Footer, VerificationBlock, RegistrationForm },
  components: { Header, Footer, Preloader },
  data() {
    return {
      emailVerified: true,
      loading: true,
      bodyScript: "",
      headerScript: "",
    };
  },
  computed: {
    getRouteName() {
      return this.$route.name;
    },
    getEdition() {
      return this.$store.getters.getEditionData;
    },
  },
  watch: {
  },
  async mounted () {
    setTimeout(() => {
      this.loading = false; // Set to false when data is loaded
    }, 3500);
  },
  methods: {}
};
</script>

<style lang="scss">
@import "https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.2.3/css/bootstrap.min.css";
// @import '@/assets/scss/style.scss';
.form-sec {
  position: relative;
  &-container {
    --block-padding-y: calc(1.25rem + 1vw);
    --block-padding-x: calc(1rem + 1vw);
    position: relative;
    background-color: var(--bs-white);
    border-radius: 0 0 calc(var(--base-border-radius) * 0)
      calc(var(--base-border-radius) * 0);
    box-shadow: 0px 3.4px 2.7px rgba(0, 0, 0, 0.016),
      0px 8.7px 6.9px rgba(0, 0, 0, 0.022),
      0px 17.7px 14.2px rgba(0, 0, 0, 0.028),
      0px 36.5px 29.2px rgba(0, 0, 0, 0.034), 0px 100px 80px rgba(0, 0, 0, 0.05);
    display: flow-root;
  }
}
</style>
