<template>
    <div class="container-fluid sponsors-partners" v-if="is_logo">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="heading">
                <h4>Our Sponsors and Partners</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="logo-list">
                <ul>
                    <li v-for="partner in this.$store.getters.get_partners_sponsors_state" :key="partner.id">
                    <img :src="image_base_url+partner.logo_thumbnail" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>


<script>

  import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
  import gql from "graphql-tag";
  export default {
    name: "PartnersAndSoponsersLogos",
    components: { },
    data() {
      return {
        image_base_url:process.env.VUE_APP_Image_url,
        is_logo:false
      };
    },
    methods: {
       getPartnersAndSponsorslogo(){
        const variables = {
                event: this.$route.params.event_id?this.$route.params.event_id:this.$route.query.event_id,
                type: this.$route.params.type?this.$route.params.type:this.$route.query.type,
                };
        this.$apollo.query({
          query: gql`
                    query partners_sponsors($event: String! $type: String!){
                        partners_sponsors(event: $event type: $type){
                          logo_thumbnail
                        }
                    }`,
                    variables,
            }).then((data) => {
              if(data?.data?.partners_sponsors){
                if(data?.data?.partners_sponsors.length > 0){
                 this.is_logo=true;
                 this.$store.commit("set_partners_sponsors_state",data?.data?.partners_sponsors);
                }
              }
        })
      },
    },
    mounted() {
    if(this.$store.getters.get_partners_sponsors_state){
      this.getPartnersAndSponsorslogo();
    }  
  },
  };
  </script>
  
  <style lang="scss">
  
  </style>