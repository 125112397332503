var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-selection"},[_c('div',{staticClass:"category-selection__list"},[(_vm.shouldDisplayMainDev)?_c('h6',{staticClass:"mt-2"},[_vm._v("Selections:")]):_vm._e(),_vm._l((_vm.sortedOptions(_vm.dataList)),function(item,x){return _c('div',{key:_vm.customKey + x},[(!item.options.length && item.user_selection == 1)?_c('div',{staticClass:"category-selection__list__item",class:{
          selected: item.state,
          disabled: item.disabled,
          'pre-selected': item.pre_selected,
        }},[_c('b-form-checkbox',{attrs:{"id":item.item_id.toString(),"name":item.item?.name,"checked":item.check},on:{"change":function($event){return _vm.getItemsData(item.check, item.price, 'item', item?.options, x)}},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}},[_vm._v(" "+_vm._s(item.item?.name)+" ("+_vm._s(_vm.category.currency?.name)+" "+_vm._s(item.price)+") ")])],1):(item.options.length && item.user_selection == 1)?_c('div',{staticClass:"category-selection__list__item",class:{
          selected: item.state,
          disabled: item.disabled,
          'pre-selected': item.pre_selected,
        }},[_c('b-form-checkbox',{attrs:{"id":item.item_id.toString(),"name":item.item?.name,"checked":item.check},on:{"change":function($event){return _vm.getItemsData(item.check, item.price, 'item', item?.options, x)}},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}},[_vm._v(" "+_vm._s(item.item?.name)+" ("+_vm._s(_vm.category.currency?.name)+" "+_vm._s(item.price)+") ")]),_vm._l((item.options),function(option,i){return _c('span',{key:i},[(option.user_selection == 1 && item.check)?_c('div',{staticClass:"category-selection__list__sub"},[_c('div',{staticClass:"category-selection__list__sub__items"},[_c('div',{staticClass:"category-selection__list__sub__items__checkbox",class:{
                  'quantity-block': option.state && !option.quantity < 1,
                  checked: option.state,
                }},[_c('b-form-checkbox',{attrs:{"id":option.id.toString(),"name":option.name},on:{"change":function($event){return _vm.getItemsData(
                      option.check,
                      option.price,
                      'option',
                      item?.options,
                      i
                    )}},model:{value:(option.check),callback:function ($$v) {_vm.$set(option, "check", $$v)},expression:"option.check"}},[_vm._v(" "+_vm._s(option.name)+" ("+_vm._s(_vm.category.currency.name)+" "+_vm._s(option.price)+") ")]),(option.check && option.multiple_quantity)?_c('div',{staticClass:"input-block",class:{ error: option.quantity < 1 }},[_c('div',{staticClass:"d-flex gap-2"},[_c('label',{staticClass:"mb-0 flex-shrink-0",attrs:{"for":""}},[_vm._v("Quantity:")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"minuse",on:{"click":function($event){return _vm.quantityDecrement(option.id)}}},[_c('i',{staticClass:"fa-solid fa-minus"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.quantity),expression:"option.quantity"}],class:{ error: option.quantity < 1 },attrs:{"type":"number","inputmode":"numeric","readonly":""},domProps:{"value":(option.quantity)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(option, "quantity", $event.target.value)},function($event){return _vm.quantityValidation(option.quantity)}]}}),_c('span',{staticClass:"plus",on:{"click":function($event){return _vm.quantityIncrement(option.id)}}},[_c('i',{staticClass:"fa-solid fa-plus"})])])]),(option.quantity < 1)?_c('small',{staticClass:"[ d-flex gap-2 align-items-center ]"},[_c('i',{staticClass:"fa-solid fa-triangle-exclamation"}),_vm._v(" "+_vm._s(_vm.errorState_message)+" ")]):_vm._e()]):_vm._e()],1)])]):_vm._e()])})],2):(item.options.length && item.user_selection == 0)?[_vm._l((item.options),function(option,i){return [(option.user_selection == 1)?_c('div',{key:i,staticClass:"category-selection__list__item",class:{
              selected: item.state,
              disabled: item.disabled,
              'pre-selected': item.pre_selected,
            }},[_c('div',{staticClass:"category-selection__list__sub"},[_c('div',{staticClass:"category-selection__list__sub__items"},[_c('div',{staticClass:"category-selection__list__sub__items__checkbox",class:{
                    'quantity-block': option.state && !option.quantity < 1,
                    checked: option.state,
                  }},[_c('b-form-checkbox',{attrs:{"id":option.id.toString(),"name":option.name},on:{"change":function($event){return _vm.getItemsData(
                        option.check,
                        option.price,
                        'option',
                        item?.options,
                        i
                      )}},model:{value:(option.check),callback:function ($$v) {_vm.$set(option, "check", $$v)},expression:"option.check"}},[_vm._v(" "+_vm._s(option.name)+" ("+_vm._s(_vm.category.currency.name)+" "+_vm._s(option.price)+") ")]),(option.check && option.multiple_quantity)?_c('div',{staticClass:"input-block",class:{ error: option.quantity < 1 }},[_c('div',{staticClass:"d-flex gap-2"},[_c('label',{staticClass:"mb-0 flex-shrink-0",attrs:{"for":""}},[_vm._v("Quantity:")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"minuse",on:{"click":function($event){return _vm.quantityDecrement(option.id)}}},[_c('i',{staticClass:"fa-solid fa-minus"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.quantity),expression:"option.quantity"}],class:{ error: option.quantity < 1 },attrs:{"type":"number","inputmode":"numeric","readonly":""},domProps:{"value":(option.quantity)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(option, "quantity", $event.target.value)},function($event){return _vm.quantityValidation(option.quantity)}]}}),_c('span',{staticClass:"plus",on:{"click":function($event){return _vm.quantityIncrement(option.id)}}},[_c('i',{staticClass:"fa-solid fa-plus"})])])]),(option.quantity < 1)?_c('small',{staticClass:"[ d-flex gap-2 align-items-center ]"},[_c('i',{staticClass:"fa-solid fa-triangle-exclamation"}),_vm._v(" "+_vm._s(_vm.errorState_message)+" ")]):_vm._e()]):_vm._e()],1)])])]):_vm._e()]})]:_vm._e()],2)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }