<template>
  <div class="container-fluid registration-screen">
    <div class="container">
      <div class="white-bg">
        <AddSlider registration_tab="Email Varification - LinkedIn" />
        <div class="row justify-content-center">

          
          <div class="col-md-8">
            <div class="heading">
              <h4>Welcome to Online Registration</h4>
            </div>

              <div class="alert alert-danger text-center" role="alert" v-if="this.$store.getters.getAlready_submited">
                <p style="margin: 0;">{{ this.$store.getters.getAlready_submited }}</p>
              </div>

            <div class="register-with-linkedin">
              <p>Select one of the options below to begin your registration</p>
              <ul>
                <li class="email" @click="loginWithEmail()">
                  <a href="Javascript:;">
                    <img src="../../public/images/email.svg" alt="" /> Register
                    with your Email</a
                  >
                </li>
                <li class="_or"><span>OR</span></li>
                <li v-if="show_twitter_linkedin" @click="loginWithSocialMedia('linkedin-openid')" class="mb-0 linkedIn">
                  <a href="javascript:;">
                    <img src="../../public/images/linkedin.svg" alt="" />
                    Register with Linkedin</a
                  >
                </li>
                <li v-if="show_twitter_login" @click="loginWithSocialMedia('twitter')" class="mb-0 x">
                  <a href="javascript:" style="background-color:#000">
                    <img src="../../public/images/twitter.svg" alt="" />
                    Register with Twitter</a
                  >
                </li>
                <li v-if="show_facebook_login" @click="loginWithSocialMedia('facebook')" class="mb-0 facebook">
                  <a href="javascript:;">
                    <img src="../../public/images/facebook.svg" alt="" />
                    Register with Facebook</a
                  >
                </li>
                <label for="confirm" :class="errors_term_condation && 'error'" class="terms-condition">
                  <input v-model="term_condation" :key="dynmaicKey" type="checkbox" id="confirm" /> Agree to
                  <a :href="getEdition?.edition_data?.terms_url" target="_blank"> Terms & Conditions. </a>
                </label>
                
              </ul>
              <span v-if="getEdition?.url_details?.specific_information">
              <p v-html="getEdition.url_details.specific_information"></p>
              </span>
            </div>
          </div>
        </div>
        <PartnersAndSoponsersLogos />
      </div>
    </div>
  </div>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import AddSlider from '../components/AddSlider.vue';
import PartnersAndSoponsersLogos from '../components/PartnersAndSoponsersLogos.vue';
import { trackPageView } from '@/utils/analytics'
export default {
  name: "LoginStep",
  beforeCreate() {
    let request_data = {
      type: this.$route?.query?.type ?? this.$route?.params?.type,
      event: this.$route?.query.event ?? this.$route?.params?.event_id
    }
    this.$store.dispatch("getEditionData", request_data)
  },
  components: {PartnersAndSoponsersLogos,AddSlider},
  data() {
    return {
      dynmaicKey:0,
      term_condation:'',
      errors_term_condation:''
    };
  },
  watch: {
    term_condation() {
      if(this.term_condation != 'true') {
        this.errors_term_condation = ""
        this.dynmaicKey++;
      }
    }
  },
  computed: {
    getEdition() {
      return this.$store.getters.getEditionData
    },
    show_twitter_linkedin() {
      return this.$store.getters.getEditionData?.url_details?.is_linkedin ?? false
    },
    show_twitter_login () {
      return this.$store.getters.getEditionData?.url_details?.login_with_twitter ?? false
    },
    show_facebook_login () {
      return this.$store.getters.getEditionData?.url_details?.login_with_facebook ?? false
    },
  },
  mounted() {
    trackPageView(this.$gtag,  this.$route,"Step 1 - Online Registration (LinkedIn)");
    // this.getEditionData();
  },
  methods: {
    loginWithEmail() {
      this.$router.push(
        "/" + this.$route.params.event_id + "/" + this.$route.params.type+'?manual=1'
      );
    },
    loginWithSocialMedia(socialType) {
      if(!this.term_condation){
       this.errors_term_condation='Please check the Terms & Conditions checkbox.';
       return false;
      }
      window.location.href =
      process.env.VUE_APP_Base_url +
      "/register/social-media/" +
      this.$route.params.event_id +
      "/" +
      this.$route.params.type+'/'+socialType;

    },
  },
  
};
</script>

<style lang="scss">
.registration-screen {
  .white-bg {
    background-color: #fff;
    display: flow-root;
    padding-block: 30px;
  }
}
</style>
