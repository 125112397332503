<template>
  <div class="promo-code-container">
    <div class="promo-code__icon hidden-xs">
      <img src="@/../public/images/promo-code-icon.svg" alt="Promo Code Icon" />
    </div>
    <div class="promo-code__content">
      <h5 class="promo-code__content__header" :class="{ 'active': show_promo_input }" @click="showPromoHandler">
        Have a Promo Code? <i class="fa-solid fa-angle-down"></i>
      </h5>
      <div class="promo-code__content__body">
        <div class="d-flex gap-2 align-items-center justify-content-end" v-if="show_promo_input">
          <b-form-input v-if="!showRemoveBtn" v-model="promo_code" placeholder="Enter Code"></b-form-input>
          <b-button variant="primary" size="sm" v-if="!showRemoveBtn" @click="promoValidation('apply')">Apply</b-button>
          <b-button size="sm" v-if="showRemoveBtn" @click="promoValidation('remove')">Remove</b-button>
        </div>
        <p class="text-danger" v-if="promo_code_error">{{ promo_code_error }}</p>
        <p class="text-success" v-else>{{ promo_success_msg }}</p>
      </div>      
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "PromoCode",
  props: {
    promoCode: {},
    request_data:{}
  },
  data() {
    return {
      prev_vat:0,
      percentage_amount:0,
      sub_total:0,
      inclusive_of_tax:'',
      vat:0,
      show_promo_input:false,
      promo_code: this.promoCode,
      promo_code_error: null,
      promo_success_msg: null,
      showRemoveBtn:false,
      promotion_code_status:false,
    }
  },
  methods: {
    showPromoHandler() {
      this.promo_success_msg = '';
      this.promo_code_error = '';
      if(this.show_promo_input) {
        this.show_promo_input = false;
      } else {
        this.show_promo_input = true;
      }
    },
    promoValidation(type) {
      
      if(type=="apply" && (this.promo_code === "" || this.promo_code === null || this.promo_code==undefined)){
         this.promo_code_error = "Promo code field required.";
         return;
      }
      if(type=="remove"){
        this.promotion_code_status=true;
        this.promo_success_msg='';
      }
      this.promo_code_error = "";
      const request_data = this.$store.getters.getCategoryOptionsRequestData;
      console.log("data_request:::::::::",request_data);
      request_data['promotion_code_input'] = this.promo_code;
      request_data['promotion_code_status']=this.promotion_code_status;
      console.log("request_data:::::::::::::;",request_data)
      this.$apollo
          .mutate({
            mutation: gql`
            mutation get_category_items_options_price(
              $registration_category_id: Int!
              $encrypted_registration_id: String!
              $item_check: [Int]
              $option_check: [Option]
              $event_id: Int!
              $edition_id: Int!
              $membership_code: String
              $step: String
#              $professional_id: String
              $promotion_code_input: String
              $promotion_code_status:Boolean
            ) #                $professional_id: Upload
            {
              get_category_items_options_price(
                registration_category_id: $registration_category_id
                encrypted_registration_id: $encrypted_registration_id
                item_check: $item_check
                option_check: $option_check
                event_id: $event_id
                edition_id: $edition_id
                membership_code: $membership_code
                step: $step
#                professional_id: $professional_id
                promotion_code_input: $promotion_code_input
                promotion_code_status:$promotion_code_status
              )
            }
          `,
            // mutation: this.$options.apollo.get_category_items_options_price.,
            variables: request_data,
          })
          .then((res) => {
            this.percentage_amount=0;
            this.sub_total=0;
            const data = JSON.parse(res.data.get_category_items_options_price);
            console.log(data,'datadatadatadata')
            this.vat = data?.registration_data?.edition?.vat;
            this.inclusive_of_tax =data?.registration_data?.edition?.inclusive_of_tax
            const percentage_tax = this.vat / 100;
            console.log("category Payable",data);
            this.promo_code_error = null;
            this.promo_success_msg = null;

            if (data.code === 200 && data.promotion_applied){
              this.promo_success_msg = 'Apply promotion code successfully';
              if(type=='apply'){
                this.showRemoveBtn=true;
                this.total_price = data.registration_data?.r_payable_amount;
                this.discount=data.registration_data.r_discount_amount;
                var rItemPrice = data.registration_data?.registration_items || [];
                rItemPrice.forEach(item => {
                this.discount = this.discount + item.pivot.r_discount_amount;
                this.total_price = this.total_price + item.pivot.r_payable_amount;
              });
              var rOptionPrice = data.registration_data?.registration_options || [];
              rOptionPrice.forEach(option => {
                this.total_price = this.total_price + (option.pivot.r_payable_amount*option.pivot.qunatity);
                this.discount += (option.pivot.r_discount_amount*option.pivot.qunatity);
              });
              this.percentage_amount = this.total_price * percentage_tax;
              this.percentage_amount = parseFloat(this.percentage_amount).toFixed(2);
              this.sub_total = this.total_price-this.percentage_amount;
              this.discount=this.discount.toFixed(2);
              this.$store.commit("setDiscount",this.discount);
              this.$store.commit("setItemAndOptionPrice",this.total_price);
              this.promo_code='';
              this.tax_calculation();
              }
             
              
            }else {
              this.promo_code_error = data.promotion_message;
            }
            if(data.code && data.promotion_removed){
              this.promo_success_msg = "Remove promotion code Successfully";
              this.showRemoveBtn=false;
          this.total_price=data?.options_price+data?.items_price+data.registration_data?.r_gross_amount;
          this.percentage_amount = this.total_price * percentage_tax;
          this.percentage_amount = parseFloat(this.percentage_amount).toFixed(2);
        if (this.inclusive_of_tax) {
          this.sub_total = this.total_price - this.percentage_amount;
        }
        else {
          this.sub_total = this.total_price;
          this.sub_total = parseFloat(this.sub_total).toFixed(2);
          this.total_price = parseFloat(this.total_price)+parseFloat(this.percentage_amount);
        }
              this.$store.commit("setSubTotal", this.sub_total);
              this.$store.commit("setVatAmount", this.percentage_amount);
              this.$store.commit("setTooltipMessage", `The prices above are inclusive of ${this.$store.getters.getVat}% Value added tax; ${this.$store.getters.getCurrency} ${this.percentage_amount}`);
              this.$store.commit("setDiscount",0);
              this.$store.commit("setItemAndOptionPrice",this.total_price);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    tax_calculation() {
      const percentage_tax = (this.$store.getters.getVat / 100);
      this.percentage_amount = this.$store.getters.getItemAndOptionPrice * percentage_tax;
      this.sub_total = this.$store.getters.getItemAndOptionPrice - parseFloat(this.percentage_amount.toFixed(2));
      this.$store.commit("setTooltipMessage", `The prices above are inclusive of ${this.$store.getters.getVat}% Value added tax; ${this.$store.getters.getCurrency} ${this.percentage_amount.toFixed(2)}`);
      this.$store.commit("setSubTotal", this.sub_total);
      this.$store.commit("setVatAmount", this.percentage_amount.toFixed(2));
    },
  },
  watch: {

  }
}
</script>

<style lang="scss">
  .promo-code {
    &-container {
      --card-padding-x: 0.85rem;
      --card-padding-y: 0.6rem;
      --card-border-color: #ccc;
      --card-bg-color: #f5f5f5;
      --card-color: var(--bs-dark);
      --card-icon-size: 70px;

      @media (max-width: 767.85px) {
        --card-padding-x: 0.5rem;
        --card-padding-y: 0.5rem;
      }
      border-radius: 0.35rem;
      border: 1px solid var(--card-border-color);
      background-color: var(--card-bg-color);
      color: var(--card-color);
      width: 100%;
      padding-block: var(--card-padding-y);
      padding-inline: var(--card-padding-x);
      display: flex;
      align-items: center;
      gap: calc(1rem + 0.25vw);
    }
    &__icon {
      position: relative;
      max-width: var(--card-icon-size);
      transition: var(--transition);
    }
    &__content {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      
      &__header {
        margin-bottom: 0;
        width: 100%;
        gap: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 0.35rem;
        padding-block: var(--card-padding-y);
        cursor: pointer;
        transition: var(--transition);
        @media (max-width: 767.85px) {
          padding-inline: var(--card-padding-x);
        }
        &:hover, &.active {
          background-color: rgba(var(--bs-primary), 0.15);
          padding-inline: var(--card-padding-x);
        }
        i { transition: var(--transition); }
        &.active {
          i { rotate: 180deg; }
        }
      }
      &__body {
        .btn { height: calc(var(--base-input-element-height) / 1.2); }
        .form-control { height: var(--base-input-element-height); }
      }
    }
  }
</style>
