import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import routes from './routes'
import VueApollo from 'vue-apollo'
import apolloClient from "@/plugins/apollo-client";
import store from './store'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(VueApollo)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

const router = new VueRouter({
  mode: 'history',
  routes: routes
});

Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);

router.beforeEach(async (to, from, next) => {
  const payload = {
    event: to.params.event_id ?? to.query.event,
    type: to.query.type ?? to.params.type,
  }

  if (payload?.event && payload?.type) {
    await store.dispatch('getEditionData', {
      event: to.params.event_id ?? to.query.event,
      type: to.query.type ?? to.params.type,
    })
  }

  const seo_header_script = store.getters.getEditionData?.edition_data?.seo_header_script
  await Vue.nextTick()

  if (seo_header_script) {
    updateGTMId(seo_header_script)
  }

  next()
})

function extractGTMId (scriptContent) {
  if (!scriptContent) {
    return null
  }
  const match = scriptContent.match(/,\s*'(\w+-\w+)'\);/)
  return match ? match[1] : null
}

function updateGTMId (seo_header_script) {
  const gtmId = extractGTMId(seo_header_script)
  console.log('GTM ID:', gtmId)
  if (gtmId) {
    // Initialize VueGtag with the extracted GTM ID
    Vue.use(VueGtag, {
      config: { id: gtmId },
    })
  }
}

// Disable console.log if VUE_APP_DEBUG is false
const isDebugMode = process.env.VUE_APP_DEBUG === 'true';
if (!isDebugMode) {
  console.log = function () {};
}

new Vue({
  render: h => h(App),
  router: router,
  store,
  apolloProvider,
}).$mount('#app')
