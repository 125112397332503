<template>
  <div class="container-fluid modalsettings">
    <div class="">
      <div class="row">
        <div class="">
          <div class="share-content">
            <!-- <div class="row">
              <div class="col-md-12">
                <div class="cross-btn">
                    <button class="closeBtn" @click="$emit('hideShareArea')">X</button>
                </div>
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-5">
                <div class="select-image-for-share">
                  <VueSlickCarousel v-bind="imageSettings" v-if="social_media_images">
                    <div class="slide-item" v-for="images in social_media_images" :key="images">
                      <img :src="image_base_url+ images" alt="" />
                    </div>

                    <template #prevArrow="arrowOption">
                      <div class="custom-arrow slideCount">
                        {{ arrowOption.currentSlide + 1 }}/{{
                          arrowOption.slideCount
                        }}
                      </div>
                    </template>
                    <template #customPaging="page">
                      <div class="custom-dot">
                        {{ page }}
                      </div>
                    </template>
                  </VueSlickCarousel>
                  <div v-else-if="$store?.state?.edition_details?.edition_data?.edition_logo">
                    <img :src="image_base_url + $store?.state?.edition_details.edition_data.edition_logo" alt="">
                  </div>

                  <h5 v-if="social_media_images?.length > 1">Pick an image to help spread the world </h5>
                </div>
              </div>
              <div class="col-md-7">
                
                <div class="content">
                  <h4>Share with your friends and colleagues</h4>
                  <p v-if="social_media_description">
                    {{social_media_description}}
                  </p>
                  <p v-else-if="$store?.state?.edition_details?.edition_data?.description" v-html="$store?.state?.edition_details?.edition_data?.description"></p>

                  <h5>Copy the Share link manually</h5>
                  <div class="share-url">
                    <span class="icon">
                      <img src="../../public/images/link.svg" alt="" />
                    </span>
                    <input type="text" ref="urlInput" placeholder="Link" :key="count" :value="share_url" readonly />
                    <button @click="copyUrl">Copy</button>
                  </div>

                  <div
                      v-if="share_with_facebook && share_with_twitter && share_with_linkedin"
                      class="share-to"
                  >
                    <h5>Share this link via</h5>
                    <ul>
                      <li v-if="share_with_facebook">
                        <a target="__blank" :href="face_book_url" class="facebook">
                          <img src="../../public/images/facebook.svg" alt="" />
                          Facebook
                        </a>
                      </li>
<!--                      <li v-if="share_with_twitter && social_media_share_banner">-->
<!--                        <a v-if="!is_loading.twitter" class="x" href="javascript:" target="__blank"-->
<!--                           @click.prevent="onClickShareWithSocialMedia('TWITTER')">-->
<!--                          <img alt="" src="../../public/images/twitter.svg"/>-->
<!--                          Twitter-->
<!--                        </a>-->
<!--                        <a v-else class="x" href="javascript:">-->
<!--                          <i class="fa-solid fa-spinner"></i>-->
<!--                        </a>-->
<!--                      </li>-->
                      <li v-if="share_with_twitter">
                        <a :href="twitter_url" class="x" target="__blank">
                          <img alt="" src="../../public/images/twitter.svg"/>
                          Twitter
                        </a>
                      </li>
                      <li v-if="share_with_linkedin && social_media_share_banner">
                        <a v-if="!is_loading.linkedin" class="linkedin" href="javascript:"
                           @click.prevent="onClickShareWithSocialMedia('LINKEDIN')">
                          <img alt="" src="../../public/images/linkedin.svg"/>
                          Linkedin
                        </a>
                        <a v-else class="linkedin" href="javascript:">
                          <i class="fa-solid fa-spinner"></i>
                        </a>
                      </li>
                      <li v-if="share_with_linkedin && !social_media_share_banner">
                        <a :href="linkedin_url" class="linkedin" target="__blank">
                          <img alt="" src="../../public/images/linkedin.svg"/>
                          Linkedin
                        </a>
                      </li>
                    </ul>
                    <br>
                    <div v-if="response_message" class="alert alert-success alert-dismissible fade show" role="alert">
                      {{ response_message }}
                      <button aria-label="Close" class="close" type="button"
                              @click.prevent="response_message=null">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import gql from 'graphql-tag'



export default {
  name: "NewDesign",
  components: { VueSlickCarousel },
  data() {
    return {
      count:0,
      social_media_description:'',
      share_url:'',
      image_base_url: process.env.VUE_APP_Image_url,
      face_book_url: '',
      linkedin_url: '',
      twitter_url: '',
      social_media_images:'',
      settings: {
        dots: false,
        arrows: false,
        draggable: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      imageSettings: {
        dots: false,
        arrows: true,
        draggable: false,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
      },
      is_loading: {
        twitter: false,
        linkedin: false,
      },
      response_message: '',
    }
  },
  computed: {
    registration_id () {
      return this.$store?.state?.registration_response?.contact?.registration_id ?? null
    },
    header_logo () {
      const edition_data = this.$store.state.edition_details?.edition_data
      return edition_data?.logo ? process.env.VUE_APP_Image_url + edition_data.logo : null
    },
    shareable_image () {
      let image_url = this.image_base_url !== '' && this.social_media_images[this.count]
          ? this.image_base_url + this.social_media_images[this.count]
          : null

      if (!image_url) {
        image_url = this.header_logo
      }

      return image_url
    },
    social_media_share_banner () {
      return this.$store.getters.getEditionData?.edition_data?.social_media_share_image ?? false
    },
    share_with_linkedin() {
      return this.$store.getters.getEditionData?.url_details?.share_with_linkedin ?? false
    },
    share_with_twitter () {
      return this.$store.getters.getEditionData?.url_details?.share_with_twitter ?? false
    },
    share_with_facebook () {
      return this.$store.getters.getEditionData?.url_details?.share_with_facebook ?? false
    },
  },
  methods: {
    copyUrl() {
      const inputElement = this.$refs.urlInput;
      inputElement.select();
      inputElement.setSelectionRange(0, 99999);
      document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
    },

    getUrlsDetail() {
      setTimeout(() => {
        const baseUrl = process.env.VUE_APP_Shareable_Link;
        let event = this.$route.query.event ?? this.$route.params.event_id;
        this.share_url=baseUrl+event+"?image=0";
        // this.social_media_description=this.$store?.state?.edition_details?.url_details.social_media_description!=null?this.$store?.state?.edition_details?.url_details.social_media_description:'';
        this.social_media_description=this.$store?.state?.edition_details?.edition_data?.social_media_description!=null?this.$store?.state?.edition_details?.edition_data?.social_media_description:'';
        this.social_media_description= new DOMParser().parseFromString(this.social_media_description, 'text/html');
        this.social_media_description= this.social_media_description?.body?.textContent || '';
        if(this.$store?.state?.edition_details?.edition_data?.social_media_images){
          this.social_media_images= this.$store?.state?.edition_details?.edition_data?.social_media_images?.split(",");
        }
        this.face_book_url = "https://www.facebook.com/sharer/sharer.php?u=" + this.share_url;
        this.linkedin_url = "https://www.linkedin.com/sharing/share-offsite/?url=" + this.share_url;
        this.twitter_url = "https://twitter.com/intent/tweet?text=" + this.share_url;

        this.$nextTick(() => {
          const slickNextButton = document.querySelector('.slick-arrow.slick-next');
          const slickPreButton = document.querySelector('.slick-arrow.slick-prev');
          if (slickNextButton) {
            slickNextButton.addEventListener('click', this.handleSlickNextClick);
          }
          if (slickPreButton) {
            slickPreButton.addEventListener('click', this.handleSlickPreClick);
          }
        });
      }, 1000);

    },
    handleSlickNextClick() {
      const dynamicValue = 'Hello from child!'; // Replace this with your dynamic value
      this.$emit('valueChanged', dynamicValue);
    if(this.$store?.state?.edition_details?.edition_data?.social_media_images){
      var social_media_images= this.$store?.state?.edition_details?.edition_data?.social_media_images?.split(",");
      if(social_media_images?.length-1 > this.count){
        this.count++;
        let ogImageTag = document.querySelector('meta[property="og:image"]');
        if (!ogImageTag) {
          ogImageTag = document.createElement('meta');
          ogImageTag.setAttribute('property', 'og:image');
          document.head.appendChild(ogImageTag);
        }
        const baseUrl = process.env.VUE_APP_Shareable_Link;
        let event = this.$route.query.event ?? this.$route.params.event_id;
        this.share_url=baseUrl+event+"?image="+this.count;
        ogImageTag.setAttribute('content', process.env.VUE_APP_Image_url+social_media_images[this.count]);
      }
    }
    },
    handleSlickPreClick() {
      let ogImageTag = document.querySelector('meta[property="og:image"]');
    if (!ogImageTag) {
      ogImageTag = document.createElement('meta');
      ogImageTag.setAttribute('property', 'og:image');
      document.head.appendChild(ogImageTag);
    }
    if(this.$store?.state?.edition_details?.edition_data?.social_media_images){
      var social_media_images= this.$store?.state?.edition_details?.edition_data?.social_media_images?.split(",");
      if(this.count > 0){
        this.count--;
        const baseUrl = process.env.VUE_APP_Shareable_Link;
        let event = this.$route.query.event ?? this.$route.params.event_id;
        this.share_url=baseUrl+event+"?image="+this.count;
        ogImageTag.setAttribute('content', process.env.VUE_APP_Image_url+social_media_images[this.count]);
      }
    }
    },
    async  onClickShareWithSocialMedia (social_media) {
      if (!this.registration_id) {
        console.error('registration id not found')
        return false
      }
      this.is_loading[social_media.toLowerCase()] = true
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`
          mutation share_with_social_media(
                $registration_id: Int!
                $image_url: String,
                $social_type: SocialMediaTypeEnum!,
                $event_id:String,
                $type:String,
              ) {
                share_with_social_media(
                  registration_id: $registration_id
                  image_url: $image_url
                  social_type: $social_type
                  event_id:$event_id,
                  type:$type
                ){
                    message
                    status
                }
              }
            `,
          variables: {
            registration_id: this.registration_id,
            image_url: this.shareable_image,
            social_type: social_media,
            event_id: this.$route.params.event_id,
            type: this.$route.params.type,
          }
        })
        const data = response.data.share_with_social_media
        this.is_loading[social_media.toLowerCase()] = false
        if ((data.status === 400 || data.status === 401) && social_media === 'LINKEDIN') {
          this.loginWithLinkedIn()
        } else if ((data.status === 400 || data.status === 401) && social_media === 'TWITTER') {
          this.loginWithTwitter()
        } else if (data.status === 200) {
          this.response_message = data.message
        }
      } catch (error) {
        this.is_loading[social_media.toLowerCase()] = false
        console.error(error)
      }
    },
    loginWithLinkedIn () {
      let url = process.env.VUE_APP_Base_url + '/social-media/share/login/' + this.$route.params.event_id + '/' + this.$route.params.type + '/linkedin-openid/' + this.registration_id
      if (this.shareable_image) {
        url += '?image_url=' + encodeURIComponent(this.shareable_image)
      }
      window.location.href = url
    },
    loginWithTwitter () {
      let url = process.env.VUE_APP_Base_url + '/social-media/share/login/' + this.$route.params.event_id + '/' + this.$route.params.type + '/twitter/' + this.registration_id
      if (this.shareable_image) {
        url += '?image_url=' + encodeURIComponent(this.shareable_image)
      }
      window.location.href = url
    },
  },
  mounted () {
    this.getUrlsDetail()
    if (this.$route.query.status) {
      this.response_message = this.$route.query.status
    }
  }
};
</script>
  
<style lang="scss">
.cross-btn {
  width: 100%;
  text-align: right;
  display: none;

  .closeBtn {
    background-color: transparent;
    border: 0;
    font-size: 24px;
  }

}

.share-modal-body .cross-btn {
  display: block;
}

@media only screen and (max-width: 767px) {
  .share-modal-body {
    .col-md-7 {
      position: static;
    }
  }
  .share-modal-body .cross-btn {
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 9;
  }
}

.modalsettings {
  padding-inline: 0;
}

.fa-spinner {
  padding: 0 !important;
  width: 17px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  animation: spin 1s linear infinite;
  transform-origin: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>