<template>
    <div class="container registration-conformation-form">
      <div class="row">
  
        <div class="col-xl-10 offset-xl-1">
          <div class="conformation-container">
            <div class="form-group m-form__group m-form__section m-form__section--first w-100">
              <br>
              <div style="text-align: center;" class="registration-conformation-banner">
                <div class="alert alert-danger cancel-registration">
                 {{message}}
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
     
    </div>
  
  </template>
  
  <script>
  import { trackPageView } from '@/utils/analytics'

  export default {
    name: "RegistrationCancel",
    data() {
    return {
      message:'',
      manger_name:'',
      manager_number:'',
    };
  },
 methods:{
  setMessage(){
    this.manger_name=this.$store.state.edition_details?.edition_data?.edition_manager_name;
    this.manager_number=this.$store.state.edition_details?.edition_data?.edition_manager_phone?this.$store.state.edition_details?.edition_data?.edition_manager_phone:'';
    if(this.$route.query.dtcm){
      this.message='Your registration could not be confirmed. Please contact '+this.manger_name+' on ' + this.manager_number +  ' with registration ID : ' +this.$route.params.registration_id + ' for assistance'
    }else{
      this.message='Transaction has been cancelled. Please contact '+this.manger_name+' on ' + this.manager_number +  ' with registration ID : ' +this.$route.params.registration_id + ' for assistance'
    }
  }
 },
 mounted(){
   trackPageView(this.$gtag, this.$route,"Registration Cancel");
  this.setMessage();
 },
 watch:{
  getManger(){
    this.setMessage();
  }
 },
 computed: {
    getManger() {
      return this.$store.state.edition_details?.edition_data;
    },
  },
  
  }
  </script>
  
<style scoped>
  .cancel-registration {
    margin: 30px 0;
  }

</style>