<template>
  <section class="form-sec">
    <div class="container">
      <div class="form-sec-container">
        <div class="registration-form">
          <AddSlider :key="tabIndex" :registration_tab="tabIndex" />
          <ul class="registration-form__short-details">
            <li v-for="(info, index) in shortRegistrationInfo" :key="index">
              <span class="__label">{{ info.label }}:</span>
              <span class="__value">{{ info.value }}</span>
            </li>
          </ul>

          <b-tabs pills class="form-tab-container" nav-wrapper-class="form-tab-header mb-3"
            nav-class="form-tab-header-nav" content-class="form-tab-body" v-model="tabIndex">
            <Tab_AttendeeInformation :tabIndex="tabIndex" :registration_data="registration_data"
              @getIndex="tabIndex = $event" :disabled="true" />

            <Tab_CategoriesDetails :tabIndex="tabIndex" @getIndex="tabIndex = $event"
              :registration_data="registration_data" :disabled="true" />

            <Tab_Payment :tabIndex="tabIndex" @getIndex="tabIndex = $event" :disabled="true" />
          </b-tabs>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import Tab_AttendeeInformation from '../components/tabs-data/Tab_AttendeeInformation.vue';
import Tab_CategoriesDetails from '../components/tabs-data/Tab_CategoriesDetails.vue';
import Tab_Payment from '../components/tabs-data/Tab_Payment.vue';
import AddSlider from '../components/AddSlider.vue';
import gql from 'graphql-tag'
import { trackPageView } from '@/utils/analytics'

export default {
  name: "RegistrationForm",
  data() {
    return {
      tabIndex: 0,
      registration_status: {
        1: 'submitted',
        2: 'confirmed',
        3: 'cancelled',
        4: 'wait_list',
        5: 'attended',
        6: 'no_show',
        7: 'declined',
        8: 'incomplete',
        9: 'in_active',
        10: 'in_progress'
      },
      shortRegistrationInfo: [
        { label: "Reg. ID", value: "RE56889", },
        { label: "Status", value: "Pending", },
        { label: "Reg. Email", value: "test@index.ae", }
      ],
      registration_data: {},
      edition_details: {},
      event: this.$route.params.event_id,
      type: this.$route.query.type,
      category_pricing_details: {}
    };
  },
  watch: {
    tabIndex(value) {
      if (value == 1) {
        trackPageView(this.$gtag, this.$route, 'Step 3 - Category Selection')
      } else if (value == 2) {
        trackPageView(this.$gtag, this.$route, 'Step 4 - Payment Screen')
      }
      this.getRegistrationData();
    },
  },
  mounted() {
    trackPageView(this.$gtag, this.$route, 'Step 2 - Registration Basic Information')
    if (Object.keys(this.$store.state.edition_details).length === 0) {
      this.getEditionData();
    }
    this.edition_details = this.$store.state.edition_details;
    this.getRegistrationData();
    
  },
  methods: {
    

    removeUnderscoreAndCapitalize(str) {
      // Remove underscores and split the string into an array of words
      const words = str.split('_');

      // Capitalize the first letter of each word
      const capitalizedWords = words.map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });

      // Join the words back together without underscores
      const result = capitalizedWords.join(' ');

      return result;
    },
    hexToRgb(hex) {
      hex = hex.replace('#', '');
      var r = parseInt(hex.length == 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
      var g = parseInt(hex.length == 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
      var b = parseInt(hex.length == 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
      const value = r + ', ' + g + ', ' + b;
      return value;
    },
    getEditionData() {
      this.$store.dispatch('getEditionData', {
        event: this.event,
        type: this.type
      });
    },
    getRegistrationData() {
      let token = this.$route.params.token;
      let event = this.$route.params.event_id ?? this.$route.query.event;
      let type = this.$route.query.type ?? this.$route.params.type;
      this.$apollo.mutate({
        mutation: gql`
            mutation get_registration_data($token: String!, $event: String!, $type: String!){
                get_registration_data(token: $token, event: $event, type: $type)
            }
        `,
        variables: {
          token: token,
          event: event,
          type: type
        }
      }).then((data) => {
        const res = JSON.parse(data.data.get_registration_data);
        if (res.error && res.error_reason == 1103) {
          this.$store.commit('setAlready_submited', res.error);
          this.$router.push('/' + event + '/' + type);
        }
        if(res.email_verification_message) {
          this.$store.commit('setAlready_submited', 'Please verify your email using the latest link provided, as the previous link has expired.');
          this.$router.push('/' + event + '/' + type);
        }
        this.$store.commit('setRegistrationOrContact', res.registrationOrContact);
        this.registration_data = res;
        // console.log("registration data status:::",this.removeUnderscoreAndCapitalize(this.registration_status[res.registration_status_id]));
        this.shortRegistrationInfo[0].value = res.registration_id;
        this.shortRegistrationInfo[1].value = this.removeUnderscoreAndCapitalize(this.registration_status[res.registration_status_id]);
        this.shortRegistrationInfo[2].value = res.email;
      }).catch((error) => {
        if (typeof type === 'undefined' || typeof event === 'undefined' || typeof token === 'undefined') {
          this.$router.push({
            name: '404'
          })
        }
        console.log(error);
      });
    },



  },
  components: { Tab_AttendeeInformation, Tab_CategoriesDetails, Tab_Payment, AddSlider }
}
</script>

<style lang="scss">
.registration-form {
  position: relative;
  --info-sticky-height: 45px;
  --info-bg-color: rgba(var(--bs-secondary), 1);
  --info-color: var(--bs-white);

  &__short-details {
    --pipe-spacing: calc(0.5rem + 1vw);
    margin: 0;
    padding: 0;
    position: sticky;
    top: 0;
    height: var(--info-sticky-height);
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    z-index: 10;
    // background-color: var(--base-bg-color);
    backdrop-filter: blur(5px);
    color: var(--bs-dark);
    background: linear-gradient(90deg,
        rgba(var(--base-bg-color-rgb), 0) 0%,
        rgba(var(--base-bg-color-rgb), 0.25) 10%,
        rgba(var(--base-bg-color-rgb), 0.7) 25%,
        rgba(var(--base-bg-color-rgb), 0.95) 35%,
        rgba(var(--base-bg-color-rgb), 0.98) 45%,
        rgba(var(--base-bg-color-rgb), 1) 50%,
        rgba(var(--base-bg-color-rgb), 0.98) 55%,
        rgba(var(--base-bg-color-rgb), 0.95) 65%,
        rgba(var(--base-bg-color-rgb), 0.7) 75%,
        rgba(var(--base-bg-color-rgb), 0.25) 90%,
        rgba(var(--base-bg-color-rgb), 0) 100%);

    li {
      display: flex;
      position: relative;
      gap: 0.5rem;

      .__value {
        font-weight: 700;
      }

      &:not(:last-child):after {
        content: "|";
        margin-inline: var(--pipe-spacing);
        display: inline-block;
        opacity: 0.5;
      }
    }

    @media (max-width: 991.8px) {}

    @media (max-width: 767.85px) {
      white-space: nowrap;
      overflow-x: scroll;
      justify-content: flex-start;
      margin-inline-start: -3.25vw;
      width: 100vw;
      padding-inline: var(--bs-gutter-x);
    }
  }

  .form-tab {
    &-container {
      --blocks-padding-x: calc(1rem + 1vw);
      --blocks-padding-y: calc(1rem + 0.5vw);
      position: relative;

      @media (max-width: 767.85px) {
        --blocks-padding-x: 0;
      }
    }

    &-header {
      position: relative;
      padding-block: 1vw;
      position: sticky;
      top: var(--info-sticky-height);
      z-index: 10;
      background-color: rgba(var(--bs-white-rgb), 0.75);
      backdrop-filter: blur(5px);
      --nav-items-gap: calc(2rem + 4.5vw);

      &-nav {
        display: flex;
        align-items: center;
        gap: var(--nav-items-gap);
        justify-content: center;
        flex-wrap: wrap;
        --bs-nav-pills-border-radius: 0.35rem;
        --bs-nav-pills-link-active-color: #fff;
        --bs-nav-pills-link-active-bg: rgba(var(--bs-primary), 1);

        .nav-item {
          text-align: center;
          position: relative;
          display: flex;
          align-items: center;
          z-index: 10;

          &:not(&:last-child)::after {
            position: absolute;
            content: '';
            height: 1px;
            left: 100%;
            border-top: 1px dashed var(--bs-gray-500);
            width: var(--nav-items-gap);
          }

          .nav-link {
            font-weight: 700;
            white-space: nowrap;
            padding: 0.55rem 1.5rem;
            font-size: 1.1rem;
            border-radius: var(--bs-nav-pills-border-radius);
            text-align: center;
            display: block;
            width: 100%;
            color: var(--bs-dark);
            background-color: var(--bs-white);

            &:not(.active) {
              font-weight: 400;
              border: 1px dashed var(--bs-gray-500);
              pointer-events: none;
            }

            &.active {
              background-color: rgba(var(--bs-primary), 1);
              color: var(--bs-nav-pills-link-active-color);
            }

            &:not(.active):hover {
              background-color: rgba(var(--bs-primary), 0.65);
              border-color: rgba(var(--bs-primary), 0);
            }
          }
        }
      }

      @media (max-width: 991.8px) {
        --nav-items-gap: calc(1rem + 0vw);
      }

      @media (max-width: 767.85px) {
        padding-block: 0;

        &-nav {
          padding-block: 2.5vw;
          flex-wrap: nowrap;
          overflow-x: scroll;
          justify-content: flex-start;
          margin-inline-start: -3.25vw;
          width: 100vw;
          padding-inline: var(--bs-gutter-x);
        }
      }
    }

    &-body {
      &-content {
        padding-inline: var(--blocks-padding-x);
        padding-block-end: var(--blocks-padding-y);
      }
    }

    &-footer {
      padding-inline: var(--blocks-padding-x);
      padding-block: var(--blocks-padding-y);
      position: relative;
      background-color: rgba(var(--bs-primary), 0.15);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: calc(var(--blocks-padding-x) * 2);

      @media (max-width: 991.8px) {
        justify-content: center;
        gap: 1rem;
      }
    }
  }
}
</style>