<template>
  <section>
    <!-- <AddSlider registration_tab="Email Varification - LinkedIn" /> -->
    <LoginStep :key="dynamicKey" v-if="!getEdition?.error"/>
    <div class="email-varification-block container-fluid">
    <div class="container">
      <div class="white-bg pb-4">
       <div class="text-center mb-4 mt-4" v-if="getEdition?.error">
       <p style="color: #f70a0a;font-size: 23px;" v-html="getEdition?.error"></p>
      </div>
    </div>
  </div>
</div>
  </section>
</template>
  
<script>
import gql from "graphql-tag";
import LoginStep from '../components/LoginStep.vue';
export default {
  name: "RegisterWithSocialMedia",
  beforeCreate() {
    let request_data = {
      type: this.$route?.query?.type ?? this.$route?.params?.type,
      event: this.$route?.query.event ?? this.$route?.params?.event_id
    }
    this.$store.dispatch("getEditionData", request_data)
  },
  components: { LoginStep },
  data() {
    return {
      dynamicKey:0,
      settings: {
        dots: false,
        arrows: false,
        draggable: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      imageSettings: {
        dots: false,
        arrows: true,
        draggable: false,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
      },
    };
  },
  mounted() {
    this.getEditionData();
  },
  computed: {
    getEdition() {
      return this.$store.getters.getEditionData
    },
  },
  methods: {
    getEditionData() {
      this.dynamicKey++;
     var type= this.$route.query.type ?? this.$route.params.type;
      var event= this.$route.params.event_id ?? this.$route.query.event;
      this.$apollo.query({
        query: gql`
            query edition_details($event: String!, $type: String!){
                edition_details(event: $event, type: $type)
            }`,
        variables: {
          event: event,
          type: type
        }
      }).then((data) => {
        const edition_details = JSON.parse(data.data.edition_details);
        if(!edition_details?.url_details?.new_design){
          window.location.href = process.env.VUE_APP_old_online_registration+"event?event="+event+'&type='+type;
          return false;
        }
        if (!edition_details?.url_details?.is_linkedin && !edition_details?.url_details?.login_with_twitter && !edition_details?.url_details?.login_with_facebook) {
          this.$router.push({
            name: 'index',
            params: { event: event, type: type }
          })
        }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
  
<style lang="scss"></style>